import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'internal-transactions',
  initialState: {
    list: {
      page: 1,
      limit: 40,
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    transactionsRequestStarted: (transactions) => {
      transactions.loading = true;
    },
    transactionsRequestFailed: (transactions, action) => {
      transactions.error = action.payload.data;
    },
    transactionsRequestEnded: (transactions) => {
      transactions.loading = false;
    },
    transactionsReceived: (transactions, action) => {
      transactions.list = action.payload.data;
    },
  },
});

export const { transactionsRequestStarted, transactionsRequestFailed, transactionsRequestEnded, transactionsReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getInternal = (params = {}) =>
  apiCallBegan({
    url: '/transactions/internal',
    params,
    onStart: transactionsRequestStarted,
    onSuccess: transactionsReceived,
    onError: transactionsRequestFailed,
    onEnd: transactionsRequestEnded,
  });

export const getMyInternal = (params = {}) =>
  apiCallBegan({
    url: '/transactions/internal/my',
    params,
    onStart: transactionsRequestStarted,
    onSuccess: transactionsReceived,
    onError: transactionsRequestFailed,
    onEnd: transactionsRequestEnded,
  });

export const createInternalTransaction = (data) =>
  apiCallBegan({
    url: '/transactions/internal',
    method: 'POST',
    data,
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
  });

export const createFakeInternalTransaction = (data) =>
  apiCallBegan({
    url: '/transactions/internal/fake',
    method: 'POST',
    data,
    onStart: transactionsRequestStarted,
    onSuccess: () => getInternal(),
    onEnd: transactionsRequestEnded,
  });

export const getMin = () => apiCallBegan({ url: `/transactions/Internal/min` });

export const getMax = () => apiCallBegan({ url: `/transactions/Internal/max` });

export const selectInternalTransactions = createSelector(
  (state) => state.internalTransactions,
  (internalTransactions) => ({
    list: internalTransactions.list,
    loading: internalTransactions.loading,
  }),
);

export const selectLoading = createSelector(
  (state) => state.internalTransactions,
  (internalTransactions) => internalTransactions.loading,
);
