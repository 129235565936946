// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'totals-abi',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    totalsABIRequestStarted: (totalsABI) => {
      totalsABI.loading = true;
    },
    totalsABIRequestFailed: (totalsABI, action) => {
      totalsABI.error = action.payload.data;
    },
    totalsABIRequestEnded: (totalsABI) => {
      totalsABI.loading = false;
    },
    totalsABIListReceived: (totalsABI, action) => {
      totalsABI.list = action.payload.data;
    },
  },
});

export const { totalsABIRequestStarted, totalsABIRequestFailed, totalsABIRequestEnded, totalsABIListReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: `/totals-abi`,
    params,
    onStart: totalsABIRequestStarted,
    onSuccess: totalsABIListReceived,
    onError: totalsABIRequestFailed,
    onEnd: totalsABIRequestEnded,
  });

export const getMy = () =>
  apiCallBegan({
    url: `/totals-abi/my`,
    onStart: totalsABIRequestStarted,
    onSuccess: totalsABIListReceived,
    onError: totalsABIRequestFailed,
    onEnd: totalsABIRequestEnded,
  });

export const importTotalsABI = (data) =>
  apiCallBegan({
    url: `/totals-abi`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: totalsABIRequestStarted,
    onEnd: totalsABIRequestEnded,
  });

//selector
export const selectList = createSelector(
  (state) => state.totalsABI,
  (totalsABI) => ({
    list: totalsABI.list,
    loading: totalsABI.loading,
  }),
);
