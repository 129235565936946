import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'btcRates',
  initialState: {
    latest: null,
  },
  reducers: {
    latestBtcReceived: (btcRates, action) => {
      btcRates.latest = action.payload.data;
    },
  },
});

export const { latestBtcReceived } = slice.actions;
export default slice.reducer;

//api calls
export const getLatest = () =>
  apiCallBegan({
    url: `/rates/latest/btc`,
    method: 'get',
    onSuccess: latestBtcReceived,
  });

export const selectBtcRates = createSelector(
  (state) => state.btcRates,
  (btcRates) => ({
    latest: btcRates.latest,
  }),
);
