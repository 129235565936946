// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'statistics',
  initialState: {
    accountsTotal: 0,
    accountsEuroTotal: 0,
    internalTransactionsTotal: 0,
    personalTransactionsTotal: 0,
    packageProfit: 0,
    playerProfit: 0,
    projectProfit: 0,
    personalMoney: 0,
    requiredForGameFromProject: 0,
    requiredForGameFromPlayer: 0,
    availableForOutput: 0,
    projectMoney: 0,
    over: 0,
    hold: 0,
    loading: false,
    error: null,
  },
  reducers: {
    statisticsRequestStarted: (statistics) => {
      statistics.loading = true;
    },
    statisticsRequestFailed: (statistics, action) => {
      statistics.error = action.payload.data;
    },
    statisticsRequestEnded: (statistics) => {
      statistics.loading = false;
    },
    statisticsReceived: (statistics, action) => {
      statistics.accountsTotal = action.payload.data.accountsTotal;
      statistics.accountsEuroTotal = action.payload.data.accountsEuroTotal;
      statistics.packageProfit = action.payload.data.packageProfit;
      statistics.playerProfit = action.payload.data.playerProfit;
      statistics.projectProfit = action.payload.data.projectProfit;
      statistics.personalMoney = action.payload.data.personalMoney;
      statistics.requiredForGameFromProject = action.payload.data.requiredForGameFromProject;
      statistics.requiredForGameFromPlayer = action.payload.data.requiredForGameFromPlayer;
      statistics.availableForOutput = action.payload.data.availableForOutput;
      statistics.projectMoney = action.payload.data.projectMoney;
      statistics.over = action.payload.data.over;
      statistics.hold = action.payload.data.hold;
    },
  },
});

export const { statisticsRequestStarted, statisticsRequestFailed, statisticsRequestEnded, statisticsReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getTotals = () =>
  apiCallBegan({
    url: '/calculate-stats/me',
    onStart: statisticsRequestStarted,
    onSuccess: statisticsReceived,
    onError: statisticsRequestFailed,
    onEnd: statisticsRequestEnded,
  });

export const getUserTotals = (userId) =>
  apiCallBegan({
    url: `/calculate-stats/${userId}`,
  });

//selector
export const selectStatistics = createSelector(
  (state) => state.statistics,
  (statistics) => ({
    accountsTotal: statistics.accountsTotal,
    accountsEuroTotal: statistics.accountsEuroTotal,
    internalTransactionsTotal: statistics.internalTransactionsTotal,
    personalTransactionsTotal: statistics.personalTransactionsTotal,
    packageProfit: statistics.packageProfit,
    playerProfit: statistics.playerProfit,
    projectProfit: statistics.projectProfit,
    personalMoney: statistics.personalMoney,
    requiredForGameFromProject: statistics.requiredForGameFromProject,
    requiredForGameFromPlayer: statistics.requiredForGameFromPlayer,
    availableForOutput: statistics.availableForOutput,
    projectMoney: statistics.projectMoney,
    over: statistics.over,
    hold: statistics.hold,
    loading: statistics.loading,
    error: statistics.error,
  }),
);
