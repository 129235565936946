import { DatePicker } from 'antd';
import 'moment/locale/ru';
import antLocale from 'antd/es/date-picker/locale/ru_RU';

const locale = {
  ...antLocale,
  lang: {
    ...antLocale.lang,
    rangePlaceholder: ['Дата от', 'Дата до'],
  },
};

const DateRangePicker = (props) => <DatePicker.RangePicker {...props} locale={locale} />;

export default DateRangePicker;
