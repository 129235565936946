import { Tag } from 'antd';
import { ACTION_TYPE, ENTITY_TYPE } from 'config/actions';
import { toAmount } from 'utils/format';
import { useTranslation } from 'react-i18next';

const ContentCell = ({ record }) => {
  const { t } = useTranslation();

  const { content } = record;
  if (record.entityType === ENTITY_TYPE.PLAYING_MYSELF_REPORT) {
    if (record.actionType === ACTION_TYPE.DELETE) {
      return '-';
    }

    return (
      <>
        {t('playingmyself.table.game_status')}:{' '}
        {content?.isPlayed ? (
          <Tag color="success">{t('playingmyself.report.button.hasplayed')}</Tag>
        ) : (
          <Tag color="error">{t('playingmyself.report.button.hasnotplayed')}</Tag>
        )}
      </>
    );
  } else if (record.entityType === ENTITY_TYPE.PLAYING_MYSELF_TRANSACTION) {
    return `${t('transaction')}: ${toAmount(content.amount, content.currency)}`;
  } else {
    return '-';
  }
};

export default ContentCell;
