import { useCallback, useContext } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { shouldBeReported } from 'utils/playing-myself';
import { ModalContext } from 'components/Modal/ModalProvider';
import { ID } from 'components/PlayingMyself/CreateReportModal/config';
import ViewReportModal from '../ViewReportModal';

export const ReportCell = ({ record, afterReport }) => {
  const { activate } = useContext(ModalContext);

  const openCreateModal = useCallback(() => {
    activate(ID, {
      record,
      afterReport,
    });
  }, [activate, record, afterReport]);

  if (!shouldBeReported(record)) {
    return '-';
  }

  if (record.isReported) {
    return <ViewReportModal record={record} afterReport={afterReport} />;
  }

  if (!record.isBlockedForReport) {
    return <Button type="primary" onClick={openCreateModal} icon={<EditOutlined />} />;
  }

  return '-';
};
