import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { getMyAccounts, selectList } from 'store/accounts';
import { sort } from 'utils/account';
import { AmountByValue } from 'components/Table/AmountCell';
import UploadScreenshot from './UploadScreenshot';

const AccountListScreenshot = () => {
  const dispatch = useDispatch();
  const { loading, list: accounts } = useSelector(selectList);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMyAccounts());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'type',
        key: 'type',
        render: t,
      },
      {
        dataIndex: 'balance',
        key: 'balance',
        align: 'center',
        render: (balance, { currency, balanceUSD }) => (
          <AmountByValue amount={balance} currency={currency} amountUSD={balanceUSD} />
        ),
      },
      {
        align: 'right',
        render: (row, record) => <UploadScreenshot account={record} />,
      },
    ],
    [t],
  );

  const sortedAccounts = useMemo(() => sort(accounts), [accounts]);

  return (
    <Table
      showHeader={false}
      loading={loading}
      columns={columns}
      rowKey="_id"
      dataSource={sortedAccounts}
      pagination={false}
      style={{ marginBottom: 24 }}
    />
  );
};

export default AccountListScreenshot;
