import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, LoginOutlined, LogoutOutlined, UpCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { useTableParams } from 'hooks/useTableParams';
import { useColumns } from 'hooks/playing-myself/useColumns';
import useLoading from 'hooks/useLoading';
import {
  cancelDecisionRequest,
  freeRollDecisionRequest,
  getAllActiveRequests,
  getAllArchivedRequests,
  markAsWeirdRequests,
  myselfDecisionRequest,
  packageDecisionRequest,
  selectActive,
} from 'store/playing-myself';
import './index.css';


const getTournamentCourse = ({ tournamentInfo, tournamentEntity, serialTournamentEntity }) => {
  if (tournamentInfo?.course) {
    return tournamentInfo.course;
  } else if (tournamentEntity?.at(0)?.course) {
    return tournamentEntity.at(0).course;
  } else if (serialTournamentEntity?.at(0)?.course) {
    return serialTournamentEntity.at(0).course;
  } else {
    return -1;
  }
};

const chooseClass = (record) => {
  if (record.user?.at(0)?.hasOnFreeze) {
    return 'snow-background';
  }

  const userCourse = record.user?.at(0)?.course;
  if (userCourse) {
    if (getTournamentCourse(record) === -1) {
      return 'blue';
    } else if (userCourse >= getTournamentCourse(record)) {
      return 'green';
    } else if (getTournamentCourse(record) - userCourse <= 2) {
      return 'yellow';
    } else if (getTournamentCourse(record) - userCourse > 2) {
      return 'red';
    }
  }
  return 'white';
};


export const AllActiveRequestTable = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectActive);
  const { params, handleTableChange } = useTableParams();
  const { t } = useTranslation();
  const { execute, loading } = useLoading();

  const fetchRequests = useCallback(() => {
    execute(() => dispatch(getAllActiveRequests(params)));
  }, [execute, dispatch, params]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const refresh = useCallback(() => {
    fetchRequests();
    dispatch(getAllArchivedRequests());
  }, [dispatch, fetchRequests]);

  const columns = useColumns(
    [
      {
        title: t('playingmyself.table.user'),
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        render: (user, record) => {
          return (
            <>
              {user?.at(0)?.username || '-'}{' '}
              {record.isWeird && (
                <Tooltip title="Weird">
                  <WarningOutlined label="Weird" color="#cc0000" />
                </Tooltip>
              )}
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        width: 106,
        render: (id) => (
          <Space>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                dispatch(packageDecisionRequest(id)).then((result) => {
                  if (result.success) {
                    refresh();
                  }
                });
              }}
            >
              <Button
                type="primary"
                title={t('playingmyself.table.statuses.package')}
                style={{ background: 'green' }}
                icon={<LoginOutlined />}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                dispatch(myselfDecisionRequest(id)).then((result) => {
                  if (result.success) {
                    refresh();
                  }
                });
              }}
            >
              <Button
                type="primary"
                title={t('playingmyself.table.statuses.myself')}
                style={{ background: 'blue' }}
                icon={<LogoutOutlined />}
              />
            </Popconfirm>

            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                dispatch(freeRollDecisionRequest(id)).then((result) => {
                  if (result.success) {
                    refresh();
                  }
                });
              }}
            >
              <Button
                type="primary"
                title={t('playingmyself.table.statuses.fl_freeroll')}
                style={{ background: 'orange' }}
                icon={<UpCircleOutlined />}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                dispatch(cancelDecisionRequest(id)).then((result) => {
                  if (result.success) {
                    refresh();
                  }
                });
              }}
            >
              <Button
                type="primary"
                title={t('playingmyself.table.statuses.cancel')}
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    ['closedAt'],
  );

  const handleRow = useCallback(
    (record) => ({
      onContextMenu: async (event) => {
        event.preventDefault();
        await dispatch(markAsWeirdRequests(record._id));
        refresh();
      },
    }),
    [dispatch, refresh],
  );

  return (
    <Table
      rowKey="_id"
      className="playing-myself-table"
      loading={loading}
      columns={columns}
      dataSource={list.docs}
      onChange={handleTableChange}
      scroll={{ x: 'auto' }}
      pagination={{
        total: list.totalDocs,
        showSizeChanger: false,
        current: list.page,
        pageSize: list.limit,
        style: { marginBottom: 0 },
      }}
      onRow={handleRow}
      rowClassName={chooseClass}
    />
  );
};
