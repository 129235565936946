import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal, notification, Upload } from "antd";
import { UploadOutlined, FileImageOutlined } from "@ant-design/icons";
import { importTotalImages, selectList } from "../../../store/total-images";

export const ImportImagesModal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { loading } = useSelector(selectList);

  const onSubmit = async (values) => {
    const formData = new FormData();
    // noinspection JSUnresolvedVariable
    formData.append("zipFile", values.zipFile.file, values.zipFile.file.name);

    try {
      await dispatch(importTotalImages(formData));
      notification.success({ message: "Archive is being saved" });
      setVisible(false);
    } catch (e) {
      if (e.response?.data?.errors) {
        form.setFields(
          e.response.data.errors.map((error) => {
            const key = Object.keys(error)[0];
            return {
              name: key,
              errors: [error[key]]
            };
          })
        );
      }
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)} icon={<FileImageOutlined />}>
        Import Archive
      </Button>
      <Modal
        confirmLoading={loading}
        open={visible}
        title="Import Archive"
        onOk={form.submit}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
      >
        <Form name="totals-csv" form={form} layout="vertical" onFinish={onSubmit}>

          <Form.Item label="ZIP file" name="zipFile" rules={[{ required: true }]}>
            <Upload accept=".zip" maxCount={1} beforeUpload={() => false} defaultFileList={[]}>
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
