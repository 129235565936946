import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Input, Table } from 'antd';
import { toCurrency } from 'utils/format';
import { compare } from 'utils/account';
import { getAll, selectAccountStats } from 'store/account-stats';
import ColumnTotal from 'components/Table/ColumnTotal';
import { excludedAccountColumns } from './config';

const renderNumber = (val) => (val !== null ? val.toFixed(2) : '-');

export const AccountStatistics = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const { loading, list } = useSelector(selectAccountStats);

  useEffect(() => {
    dispatch(getAll(filters));
  }, [dispatch, filters]);

  const onInputChange = useCallback((e) => {
    setFilters((prevState) => ({
      ...prevState,
      username: e.target.value,
    }));
  }, []);

  const columns = useMemo(
    () =>
      list.reduce(
        (acc, statistics) => {
          Object.keys(statistics)
            .filter((key) => !excludedAccountColumns.includes(key) && list.some((item) => item[key] > 0))
            .map((key) => {
              const titleChunks = key.split('_');
              const currency = titleChunks.pop();

              return {
                key,
                type: titleChunks.join('.'),
                currency,
              };
            })
            .sort(compare)
            .forEach(({ key, type, currency }) => {
              if (!acc.some((col) => col.key === key)) {
                acc.push({
                  key,
                  dataIndex: key,
                  align: 'center',
                  title: <ColumnTotal title={`${type} ${toCurrency(currency)}`} field={key} list={list} />,
                  ellipsis: true,
                  sorter: true,
                  render: renderNumber,
                });
              }
            });

          return acc;
        },
        [
          {
            key: 'username',
            title: <Input placeholder="Login" onChange={debounce(onInputChange, 500)} />,
            dataIndex: 'username',
            align: 'right',
            fixed: true,
          },
          {
            key: 'total',
            dataIndex: 'total',
            align: 'center',
            title: <ColumnTotal title="Total" field="total" list={list} />,
            ellipsis: true,
            sorter: true,
            render: renderNumber,
          },
          {
            key: 'over',
            dataIndex: 'over',
            align: 'center',
            title: <ColumnTotal title="Over" field="over" list={list} />,
            ellipsis: true,
            sorter: true,
            render: renderNumber,
          },
        ],
      ),
    [list],
  );

  const onToggleBlocked = useCallback(() => {
    setFilters((current) => ({ ...current, blocked: !current.blocked }));
  }, []);

  const onTableChange = useCallback((_, __, { field, order }) => {
    setFilters((current) => ({
      ...current,
      sort: { [field]: order === 'ascend' ? 1 : -1 },
    }));
  }, []);

  return (
    <>
      <Button onClick={onToggleBlocked}>{filters.blocked ? 'Hide' : 'Show'} blocked users</Button>
      <Divider />
      <Table
        onChange={onTableChange}
        loading={loading}
        rowKey="_id"
        dataSource={list}
        columns={columns}
        pagination={false}
        scroll={{ x: 'auto', y: 'calc(100vh - 209px)' }}
      />
    </>
  );
};
