import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal, notification, Upload } from 'antd';
import { UploadOutlined, FileSyncOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { useFileUpload } from 'hooks/useFileUpload';
import { mapFormErrors } from 'utils/format';
import { getAll, importSerialEvaluation, selectList } from 'store/serial-evaluation';

export const ImportArchiveModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const { fileList, handleChange, setStatus } = useFileUpload();
  const [form] = Form.useForm();
  const { loading } = useSelector(selectList);

  const onSubmit = useCallback(
    async (values) => {
      const formData = new FormData();
      formData.append('csvFile', values.file.file, values.file.file.name);

      try {
        await dispatch(importSerialEvaluation(formData));
        await dispatch(getAll());
        notification.success({ message: 'Import successful' });
        deactivate();
        form.resetFields();
        setStatus('success');
      } catch (e) {
        if (e.response.data.errors?.file) {
          setStatus('error');
          form.setFields(mapFormErrors(e));
        }
      }
    },
    [form, dispatch, deactivate, setStatus],
  );

  return (
    <>
      <Button type="primary" onClick={activate} icon={<FileSyncOutlined />}>
        Import Archive
      </Button>
      <Modal
        confirmLoading={loading}
        open={active}
        title="Import Archive"
        onOk={form.submit}
        destroyOnClose
        onCancel={deactivate}
      >
        <Form name="serial-evaluation-archive" form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="CSV file" name="file" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
            <Upload
              accept=".csv"
              maxCount={1}
              onChange={handleChange}
              fileList={fileList}
              beforeUpload={() => false}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
