export const getTotal = (list, latestBtcRate, latestEurRate) => {
  const sum = list?.reduce((acc, payment) => {
    if (payment.currency === 'BTC' && latestBtcRate) {
      acc += payment.balance * latestBtcRate.ratio;
    } else if (payment.currency === 'EUR' && latestEurRate) {
      acc += payment.balance * latestEurRate.ratio;
    } else if (payment.currency === 'USD') {
      acc += payment.balance;
    }

    return acc;
  }, 0);

  return sum.toFixed(2);
};
