import moment from 'moment/moment';

export const disableStartDate = (current) =>
  current && (current < moment().subtract(3, 'days').startOf('day') || current > moment().add(6, 'days').endOf('day'));

export const disableEndDate = (current) =>
  current && (current < moment().subtract(3, 'days').startOf('day') || current > moment().add(7, 'days').endOf('day'));

export const calculateRowClassName = (tournament, playerCourse) => {
  const { type, buyIn, aCourse, quality } = tournament;

  if (tournament.course === 0 || tournament.course === 16) {
    return '';
  }

  // If quality exists and is not 0, use the existing logic
  if (quality && quality !== 0) {
    return `color-${quality}`;
  }

  // Calculate edge
  const edge = playerCourse - aCourse;

  // Green conditions
  if (type >= 1 && type <= 10) {
    if (edge >= 2.5 || buyIn >= 199) {
      return 'color-1';
    }
    if ((buyIn >= 99 && buyIn <= 199 && aCourse <= 13) ||
      (buyIn >= 49 && buyIn < 99 && aCourse <= 9) ||
      (buyIn >= 19 && buyIn < 49 && aCourse <= 5) ||
      (buyIn < 19 && aCourse <= 2)) {
      return 'color-1';
    }
  } else if (type >= 11 && type <= 12 && edge >= 4) {
    return 'color-1';
  }

  // Yellow conditions
  if ((type >= 1 && type <= 10 && edge >= 1.25 && edge < 2.5) ||
    (type >= 11 && type <= 12 && edge >= 2 && edge < 4)) {
    return 'color-2';
  }

  // Blue conditions
  if ((type >= 1 && type <= 10 && edge >= 0.25 && edge < 1.25) ||
    (type >= 11 && type <= 12 && edge >= 0.5 && edge < 2) ||
    (type === 13 && edge >= 2.5)) {
    return 'color-3';
  }

  // Red conditions
  if ((type >= 1 && type <= 10 && edge < 0.25) ||
    (type >= 11 && type <= 12 && edge < 0.5) ||
    (type === 13 && edge < 2.5)) {
    return 'color-4';
  }

  return '';
}
