import { combineReducers, configureStore } from '@reduxjs/toolkit';
import api from './middleware/api';
import auth from './auth';
import users from './users';
import accounts from './accounts';
import rates from './rates';
import internalTransactions from './internal-transactions';
import personalTransactions from './personal-transactions';
import packages from './packages';
import payments from './payments';
import transfers from './transfers';
import statistics from './statistics';
import tournaments from './tournaments';
import serialTournaments from './serial-tournaments';
import limits from './limits';
import userStats from './user-stats';
import accountStats from './account-stats';
import accountLogs from './account-logs';
import apiError from './middleware/apiError';
import analytics from './analytics';
import btcRates from './btc-rates';
import externalTransactions from './external-transactions';
import playingMyself from './playing-myself';
import serialEvaluation from './serial-evaluation';
import totals from './totals';
import totalsABI from './totals-abi';
import gridGraphics from './grid-graphics';
import radarGraphics from './radar-graphics';
import flCraft from './fl-craft';
import totalImages from './total-images';
import actions from './actions';
export const store = () =>
  configureStore({
    reducer: combineReducers({
      auth,
      users,
      accounts,
      actions,
      rates,
      btcRates,
      internalTransactions,
      personalTransactions,
      packages,
      transfers,
      statistics,
      tournaments,
      serialTournaments,
      limits,
      userStats,
      analytics,
      accountStats,
      accountLogs,
      externalTransactions,
      playingMyself,
      payments,
      serialEvaluation,
      totals,
      totalsABI,
      gridGraphics,
      radarGraphics,
      flCraft,
      totalImages,
    }),
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), api, apiError],
  });
