import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Divider, Modal, Table } from 'antd';
import { getAll, getLatest, selectRates } from 'store/rates';

const columns = [
  {
    title: 'Date',
    dataIndex: 'ratioDate',
    key: 'date',
  },
  {
    title: 'Ratio',
    dataIndex: 'ratio',
    key: 'ratio',
  },
];

export const RateModal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { list, latest } = useSelector(selectRates);

  useEffect(() => {
    dispatch(getLatest());
  }, []);

  const onModalOpen = () => {
    dispatch(getAll());
    setVisible(true);
  };

  const onDateChange = useCallback((_, dateStr) => {
    dispatch(getAll(dateStr));
  }, []);

  return (
    <>
      {latest && <span onClick={onModalOpen}>EUR/USD - {latest?.ratio}</span>}
      <Modal open={visible} title="Recent rates" footer={null} onCancel={() => setVisible(false)}>
        <DatePicker onChange={onDateChange} />
        <Divider />
        <Table rowKey={(rate) => rate._id} columns={columns} dataSource={list} pagination={false} />
      </Modal>
    </>
  );
};
