import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { block, unblock } from 'store/payments';

const StatusSwitch = ({ id, blocked, onToggleStatus }) => {
  const dispatch = useDispatch();

  const blockPayment = useCallback(
    async (id) => {
      await dispatch(block(id));
      onToggleStatus();
    },
    [dispatch, onToggleStatus],
  );

  const unblockPayment = useCallback(
    async (id) => {
      await dispatch(unblock(id));
      onToggleStatus();
    },
    [dispatch, onToggleStatus],
  );

  return (
    <Button
      icon={blocked ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      onClick={() => (blocked ? unblockPayment(id) : blockPayment(id))}
    />
  );
};

export default StatusSwitch;
