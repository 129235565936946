export const generatePassword = () =>
  Array(10)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');

export const getDegree = (value) => {
  switch (true) {
    case value >= 1 && value <= 3:
      return 'Enrollee';
    case value >= 4 && value <= 8:
      return 'Student';
    case value >= 9 && value <= 12:
      return 'Bachelor';
    case value > 12 && value <= 15:
      return 'Master';
    default:
      return '';
  }
};

export const hasRole = (user, role) => user.roles?.some((r) => r.name === role);

export const getMaxPercent = (value) => {
  switch (true) {
    case value >= 1 && value <= 3:
      return 0;
    case value >= 4 && value <= 6:
      return 15;
    case value >= 7 && value <= 8:
      return 25;
    case value >= 9 && value <= 15:
      return 50;
    default:
      return 0;
  }
};
