import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { sort } from 'utils/account';
import { toCurrency } from 'utils/format';
import { getMyAccounts, updateBalance, selectList } from 'store/accounts';
import { getTotals } from 'store/statistics';
import EditableCell from 'components/Table/EditableCell';
import EditableRow from 'components/Table/EditableRow';
import { AmountByValue } from 'components/Table/AmountCell';
import ColumnTotal from 'components/Table/ColumnTotal';
import './index.css';

const AccountsTable = () => {
  const dispatch = useDispatch();
  const { loading, list: accounts } = useSelector(selectList);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMyAccounts());
  }, [dispatch]);

  const sortedAccounts = useMemo(() => sort(accounts), [accounts]);

  const saveBalance = useCallback(
    async (account) => {
      await dispatch(updateBalance(account._id, account.balance));
      dispatch(getTotals());
    },
    [dispatch],
  );

  // noinspection JSUnusedGlobalSymbols
  const columns = useMemo(
    () => [
      {
        title: t('accounts.total'),
        dataIndex: 'type',
        key: 'type',
        render: t,
        width: '50%',
      },
      {
        title: <ColumnTotal field="balanceUSD" list={accounts} addonAfter="$" align="left" />,
        dataIndex: 'balance',
        key: 'balance',
        editable: true,
        width: '50%',
        onCell: (record) => ({
          record,
          editable: true,
          dataIndex: 'balance',
          title: 'Balance',
          inputType: 'number',
          inputProps: {
            controls: false,
            addonAfter: toCurrency(record.currency),
            parser: (value) => value.replace(',', '.'),
          },
          rules: [
            {
              type: 'number',
              min: 0,
              message: 'Invalid value',
            },
          ],
          handleSave: saveBalance,
        }),
        render: (balance, { currency, balanceUSD }) => (
          <AmountByValue amount={balance} currency={currency} amountUSD={balanceUSD} />
        ),
      },
    ],
    [saveBalance, t, accounts],
  );

  return (
    <Table
      loading={loading}
      components={{ body: { row: EditableRow, cell: EditableCell } }}
      columns={columns}
      rowKey="_id"
      dataSource={sortedAccounts}
      pagination={false}
      className="allow-wrap accounts-table big"
      scroll={{ x: 'auto' }}
      bordered
    />
  );
};

export default AccountsTable;
