export const excludedAccountColumns = [
  '_id',
  'user',
  'username',
  'packageProfit',
  'projectProfit',
  'total',
  'over',
  'sessionProfit',
  'projectSessionProfit',
  'daily',
  'createdAt',
  '__v',
];
