import { Avatar, Descriptions, Space, Typography } from 'antd';

const { Text } = Typography;

export const Confirmation = ({ form }) => {
  const user = form.getFieldsValue(true);

  return (
    <>
      <Space style={{ marginBottom: 24 }}>
        <Avatar src={user.avatar || '//www.gravatar.com/avatar/4f64c9f81bb0d4ee969aaf7b4a5a6f40?s=200&r=pg&d=mm'} />
        <Text strong>{user.username}</Text>
      </Space>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Full Name">
          {user.name} {user.nameRus && `(${user.nameRus})`}
        </Descriptions.Item>
        <Descriptions.Item label="Country">{user.country}</Descriptions.Item>
      </Descriptions>
    </>
  );
};
