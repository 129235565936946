import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Image, Table } from 'antd';
import { toLocalTime } from 'utils/format';
import { fetchActions } from 'store/actions';
import ContentCell from './ContentCell';

export const ActionsTable = ({ record }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    // noinspection JSUnresolvedReference
    dispatch(fetchActions(record._id)).then(({ data }) => setReports(data));
  }, [dispatch, record]);

  const columns = useMemo(
    () => [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: toLocalTime,
        align: 'center',
      },
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        render: (_, record) => <ContentCell record={record} />,
        align: 'center',
      },
      {
        title: 'Action',
        dataIndex: 'actionType',
        key: 'actionType',
        render: (actionType) => (actionType === 'CREATE' ? 'Report' : 'Rollback'),
        align: 'center',
      },
      {
        title: 'Reported By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (createdBy) => createdBy?.username,
        align: 'center',
      },
    ],
    [t],
  );

  const onRow = useCallback(
    (record) => ({
      onClick: () => {
        if (record.content?.screenshot) {
          setScreenshot(record.content.screenshot);
        }
      },
    }),
    [],
  );

  const hideScreenshot = useCallback(() => setScreenshot(null), []);

  return (
    <>
      <h3>History</h3>
      <Table dataSource={reports} columns={columns} pagination={false} size="small" rowKey="_id" onRow={onRow} />
      <Image
        style={{ display: 'none' }}
        preview={{ visible: !!screenshot, src: screenshot, onVisibleChange: hideScreenshot }}
      />
    </>
  );
};
