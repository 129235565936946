import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { REQUEST_TYPE } from 'config/playing-myself';
import { useColumns } from './useColumns';

export const useArchiveColumns = (extra = [], hidden = []) => {
  const { t } = useTranslation();

  return useColumns(
    [
      ...extra,
      {
        title: t('playingmyself.table.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (value) => {
          if (value === REQUEST_TYPE.PACKAGE) {
            return <Tag color="success">{t('playingmyself.table.statuses.package')}</Tag>;
          } else if (value === REQUEST_TYPE.MYSELF) {
            return <Tag color="processing">{t('playingmyself.table.statuses.myself')}</Tag>;
          } else if (value === REQUEST_TYPE.DELETED) {
            return <Tag color="error">{t('playingmyself.table.statuses.declined')}</Tag>;
          } else if (value === REQUEST_TYPE.FL_FREEROLL) {
            return <Tag color="cyan">{t('playingmyself.table.statuses.fl_freeroll')}</Tag>;
          } else if (value === REQUEST_TYPE.CANCELLED_BY_PLAYER) {
            return <Tag color="red">{t('playingmyself.table.statuses.player_cancelled')}</Tag>;
          } else {
            return <Tag color="warning">{t('playingmyself.table.statuses.unknown')}</Tag>;
          }
        },
      },
    ],
    hidden,
  );
};
