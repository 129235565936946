import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Space } from 'antd';
import { getAll, selectTransfers } from 'store/transfers';
import { getInternal, selectInternalTransactions } from 'store/internal-transactions';
import { InternalTransactionsTable } from 'components/Transactions/InternalTransactionsTable';
import DownloadTransactionsModal from 'components/Transactions/DownloadTransactionsModal';
import CreatePhysicalTransactionModal from './fragments/CreatePhysicalTransactionModal';
import CreateTransactionModal from './fragments/CreateTransactionModal';
import CreateTransferRequestModal from './fragments/CreateTransferRequestModal';
import TransfersTable from './fragments/TransfersTable';

export const InternalTransactions = () => {
  const dispatch = useDispatch();
  const { loading: transfersLoading, list: transfersList } = useSelector(selectTransfers);
  const { loading: transactionsLoading, list: transactionsList } = useSelector(selectInternalTransactions);

  const onTransfersTableChange = useCallback((params) => dispatch(getAll(params)), [dispatch]);

  const onTransactionsTableChange = useCallback((params) => dispatch(getInternal(params)), [dispatch]);

  return (
    <>
      <Space>
        <CreateTransferRequestModal />
        <DownloadTransactionsModal />
      </Space>
      <Divider />
      <TransfersTable list={transfersList} loading={transfersLoading} onChange={onTransfersTableChange} />
      <Divider />
      <Space>
        <CreateTransactionModal />
        <CreatePhysicalTransactionModal />
      </Space>
      <Divider />
      <InternalTransactionsTable
        list={transactionsList}
        loading={transactionsLoading}
        onChange={onTransactionsTableChange}
      />
    </>
  );
};
