import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Space, Tag } from 'antd';
import { getMy, selectTransfers } from 'store/transfers';
import { getMyInternal, selectInternalTransactions } from 'store/internal-transactions';
import { getTotals, selectStatistics } from 'store/statistics';
import { InternalTransactionsTable } from 'components/Transactions/InternalTransactionsTable';
import CreateTransferRequestModal from './fragments/СreateTransferRequestModal';
import { TransfersTable } from './fragments/TransfersTable';

export const InternalTransactions = () => {
  const dispatch = useDispatch();
  const { loading: transfersLoading, list: transfersList } = useSelector(selectTransfers);
  const { loading: transactionsLoading, list: transactionsList } = useSelector(selectInternalTransactions);
  const { over } = useSelector(selectStatistics);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTotals());
  }, [dispatch]);

  const onTransfersTableChange = useCallback((params) => dispatch(getMy(params)), [dispatch]);

  const onTransactionsTableChange = useCallback((params) => dispatch(getMyInternal(params)), [dispatch]);

  return (
    <>
      <Space style={{ marginBottom: 24 }}>
        <CreateTransferRequestModal />
        <Tag color="geekblue">
          {t('over')}: {over?.toFixed(2)}$
        </Tag>
      </Space>
      <TransfersTable list={transfersList} loading={transfersLoading} onChange={onTransfersTableChange} />
      <Divider />
      <InternalTransactionsTable
        list={transactionsList}
        loading={transactionsLoading}
        onChange={onTransactionsTableChange}
      />
    </>
  );
};
