import { useMemo } from 'react';
import { Divider, Typography } from 'antd';
import { toCurrency } from 'utils/format';
import Amount from 'components/Amount';

const { Text } = Typography;

export const AmountByRate = ({ amount, currency, latestEurRate, latestBtcRate }) => {
  if (!amount) return 0;

  let str = `${amount.toFixed(currency === 'BTC' ? 5 : 2)}${toCurrency(currency)}`;

  if (currency === 'EUR' && latestEurRate) {
    return (
      <>
        <span>{str}</span>
        <Divider style={{ margin: 0 }} />
        <Text type="secondary">({(amount * latestEurRate.ratio).toFixed(2)}$)</Text>
      </>
    );
  }

  if (currency === 'BTC' && latestBtcRate) {
    return (
      <>
        <span>{str}</span>
        <Divider style={{ margin: 0 }} />
        <Text type="secondary">({(amount * latestBtcRate.ratio).toFixed(2)}$)</Text>
      </>
    );
  }

  return str;
};

export const AmountByValue = ({ amount, currency, amountUSD }) => {
  const secondary = useMemo(
    () => (currency !== 'USD' && amountUSD ? { amount: amountUSD } : undefined),
    [currency, amountUSD],
  );

  return <Amount amount={amount} currency={currency} secondary={secondary} />;
};
