export const stopPropagation = (e) => e.stopPropagation();

export const withFormErrors = async (form, cb, onError) => {
  try {
    return await cb();
  } catch (e) {
    const { errors } = e.response.data;

    if (errors) {
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: [errors[key]],
        })),
      );
    }

    if (onError) {
      onError(e);
    }
  }
};
