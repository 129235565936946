export const INPUT_OUTPUT = 'input_output';
export const FRIEND_TRANSFER = 'friend_transfer';
export const PLAYING_MYSELF = 'playing_myself';
export const PLAYING_MYSELF_CASH = 'playing_myself_cash';
export const OTHER = 'other';
export const SOFTWARE_COFINANCING = 'software_cofinancing';
export const REFERRAL = 'referral';
export const SALARY_EMPLOYEE = 'salary_employee';
export const SALARY_COACH = 'salary_coach';
export const DEBT = 'debt';
export const TECHNICAL_COSTS = 'technical costs';
export const MEDIA = 'media';
export const TICKET_REDEMPTION = 'ticket redemption';
export const CORRECTION = 'correction';
export const DONATION = 'Donation';

export const personalPurposes = [
  {
    value: INPUT_OUTPUT,
    label: 'Input/Output',
  },
  {
    value: PLAYING_MYSELF,
    label: 'Playing myself',
  },
  {
    value: PLAYING_MYSELF_CASH,
    label: 'Playing myself cash',
  },
  {
    value: FRIEND_TRANSFER,
    label: 'Friend transfer',
  },
];

export const playerPersonalPurposes = [
  ...personalPurposes,
  {
    value: OTHER,
    label: 'Other',
  },
];

export const internalPurposes = [
  {
    value: SOFTWARE_COFINANCING,
    label: 'Software co-financing',
  },
  {
    value: REFERRAL,
    label: 'Referral',
  },
  {
    value: SALARY_EMPLOYEE,
    label: 'Salary (employee)',
  },
  {
    value: SALARY_COACH,
    label: 'Salary (coach)',
  },
  {
    value: DEBT,
    label: 'Debt',
  },
  {
    value: TECHNICAL_COSTS,
    label: 'Technical costs',
  },
  {
    value: MEDIA,
    label: 'Media',
  },
  {
    value: TICKET_REDEMPTION,
    label: 'Ticket redemption',
  },
  {
    value: CORRECTION,
    label: 'Correction',
  },
  {
    value: OTHER,
    label: 'Other',
  },
];

export const adminPersonalPurposes = [...personalPurposes, ...internalPurposes];

export const physicalPurposes = [
  {
    value: DONATION,
    label: 'Donation',
  },
  ...internalPurposes,
];

export const REVERT_TIME_EXPIRE = 3600 * 72 * 1000;
