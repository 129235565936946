import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Descriptions, Skeleton, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { selectMyActivePackage } from 'store/packages';
import { selectStatistics } from 'store/statistics';
import Amount from 'components/Amount';

export const PackageInfo = () => {
  const { myActive, loading } = useSelector(selectMyActivePackage);
  const statistics = useSelector(selectStatistics);
  const { t } = useTranslation();

  const {
    packageProfit,
    playerProfit,
    projectProfit,
    packageProfitOver = 0,
    profitOver = 0,
  } = useMemo(() => {
    const { packageProfit, playerProfit, projectProfit } = statistics;

    if (myActive?.onFreeze > 0 && packageProfit > 0) {
      const packageProfitToUnfreeze = myActive.onFreeze * 2;
      const packageProfitOver = packageProfit - packageProfitToUnfreeze;
      if (packageProfitOver > 0) {
        return {
          packageProfit: packageProfitToUnfreeze,
          packageProfitOver,
          playerProfit: packageProfitToUnfreeze * 0.25,
          projectProfit: packageProfitToUnfreeze * 0.25,
          profitOver: packageProfitOver * 0.5,
        };
      } else {
        return {
          packageProfit,
          playerProfit: packageProfit * 0.25,
          projectProfit: packageProfit * 0.25,
        };
      }
    }

    return { packageProfit, playerProfit, projectProfit };
  }, [myActive, statistics]);

  return (
    <Skeleton loading={loading}>
      <Descriptions bordered column={1} labelStyle={{ width: '50%' }} contentStyle={{ width: '50%' }}>
        <Descriptions.Item label={t('package.created_at')}>
          {new Date(myActive?.createdAt).toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label={t('package.conditions')}>
          {myActive?.onFreeze ? (
            <>
              0 (25/25/50){' '}
              <Tooltip title={t('onFreeze')}>
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          ) : (
            <>
              {myActive?.buyInPercent} ({myActive?.profitPercent}/{100 - myActive?.profitPercent})
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('packageProfit')}>
          <Amount amount={packageProfit} currency="USD" secondary={{ addonBefore: '+', amount: packageProfitOver }} />
        </Descriptions.Item>
        <Descriptions.Item label={t('playerProfit')}>
          <Amount amount={playerProfit} currency="USD" secondary={{ addonBefore: '+', amount: profitOver }} />
        </Descriptions.Item>
        <Descriptions.Item label={t('projectProfit')}>
          <Amount amount={projectProfit} currency="USD" secondary={{ addonBefore: '+', amount: profitOver }} />
        </Descriptions.Item>
        {myActive?.onFreeze && (
          <>
            <Descriptions.Item label={t('toUnFreeze')}>
              {(packageProfit > myActive.onFreeze * 2
                ? myActive.onFreeze
                : packageProfit > 0
                ? packageProfit / 2
                : 0
              ).toFixed(2)}{' '}
              $
            </Descriptions.Item>
            <Descriptions.Item label={t('onFreeze')}>{myActive.onFreeze.toFixed(2)} $</Descriptions.Item>
          </>
        )}
        {myActive?.onSplit && (
          <Descriptions.Item label={t('onSplit')}>{myActive.onSplit.toFixed(2)} $</Descriptions.Item>
        )}
      </Descriptions>
    </Skeleton>
  );
};
