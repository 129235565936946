import { useContext } from 'react';
import { ModalContext } from './ModalProvider';

const ModalController = ({ id, children }) => {
  const { activeId } = useContext(ModalContext);

  return activeId === id ? children : null;
};

export default ModalController;
