import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Space, Tag } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { getMyPersonal } from 'store/personal-transactions';
import { getTotals, selectStatistics } from 'store/statistics';
import CreateTransactionModal from './fragments/CreateTransactionModal';
import MyPersonalTransactionsTable from './fragments/MyPersonalTransactionsTable';

const tableParams = {
  pagination: {
    page: 1,
    pageSize: 40,
  },
};

const PersonalTransactions = () => {
  const dispatch = useDispatch();
  const { availableForOutput } = useSelector(selectStatistics);
  const { t } = useTranslation();
  const { params, addFilter, nextPage } = useTableParams(tableParams);

  const onChange = useCallback(() => {
    dispatch(getMyPersonal(params));
    dispatch(getTotals());
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(getTotals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMyPersonal(params));
  }, [dispatch, params]);

  return (
    <>
      <Space>
        <CreateTransactionModal onCreate={onChange} />
        <Tag color="geekblue">
          {t('availableForOutput')}: {availableForOutput?.toFixed(2)}$
        </Tag>
      </Space>
      <Divider />
      <MyPersonalTransactionsTable onFilter={addFilter} next={nextPage} onRevert={onChange} />
    </>
  );
};

export default PersonalTransactions;
