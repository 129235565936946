import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Descriptions, Tag } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { ENTITY_TYPE } from 'config/actions';
import { toAmount, toLocalTime, toOptional } from 'utils/format';
import { isReportRevertTimeExpired } from 'utils/playing-myself';
import { useToggle } from 'hooks/useToggle';
import { fetchLastAction } from 'store/actions';
import { getPersonalTransaction, selectPersonalTransactions } from 'store/personal-transactions';
import { revertReport } from 'store/playing-myself';
import LoadingState from 'components/LoadingState';
import { ModalContext } from 'components/Modal/ModalProvider';
import { ID as CREATE_MODAL_ID } from 'components/PlayingMyself/CreateReportModal/config';

const ViewReportModal = ({ record, afterReport }) => {
  const { t } = useTranslation();
  const { active, activate, deactivate } = useToggle();
  const dispatch = useDispatch();
  const { activate: openModal } = useContext(ModalContext);
  const { loading: transactionLoading } = useSelector(selectPersonalTransactions);

  const [lastReport, setLastReport] = useState(null);
  const [lastTransaction, setLastTransaction] = useState(null);

  const revertUserReport = useCallback(async () => {
    deactivate();
    openModal(CREATE_MODAL_ID, {
      record,
      beforeReport: () => dispatch(revertReport(record._id)),
      afterReport,
    });
  }, [record, dispatch, deactivate, afterReport]);

  const fetchData = useCallback(async () => {
    const { data: reportAction } = await dispatch(fetchLastAction(ENTITY_TYPE.PLAYING_MYSELF_REPORT, record._id));
    setLastReport(reportAction);

    if (reportAction?.content?.isPlayed) {
      const { data: transactionAction } = await dispatch(
        fetchLastAction(ENTITY_TYPE.PLAYING_MYSELF_TRANSACTION, record._id),
      );
      if (transactionAction?.content?.transactionId) {
        const { data: transaction } = await dispatch(getPersonalTransaction(transactionAction.content.transactionId));
        setLastTransaction({
          ...transaction,
          createdBy: transactionAction.createdBy.username,
        });
      }
    }
  }, [dispatch, record]);

  useEffect(() => {
    if (active) {
      fetchData();
    }
  }, [active, fetchData]);

  const footer = useMemo(
    () =>
      isReportRevertTimeExpired(record) || record.isBlockedForReport ? (
        <Button onClick={deactivate} type="primary">
          {t('OK')}
        </Button>
      ) : (
        [
          <Button key="revert" type="danger" onClick={revertUserReport}>
            {t('playingmyself.report.button.revertreport')}
          </Button>,
          <Button key="close" onClick={deactivate} type="primary">
            {t('OK')}
          </Button>,
        ]
      ),
    [record, deactivate, revertUserReport, t],
  );

  const hasPlayed = lastReport?.content?.isPlayed;

  return (
    <>
      <Button type="primary" onClick={activate} icon={<BookOutlined />} />
      <Modal title={t('playingmyself.table.report')} open={active} footer={footer} width={700} closable={false}>
        <Descriptions title={t('playingmyself.report.tournament_details')} bordered column={1} size="small">
          <Descriptions.Item label={t('playingmyself.table.tournament')}>{record.tournament}</Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.date')}>
            {toLocalTime(record.tournamentDate)}
          </Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.buyin')}>
            {toAmount(record.buyin, record.account[0].currency)}
          </Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.entry')}>
            {toOptional(t(`playingmyself.create.selector.${record.entry}`))}
          </Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.bbcount')}>{toOptional(record.bbCount)}</Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.updateddate')}>
            {toLocalTime(record.updatedAt)}
          </Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.requestcomment')}>
            {toOptional(record.comment)}
          </Descriptions.Item>
          <Descriptions.Item label={t('playingmyself.table.isplayed')}>
            {hasPlayed ? (
              <Tag color="success">{t('playingmyself.table.played')}</Tag>
            ) : (
              <Tag color="error">{t('playingmyself.table.notplayed')}</Tag>
            )}
          </Descriptions.Item>
        </Descriptions>
        {hasPlayed && (
          <LoadingState loading={transactionLoading}>
            {lastTransaction && (
              <Descriptions
                title={t('playingmyself.report.last_transaction')}
                bordered
                column={1}
                size="small"
                style={{ marginTop: 20 }}
              >
                <Descriptions.Item label={t('amount')}>
                  {toAmount(lastTransaction.amount, lastTransaction.currency)}
                </Descriptions.Item>
                <Descriptions.Item label={t('comment')}>{lastTransaction.comment}</Descriptions.Item>
                <Descriptions.Item label={t('playingmyself.table.createdat')}>
                  {toLocalTime(lastTransaction.createdAt)}
                </Descriptions.Item>
                <Descriptions.Item label={t('createdBy')}>{lastTransaction.createdBy}</Descriptions.Item>
              </Descriptions>
            )}
          </LoadingState>
        )}
      </Modal>
    </>
  );
};

export default ViewReportModal;
