import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableTable = ({ onDragSort, dataSource, columns, active = true, ...restProps }) => {
  const [sortedDataSource, setSortedDataSource] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setSortedDataSource(dataSource);
    }
  }, [dataSource]);

  const columnsWithDragHandle = useMemo(
    () =>
      active
        ? [
            {
              dataIndex: 'sort',
              width: 30,
              className: 'drag-visible',
              align: 'center',
              render: () => <DragHandle />,
            },
            ...columns,
          ]
        : columns,
    [columns, active],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable(sortedDataSource.slice(), oldIndex, newIndex).filter((el) => !!el);
        setSortedDataSource(newData);
        onDragSort({ oldIndex, newIndex });
      }
    },
    [sortedDataSource, onDragSort],
  );

  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = sortedDataSource.findIndex((x) => x.order === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={sortedDataSource}
      columns={columnsWithDragHandle}
      rowKey="order"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...restProps}
    />
  );
};

export default SortableTable;
