// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'transfers',
  initialState: {
    list: {
      page: 1,
      limit: 10,
      totalDocs: 0,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    transfersRequestStarted: (transfers) => {
      transfers.loading = true;
    },
    transfersRequestFailed: (transfers, action) => {
      transfers.error = action.payload.data;
    },
    transfersRequestEnded: (transfers) => {
      transfers.loading = false;
    },
    transfersReceived: (transfers, action) => {
      transfers.list = action.payload.data;
    },
  },
});

export const { transfersRequestStarted, transfersRequestFailed, transfersRequestEnded, transfersReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getMy = (params = {}) =>
  apiCallBegan({
    url: '/transfer-requests/my',
    params,
    onStart: transfersRequestStarted,
    onSuccess: transfersReceived,
    onError: transfersRequestFailed,
    onEnd: transfersRequestEnded,
  });

export const getMyAvailableAccounts = () =>
  apiCallBegan({
    url: '/transfer-requests/available-accounts',
  });

export const reserve = (requestId) =>
  apiCallBegan({
    url: `/transfer-requests/${requestId}/reserve`,
    method: 'PATCH',
    onStart: transfersRequestStarted,
    onError: transfersRequestFailed,
    onEnd: transfersRequestEnded,
  });

export const release = (requestId) =>
  apiCallBegan({
    url: `/transfer-requests/${requestId}/release`,
    method: 'PATCH',
  });

export const getAll = (params = {}) =>
  apiCallBegan({
    url: '/transfer-requests',
    params,
    onStart: transfersRequestStarted,
    onSuccess: transfersReceived,
    onError: transfersRequestFailed,
    onEnd: transfersRequestEnded,
  });

export const createMy = (data) =>
  apiCallBegan({
    url: '/transfer-requests/my',
    method: 'POST',
    data,
    onStart: transfersRequestStarted,
    onSuccess: () => getMy(),
    onEnd: transfersRequestEnded,
  });

export const create = (data) =>
  apiCallBegan({
    url: '/transfer-requests',
    method: 'POST',
    data,
    onStart: transfersRequestStarted,
    onSuccess: () => getAll(),
    onEnd: transfersRequestEnded,
  });

export const donate = (requestId, data) =>
  apiCallBegan({
    url: `/transfer-requests/${requestId}/donate`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'PATCH',
    data,
    onStart: transfersRequestStarted,
    onSuccess: () => getMy(),
    onEnd: transfersRequestEnded,
  });

export const close = (requestId) =>
  apiCallBegan({
    url: `/transfer-requests/${requestId}/close`,
    method: 'PATCH',
    onStart: transfersRequestStarted,
    onSuccess: () => getMy(),
    onEnd: transfersRequestEnded,
  });

export const deleteOne = (requestId) =>
  apiCallBegan({
    url: `/transfer-requests/${requestId}`,
    method: 'DELETE',
    onStart: transfersRequestStarted,
    onEnd: transfersRequestEnded,
  });

//selector
export const selectTransfers = createSelector(
  (state) => state.transfers,
  (transfers) => ({
    list: transfers.list,
    loading: transfers.loading,
  }),
);

export const selectLoading = createSelector(
  (state) => state.transfers,
  (transfers) => transfers.loading,
);
