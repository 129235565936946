import { createContext, useCallback, useMemo, useState } from 'react';

export const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [activeId, setActiveId] = useState(undefined);
  const [payload, setPayload] = useState(null);

  const activate = useCallback((id, data) => {
    setActiveId(id);
    setPayload(data);
  }, []);

  const deactivate = useCallback(() => setActiveId(undefined), []);

  const value = useMemo(
    () => ({
      activeId,
      payload,
      activate,
      deactivate,
    }),
    [activeId, payload, activate, deactivate],
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
