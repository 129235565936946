import { Button, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ImageUpload = ({ onChange }) => {
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener('paste', (e) => {
      const items = [].slice.call(e.clipboardData.items).filter((item) => /^image\//.test(item.type));

      if (items.length === 0) {
        return;
      }

      const time = new Date().getTime();
      const file = new File(
        [items[0].getAsFile()],
        `screenshot-${time}`,
        { type: items[0].type, lastModified: time },
        'utf-8',
      );

      setFileList([
        {
          ...file,
          name: file.name,
          uid: Math.random(),
          originFileObj: file,
          thumbUrl: URL.createObjectURL(file),
        },
      ]);
      onChange(file);
    });

    return () => document.removeEventListener('paste', document);
  }, []);

  const onFileChange = useCallback((data) => {
    setFileList(data.fileList);
    onChange(data.fileList.length ? data.file : undefined);
  }, []);

  const onPreview = useCallback(async (file) => {
    setPreviewImage(file.thumbUrl);
    setPreviewTitle(file.name);
    setPreviewOpen(true);
  }, []);

  const onCancel = useCallback(() => setPreviewOpen(false));

  return (
    <>
      <Upload
        accept="image/*"
        maxCount={1}
        beforeUpload={() => false}
        fileList={fileList}
        listType="picture"
        onChange={onFileChange}
        onPreview={onPreview}
      >
        <Button icon={<UploadOutlined />}>{t('selectFile')}</Button>
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={onCancel}>
        <img alt="screenshot" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
