import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal, notification, Select, Upload } from 'antd';
import { UploadOutlined, FileSyncOutlined } from '@ant-design/icons';
import { selectList } from 'store/totals';
import { importTotals } from 'store/totals';
import { importTotalsABI } from 'store/totals-abi';

export const ImportTotalsModal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { loading } = useSelector(selectList);

  const onSubmit = async (values) => {
    const formData = new FormData();
    // noinspection JSUnresolvedVariable
    formData.append('csvFile', values.csvFile.file, values.csvFile.file.name);

    try {
      switch (values.type) {
        case 'ev':
          await dispatch(importTotals(formData));
          notification.success({ message: 'EV Import successful' });
          break;
        case 'abi':
          await dispatch(importTotalsABI(formData));
          notification.success({ message: 'ABI Import successful' });
          break;
        default:
          notification.error({ message: 'Unknown type ' + values.type });
          break;
      }
      setVisible(false);
    } catch (e) {
      if (e.response?.data?.errors) {
        form.setFields(
          e.response.data.errors.map((error) => {
            const key = Object.keys(error)[0];
            return {
              name: key,
              errors: [error[key]],
            };
          }),
        );
      }
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)} icon={<FileSyncOutlined />}>
        Import Totals
      </Button>
      <Modal
        confirmLoading={loading}
        open={visible}
        title="Import Totals"
        onOk={form.submit}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
      >
        <Form name="serial-evaluation-archive" form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            label={'Type'}
            name="type"
            rules={[{ required: true, message: 'Please select a type of CSV file' }]}
          >
            <Select placeholder="Type" style={{ maxWidth: 120 }} allowClear>
              <Select.Option value="ev">EV</Select.Option>
              <Select.Option value="abi">ABI</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="CSV file" name="csvFile" rules={[{ required: true }]}>
            <Upload accept=".csv" maxCount={1} beforeUpload={() => false} defaultFileList={[]}>
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
