import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'serialTournaments',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    serialTournamentsRequestStarted: (tournaments) => {
      tournaments.loading = true;
    },
    serialTournamentsRequestFailed: (tournaments, action) => {
      tournaments.error = action.payload.data;
    },
    serialTournamentsRequestEnded: (tournaments) => {
      tournaments.loading = false;
    },
    serialTournamentsReceived: (tournaments, action) => {
      tournaments.list = action.payload.data;
    },
  },
});

export const {
  serialTournamentsRequestStarted,
  serialTournamentsRequestFailed,
  serialTournamentsRequestEnded,
  serialTournamentsReceived,
} = slice.actions;
export default slice.reducer;

const BASE_URL = '/tournaments/serial';

export const getSerialTournaments = (params = {}) =>
  apiCallBegan({
    url: BASE_URL,
    params,
    onStart: serialTournamentsRequestStarted,
    onSuccess: serialTournamentsReceived,
    onError: serialTournamentsRequestFailed,
    onEnd: serialTournamentsRequestEnded,
  });

export const getFilteredTournaments = (data) =>
  apiCallBegan({
    url: `${BASE_URL}/filtered`,
    method: 'POST',
    data,
    onStart: serialTournamentsRequestStarted,
    onEnd: serialTournamentsRequestEnded,
  });

export const importSerialTournaments = (data) =>
  apiCallBegan({
    url: BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: serialTournamentsRequestStarted,
    onSuccess: () => getSerialTournaments(),
    onEnd: serialTournamentsRequestEnded,
  });

export const updateSerialTournament = (id, data) =>
  apiCallBegan({
    url: `${BASE_URL}/${id}`,
    method: 'put',
    data,
    onStart: serialTournamentsRequestStarted,
    onSuccess: () => getSerialTournaments(),
    onError: serialTournamentsRequestFailed,
    onEnd: serialTournamentsRequestEnded,
  });

export const deleteSerialTournament = (id) =>
  apiCallBegan({
    url: `${BASE_URL}/${id}`,
    method: 'delete',
    onStart: serialTournamentsRequestStarted,
    onSuccess: () => getSerialTournaments(),
    onError: serialTournamentsRequestFailed,
    onEnd: serialTournamentsRequestEnded,
  });

export const selectSerialTournamentsLoading = createSelector(
  (state) => state.serialTournaments,
  (tournaments) => tournaments.loading,
);

export const selectSerialTournaments = createSelector(
  (state) => state.serialTournaments,
  (tournaments) => ({
    list: tournaments.list,
    loading: tournaments.loading,
  }),
);
