import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectAuthenticated } from '../store/auth';

const PublicRoute = ({ role, children, ...rest }) => {
  const authenticated = useSelector(selectAuthenticated);

  return <Route {...rest} render={() => (!authenticated ? children : <Redirect to="/" />)} />;
};

export default PublicRoute;
