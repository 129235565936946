import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'users',
  initialState: {
    list: {
      page: 1,
      hasNextPage: true,
      docs: [],
    },
    current: null,
    loading: false,
    error: null,
  },
  reducers: {
    usersRequested: (users) => {
      users.loading = true;
    },
    usersRequestFailed: (users, action) => {
      users.error = action.payload.data;
    },
    usersRequestEnded: (users) => {
      users.loading = false;
    },
    usersReceived: (users, action) => {
      users.list = action.payload.data;
    },
    usersLoaded: (users, action) => {
      users.list = {
        ...action.payload.data,
        docs: [...users.list.docs, ...action.payload.data.docs],
      };
    },
    userReceived: (users, action) => {
      users.current = action.payload.data;
    },
  },
});

export const { usersRequested, usersRequestFailed, usersRequestEnded, usersReceived, usersLoaded, userReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params = {}) =>
  apiCallBegan({
    url: '/users',
    params,
    onStart: usersRequested,
    onSuccess: params.pagination.page > 1 ? usersLoaded : usersReceived,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const getUserById = (id) =>
  apiCallBegan({
    url: `/users/${id}`,
    onStart: usersRequested,
    onSuccess: userReceived,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const getInner = () => apiCallBegan({ url: `/users/inner` });

export const createUser = (data) =>
  apiCallBegan({
    url: '/users',
    method: 'post',
    data,
    onStart: usersRequested,
    onEnd: usersRequestEnded,
  });

export const createAdmin = (data) =>
  apiCallBegan({
    url: '/users/admin',
    method: 'post',
    data,
    onStart: usersRequested,
    onEnd: usersRequestEnded,
  });

export const updateUser = (id, data) =>
  apiCallBegan({
    url: `/users/${id}`,
    method: 'put',
    data,
    onStart: usersRequested,
    onEnd: usersRequestEnded,
  });

export const updateAdmin = (id, data) =>
  apiCallBegan({
    url: `/users/admin/${id}`,
    method: 'put',
    data,
    onStart: usersRequested,
    onEnd: usersRequestEnded,
  });

export const updatePermissions = (id, data) =>
  apiCallBegan({
    url: `/users/admin/${id}/permissions`,
    method: 'patch',
    data: { permissions: data },
    onStart: usersRequested,
    onEnd: usersRequestEnded,
  });

// export const deleteUser = (id) =>
//   apiCallBegan({
//     url: `/users/${id}`,
//     method: 'delete',
//     onStart: usersRequested,
//     onError: usersRequestFailed,
//     onEnd: usersRequestEnded,
//   });

export const blockUser = (id) =>
  apiCallBegan({
    url: `/users/${id}/block`,
    method: 'patch',
    onStart: usersRequested,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const unblockUser = (id) =>
  apiCallBegan({
    url: `/users/${id}/unblock`,
    method: 'patch',
    onStart: usersRequested,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const passwordResetUser = (id, data) =>
  apiCallBegan({
    url: `/users/${id}/reset-password`,
    method: 'patch',
    data,
    onStart: usersRequested,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const validateUnique = (data) =>
  apiCallBegan({
    url: `/users/validate-unique`,
    method: 'post',
    data,
  });

export const syncTimezone = (lastTimezone) =>
  apiCallBegan({
    url: `/users/sync-timezone`,
    method: 'patch',
    data: { lastTimezone },
  });

export const searchUsers = (name, params = {}) =>
  apiCallBegan({
    url: '/users/search',
    params: { name, ...params },
  });

//selector
export const selectList = createSelector(
  (state) => state.users,
  (users) => ({
    list: users.list,
    loading: users.loading,
  }),
);
export const selectLoading = createSelector(
  (state) => state.users,
  (users) => users.loading,
);
export const selectUser = createSelector(
  (state) => state.users,
  (users) => ({
    user: users.current,
    loading: users.loading,
  }),
);
