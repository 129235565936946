import { useCallback, useMemo } from 'react';
import { Layout, Menu, Result } from 'antd';
import { LogoutOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth';
import { UpdatePasswordModal } from 'components/UpdatePasswordModal';
import DownloadTransactionsModal from 'components/Transactions/DownloadTransactionsModal';

const { Header, Content } = Layout;

const Dashboard = () => {
  const dispatch = useDispatch();

  const signOut = useCallback(() => dispatch(logout()), [dispatch]);

  const topMenu = useMemo(
    () => [
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        onClick: signOut,
        label: 'Log out',
      },
    ],
    [signOut],
  );

  return (
    <Layout>
      <Layout className="site-layout">
        <Header className="site-layout-header" style={{ padding: 0 }}>
          <Menu
            triggerSubMenuAction="click"
            mode="horizontal"
            theme="light"
            style={{ justifyContent: 'end' }}
            items={topMenu}
          />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-content">
            <Result
              icon={<CloudDownloadOutlined />}
              title="Download latest personal transactions"
              extra={<DownloadTransactionsModal />}
            />
          </div>
        </Content>
        <UpdatePasswordModal />
      </Layout>
    </Layout>
  );
};

export default Dashboard;
