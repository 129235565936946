import { toLocalTime, toOptional } from 'utils/format';

export const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    defaultSortOrder: 'descend',
    width: 95,
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: (date) => (date ? new Date(date).toLocaleDateString() : '-'),
  },
  {
    title: 'Day',
    dataIndex: 'date',
    key: 'weekDay',
    width: 62,
    align: 'center',
    // compare by day of week check if not null,  Sunday should be the last day of week
    sorter: (a, b) => a.weekDay - b.weekDay,
    render: (date) => (date ? toLocalTime(date, { weekday: 'short' }, 'en-EN') : '-'),
  },
  {
    title: 'Time',
    dataIndex: 'date',
    key: 'time',
    align: 'center',
    width: 65,
    sorter: (a, b) => {
      if (!a.date || !b.date) return 0;
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate.getHours() * 60 + aDate.getMinutes() - (bDate.getHours() * 60 + bDate.getMinutes());
    },

    render: (date) => (date ? toLocalTime(date, { hour: '2-digit', minute: '2-digit' }) : '-'),
  },
  {
    title: 'Room',
    dataIndex: 'room',
    key: 'room',
    align: 'center',
    width: 70,
    render: toOptional,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: 365,
    render: toOptional,
  },
  {
    title: 'BI',
    dataIndex: 'buyIn',
    key: 'buyIn',
    align: 'center',
    width: 65,
    render: toOptional,
  },
  {
    title: 'Qty',
    dataIndex: 'quality',
    key: 'quality',
    align: 'center',
    width: 65,
    //sorter with null check
    sorter: (a, b) => (a.quality || 0) - (b.quality || 0),
    render: toOptional,
  },
  {
    title: 'Course',
    dataIndex: 'course',
    key: 'course',
    align: 'center',
    width: 105,
    sorter: (a, b) => (a.course || 0) - (b.course || 0),
    render: toOptional,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 65,
    sorter: (a, b) => (a.type || 0) - (b.type || 0),
    render: toOptional,
  },
  {
    title: 'Series',
    dataIndex: 'series',
    key: 'series',
    align: 'center',
    width: 265,
    render: toOptional,
  },
  {
    title: 'GTD',
    dataIndex: 'guarantee',
    key: 'guarantee',
    align: 'center',
    width: 65,
    sorter: (a, b) =>
      (a.guarantee ? parseInt(a.guarantee?.substring(1).replaceAll(',', '')) : 0) -
      (b.guarantee ? parseInt(b.guarantee?.substring(1).replaceAll(',', '')) : 0),
    render: toOptional,
  },
  {
    title: 'WDay',
    dataIndex: 'weekDay',
    key: 'weekDay',
    align: 'center',
    width: 65,
    sorter: (a, b) => (a.weekDay || 0) - (b.weekDay || 0),
    render: toOptional,
  },
  {
    title: 'Re',
    dataIndex: 'reentry',
    key: 'reentry',
    align: 'center',
    width: 65,
    render: toOptional,
  },
  {
    title: 'BB',
    dataIndex: 'startBB',
    key: 'startBB',
    align: 'center',
    width: 65,
    render: toOptional,
  },
  {
    title: 'Str',
    dataIndex: 'structure',
    key: 'structure',
    align: 'center',
    width: 65,
    render: toOptional,
  },
  {
    title: 'aAbl',
    dataIndex: 'actualAbl',
    key: 'actualAbl',
    align: 'center',
    width: 65,
    render: toOptional,
  },
  {
    title: 'mAbl',
    dataIndex: 'medAbl',
    key: 'medAbl',
    align: 'center',
    width: 70,
    render: toOptional,
  },
  {
    title: 'mRank',
    dataIndex: 'medRank',
    key: 'medRank',
    align: 'center',
    width: 80,
    render: toOptional,
  },
  {
    title: 'StrAdj',
    dataIndex: 'strAdj',
    key: 'strAdj',
    align: 'center',
    width: 65,
    sorter: (a, b) => (a.strAdj || -10000) - (b.strAdj || -10000),
    render: toOptional,
  },
  {
    title: 'WBC',
    dataIndex: 'wouldBeCourse',
    key: 'wouldBeCourse',
    align: 'center',
    width: 65,
    sorter: (a, b) => (a.wouldBeCourse || 0) - (b.wouldBeCourse || 0),
    render: toOptional,
  },
  {
    title: 'Diff',
    dataIndex: 'diff',
    key: 'diff',
    align: 'center',
    width: 65,
    render: toOptional,
  },
  {
    title: 'ID',
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    width: 82,
    render: toOptional,
  },
];
