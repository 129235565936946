import { useCallback, useEffect } from 'react';
import { Divider, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useTableParams } from 'hooks/useTableParams';
import { getPersonal } from 'store/personal-transactions';
import DownloadTransactionsModal from 'components/Transactions/DownloadTransactionsModal';
import CreateTransactionModal from './fragments/CreateTransactionModal';
import PersonalTransactionsTable from './fragments/PersonalTransactionsTable';

const tableParams = {
  pagination: {
    page: 1,
    pageSize: 40,
  },
};

export const PersonalTransactions = () => {
  const dispatch = useDispatch();
  const { params, addFilter, nextPage } = useTableParams(tableParams);

  const fetchTransactions = useCallback(() => dispatch(getPersonal(params)), [dispatch, params]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  return (
    <>
      <Space>
        <CreateTransactionModal onCreate={fetchTransactions} />
        <DownloadTransactionsModal />
      </Space>

      <Divider />
      <PersonalTransactionsTable onFilter={addFilter} next={nextPage} />
    </>
  );
};
