import { apiCallBegan } from './api';

export const getRoles = () =>
  apiCallBegan({
    url: '/roles',
  });
export const getPermissions = () =>
  apiCallBegan({
    url: '/permissions',
  });

export const createPermission = (data) =>
  apiCallBegan({
    url: '/permissions',
    method: 'post',
    data,
  });
