import { apiCallBegan } from './api';

//api calls
export const getHold = (userId) => apiCallBegan({ url: `/users/${userId}/hold` });

export const getMyHold = () => apiCallBegan({ url: '/users/hold/my' });

export const updateHold = (userId, data) =>
  apiCallBegan({
    url: `/users/${userId}/hold`,
    method: 'put',
    data,
  });
