import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { physicalPurposes } from 'config/transactions';
import { getName } from 'utils/account';
import { withFormErrors } from 'utils/common';
import { toCurrency } from 'utils/format';
import { natural } from 'utils/validators';
import { createInternalTransaction, getInternal, selectLoading } from 'store/internal-transactions';
import { getSameAccounts, selectLoading as selectAccountsLoading } from 'store/accounts';
import { useToggle } from 'hooks/useToggle';
import { UserSelect } from 'components/Form/UserSelect';
import InputNumber from 'components/Form/InputNumber';

const CreatePhysicalTransactionModal = () => {
  const dispatch = useDispatch();
  const [accountsList, setAccountsList] = useState([]);
  const [fromUser, setFromUser] = useState(null);
  const [toUser, setToUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();
  const loading = useSelector(selectLoading);
  const accountsLoading = useSelector(selectAccountsLoading);

  useEffect(() => {
    if (fromUser && toUser) {
      // noinspection JSUnresolvedReference
      dispatch(getSameAccounts(fromUser, toUser)).then(({ data }) => setAccountsList(data));
    }
  }, [dispatch, fromUser, toUser]);

  const onFinish = useCallback(
    (values) =>
      withFormErrors(form, async () => {
        await dispatch(createInternalTransaction(values));
        dispatch(getInternal());
        form.resetFields();
        deactivate();
      }),
    [dispatch, form, deactivate],
  );

  const onAccountSelect = useCallback(
    (id) => setSelectedAccount(accountsList.find((account) => account._id === id)),
    [accountsList],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Create physical transaction
      </Button>
      <Modal
        title="Create physical transaction"
        open={active}
        onOk={form.submit}
        confirmLoading={loading}
        onCancel={deactivate}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item label="From" name="fromUser" rules={[{ required: true }]}>
                <UserSelect onChange={setFromUser} filter={{ hidden: true }} />
              </Form.Item>
            </Col>
            <Col span={2} style={{ textAlign: 'center', paddingTop: '35px' }}>
              <ArrowRightOutlined />
            </Col>
            <Col span={11}>
              <Form.Item
                label="To"
                name="toUser"
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return value === getFieldValue('fromUser')
                        ? Promise.reject(new Error('Can not be same account'))
                        : Promise.resolve();
                    },
                  }),
                ]}
              >
                <UserSelect onChange={setToUser} filter={{ hidden: true }} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={10}>
            <Col span={12}>
              <Form.Item label="Account" name="fromAccount" rules={[{ required: true }]}>
                <Select
                  loading={accountsLoading}
                  disabled={!fromUser || !toUser}
                  virtual={false}
                  onChange={onAccountSelect}
                >
                  {accountsList.map((account) => (
                    <Select.Option key={account._id}>{getName(account)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Amount" name="amount" rules={[{ required: true }, natural]}>
                <InputNumber style={{ width: '100%' }} addonAfter={toCurrency(selectedAccount?.currency)} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Commission" name="commission" rules={[natural]}>
                <InputNumber style={{ width: '100%' }} addonAfter={toCurrency(selectedAccount?.currency)} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Purpose" name="purpose" rules={[{ required: true }]}>
            <Select options={physicalPurposes} virtual={false} />
          </Form.Item>
          <Form.Item label="Comment" name="comment" style={{ marginBottom: 0 }}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreatePhysicalTransactionModal;
