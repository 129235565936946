import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Divider, notification, Row, Space, Tag } from 'antd';
import { getLatest as getBtcLatest, selectBtcRates } from 'store/btc-rates';
import { recalculate } from 'store/payments';
import { getLatest as getEurLatest } from 'store/rates';
import PaymentsTable from './fragments/PaymentsTable/PaymentsTable';
import TransactionsTable from './fragments/TransactionsTable';
import PaymentModal from './fragments/PaymentModal';
import TransactionModal from './fragments/TransactionModal';

export const ExternalTransactions = () => {
  const dispatch = useDispatch();

  const { latest: btcLatest } = useSelector(selectBtcRates);

  useEffect(() => {
    dispatch(getBtcLatest());
    dispatch(getEurLatest());
  }, [dispatch]);

  const requestRecalculation = useCallback(async () => {
    await dispatch(recalculate());
    notification.success({ message: 'Payments recalculation will be done soon' });
  }, [dispatch]);

  return (
    <>
      <Space>
        <PaymentModal />
        <TransactionModal />
        <Tag>BTC rate: {btcLatest?.ratio?.toFixed(2)}$</Tag>
      </Space>
      <Button style={{ float: 'right' }} type="primary" onClick={requestRecalculation}>
        Recalculate
      </Button>
      <Divider />
      <Row gutter={10}>
        <Col span={8}>
          <PaymentsTable />
        </Col>
        <Col span={16}>
          <TransactionsTable />
        </Col>
      </Row>
    </>
  );
};
