import moment from 'moment';

export const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    render: (type) => (type === '0' ? 'All (1 - 8)' : type),
  },
  {
    title: 'Entries',
    dataIndex: 'entries',
    key: 'entries',
    align: 'center',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    align: 'center',
  },
  {
    title: 'Reentry',
    dataIndex: 'reentry',
    key: 'reentry',
    align: 'center',
  },
  {
    title: 'Reentry %',
    dataIndex: 'reentryPercentage',
    key: 'reentryPercentage',
    align: 'center',
    render: (profitPercent) => (profitPercent !== '-' ? `${profitPercent} %` : '-'),
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    key: 'profit',
    align: 'center',
  },
  {
    title: 'Av. Profit',
    dataIndex: 'averageProfit',
    key: 'averageProfit',
    align: 'center',
  },
  {
    title: 'ABI',
    dataIndex: 'abi',
    key: 'abi',
    align: 'center',
  },
  {
    title: 'Total ROI',
    dataIndex: 'totalROI',
    key: 'totalROI',
    align: 'center',
  },
  {
    title: 'TurboRatio',
    dataIndex: 'turboRatio',
    key: 'turboRatio',
    align: 'center',
  },
  {
    title: 'FinishesEarly',
    dataIndex: 'finishesEarly',
    key: 'finishesEarly',
    align: 'center',
  },
];

export const tournamentsColumns = [
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    align: 'center',
    render: (startDate) => startDate ? moment(startDate * 1000).format('DD.MM.YYYY') : '-',
    sorter: (a, b) => {
      if (a.startDate === null && b.startDate === null) {
        return 0;
      } else if (a.startDate === null) {
        return 1;
      } else if (b.startDate === null) {
        return -1;
      } else {
        return moment(a.startDate * 1000).diff(moment(b.startDate * 1000));
      }
    },
  },
  {
    title: 'Start Time',
    dataIndex: 'startDate',
    key: 'startTime',
    align: 'center',
    render: (startDate) => startDate ? moment(startDate * 1000).format('HH:mm') : '-',
  },
  {
    title: 'Room',
    dataIndex: 'room',
    key: 'room',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Buy-In',
    dataIndex: 'buyIn',
    key: 'buyIn',
    align: 'center',
  },
  {
    title: 'End Time',
    dataIndex: 'endDate',
    key: 'endTime',
    align: 'center',
    render: (endDate) => endDate ? moment(endDate * 1000).format('HH:mm') : '-',
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    align: 'center',
  },
  {
    title: 'Result',
    dataIndex: 'result',
    key: 'result',
    align: 'center',
  },
];

export const types = [
  {
    value: 0,
    label: 'All (1 - 8)',
  },
  {
    value: 1,
    label: '1 - pko regspeed',
  },
  {
    value: 2,
    label: '2 - 8-9 max non-pko regspeed',
  },
  {
    value: 3,
    label: '3 - 5-7 max non-pko regspeed',
  },
  {
    value: 4,
    label: '4 - 5-7 max pko turbo',
  },
  {
    value: 5,
    label: '5 - 5-7 max pko hyper',
  },
  {
    value: 6,
    label: '6 - 8-9 max pko turbo',
  },
  {
    value: 7,
    label: '7 - 5-7 max non-pko turbo/hyper',
  },
  {
    value: 8,
    label: '8 - 8-9 max non-pko turbo/hyper',
  },
];
