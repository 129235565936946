import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { createPayment, getAllPayments, selectPayments } from 'store/payments';
import { getAllExternalTransactions } from 'store/external-transactions';

const rules = [{ required: true }];

const currencies = [
  {
    label: '$',
    value: 'USD',
  },
  {
    label: '€',
    value: 'EUR',
  },
  {
    label: '₿',
    value: 'BTC',
  },
];

const initialValues = { balance: 0, isCrypto: false };

const PaymentModal = () => {
  const dispatch = useDispatch();
  const [accountForm] = Form.useForm();
  const { active, activate, deactivate } = useToggle();
  const { loading } = useSelector(selectPayments);

  const addPayment = useCallback(
    async (values) => {
      await dispatch(createPayment(values));
      deactivate();
      accountForm.resetFields();
      dispatch(getAllPayments());
      dispatch(getAllExternalTransactions());
    },
    [dispatch, accountForm, deactivate],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Add Payment
      </Button>
      <Modal title="Add payment" open={active} onOk={accountForm.submit} confirmLoading={loading} onCancel={deactivate}>
        <Form form={accountForm} onFinish={addPayment} layout="vertical" initialValues={initialValues}>
          <Row gutter={10} style={{ marginBottom: -24 }}>
            <Col span={18}>
              <Form.Item label="Name" name="name" rules={rules}>
                <Input
                  placeholder="Name"
                  addonAfter={
                    <Form.Item name="isCrypto" valuePropName="checked" noStyle>
                      <Checkbox>crypto</Checkbox>
                    </Form.Item>
                  }
                />
              </Form.Item>
              <Form.Item label="Balance" name="balance" rules={rules} hidden />
            </Col>
            <Col span={6}>
              <Form.Item label="Currency" name="currency" rules={rules}>
                <Select placeholder="Currency">
                  {currencies.map(({ label, value }) => (
                    <Select.Option key={value}>{label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PaymentModal;
