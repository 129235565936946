import { useCallback, useState } from 'react';

export const useFileUpload = () => {
  const [fileList, setFileList] = useState([]);

  const setStatus = useCallback((status) => {
    if (status === 'success') {
      setFileList([]);
    } else {
      setFileList((current) => [
        {
          ...current[0],
          status,
        },
      ]);
    }
  }, []);

  const handleChange = useCallback((payload) => {
    setFileList(payload.fileList);
  }, []);

  return {
    fileList,
    handleChange,
    setStatus,
  };
};
