import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import './i18n.js';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', new RegExp(`^${process.env.REACT_APP_BASE_URL}`)],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [new RegExp(`^${process.env.REACT_APP_BASE_URL}`)],
    }),
  ],
  tracesSampleRate: process.env.REACT_APP_STAGE === 'production' ? 0.1 : 1.0,
  replaysSessionSampleRate: process.env.REACT_APP_STAGE === 'production' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: process.env.REACT_APP_STAGE === 'production' ? 0.1 : 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
