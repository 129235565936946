import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTableParams } from 'hooks/useTableParams';
import { useColumns } from 'hooks/playing-myself/useColumns';
import useLoading from 'hooks/useLoading';
import { deleteActiveRequest, getMyActiveRequests, selectActive } from 'store/playing-myself';

export const ActiveRequestsTable = () => {
  const dispatch = useDispatch();
  const commonColumns = useColumns([], ['type', 'tournamentCourse', 'course', 'user', 'closedAt']);
  const list = useSelector(selectActive);
  const { params, handleTableChange } = useTableParams();
  const { t } = useTranslation();
  const { loading, execute } = useLoading();

  const fetchRequests = useCallback(
    (filter) => {
      execute(() => dispatch(getMyActiveRequests(filter)));
    },
    [execute, dispatch],
  );

  useEffect(() => {
    fetchRequests(params);
  }, [fetchRequests, params]);

  const deleteRequest = useCallback(
    async (id) => {
      await dispatch(deleteActiveRequest(id));
      fetchRequests();
    },
    [dispatch, fetchRequests],
  );

  const columns = useMemo(
    () => [
      ...commonColumns,
      {
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        width: 106,
        render: (id) => (
          <Tooltip title={t('delete')}>
            <Popconfirm title={t('playingmyself.popup.confirmation')} onConfirm={() => deleteRequest(id)}>
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        ),
      },
    ],
    [deleteRequest],
  );

  return (
    <Table
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        total: list.totalDocs,
        current: list.page,
        showSizeChanger: false,
        pageSize: list.limit,
        style: { marginBottom: 0 },
      }}
      columns={columns}
      rowKey="_id"
      dataSource={list.docs}
    />
  );
};
