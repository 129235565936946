import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { selectSplitPackages } from 'store/packages';

const columns = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    align: 'center',
    render: (user) => user,
  },
  {
    title: 'Buy-in Percentage',
    dataIndex: 'buyInPercent',
    key: 'buyInPercent',
    align: 'center',
    render: (buyInPercent) => `${buyInPercent} %`,
  },
  {
    title: 'Profit Percentage',
    dataIndex: 'profitPercent',
    key: 'profitPercent',
    align: 'center',
    render: (profitPercent) => `${profitPercent} %`,
  },
  {
    title: 'Open Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (createdAt) => (createdAt ? new Date(createdAt).toLocaleString() : '-'),
  },
  {
    title: 'Closed Date',
    dataIndex: 'closedAt',
    key: 'closedAt',
    align: 'center',
    render: (closedAt) => (closedAt ? new Date(closedAt).toLocaleString() : '-'),
  },
];

export const SplitTable = ({ onRowClick }) => {
  const { splitPackages } = useSelector(selectSplitPackages);

  return (
    <Table
      rowKey="_id"
      onRow={(record) => ({
        onClick: (e) => {
          e.preventDefault();
          onRowClick(record);
        },
      })}
      dataSource={splitPackages}
      columns={columns}
      pagination={false}
      scroll={{ y: 390 }}
    />
  );
};
