import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Form, Input } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { createPermission } from 'store/rbac';

const CreatePermissionModal = ({ onCreate }) => {
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const create = useCallback(
    async (data) => {
      await dispatch(createPermission(data));
      form.resetFields();
      onCreate?.();
      deactivate();
    },
    [dispatch, deactivate, form, onCreate],
  );

  return (
    <>
      <Button onClick={activate}>Create</Button>
      <Modal open={active} title="Create permission" onCancel={deactivate} onOk={form.submit}>
        <Form form={form} onFinish={create}>
          <Form.Item name="name" label="Permission" style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreatePermissionModal;
