import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import PublicRoute from './components/PublicRoute';
import Guard from './components/Guard';
import ModalProvider from './components/Modal/ModalProvider';
import Login from './pages/Login';
import './App.css';

const App = () => (
  <div className="App">
    <Provider store={store()}>
      <BrowserRouter>
        <ModalProvider>
          <Switch>
            <PublicRoute exact path="/login">
              <Login />
            </PublicRoute>
            <Route path="*">
              <Guard />
            </Route>
          </Switch>
        </ModalProvider>
      </BrowserRouter>
    </Provider>
  </div>
);

export default App;
