import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Steps } from 'antd';
import { AimOutlined, BookOutlined, LikeOutlined } from '@ant-design/icons';
import { toAmount } from 'utils/format';
import useLoading from 'hooks/useLoading';
import { getById as getAccountById } from 'store/accounts';
import { sendPlayedReport, sendNotPlayedReport } from 'store/playing-myself';
import { ModalContext } from 'components/Modal/ModalProvider';
import { STEPS } from './config';
import { HasPlayed, Footer as HasPlayedFooter } from './steps/HasPlayed';
import { Done, Footer as DoneFooter } from './steps/Done';
import { ReportForm, Footer as ReportFormFooter } from './steps/ReportForm';

const CreateReportModal = () => {
  const {
    payload: { record, beforeReport, afterReport },
    deactivate,
  } = useContext(ModalContext);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(STEPS.HAS_PLAYED);
  const [reportForm] = Form.useForm();
  const [activeAccount, setActiveAccount] = useState({});
  const { execute, loading } = useLoading();
  const dispatch = useDispatch();

  useEffect(() => {
    // noinspection JSUnresolvedReference
    dispatch(getAccountById(record.accountId[0])).then((res) => setActiveAccount(res.data));
  }, [dispatch, record]);

  const close = useCallback(
    (withCallback = false) => {
      deactivate();
      setCurrentStep(STEPS.HAS_PLAYED);
      reportForm.resetFields();
      if (withCallback) {
        afterReport?.();
      }
    },
    [deactivate, reportForm, afterReport],
  );

  const goNext = useCallback(() => {
    setCurrentStep((current) => current + 1);
  }, []);

  const goBack = useCallback(() => {
    setCurrentStep((current) => current - 1);
  }, []);

  const submitNotPlayedReport = useCallback(async () => {
    if (beforeReport) {
      await beforeReport();
    }
    execute(() => dispatch(sendNotPlayedReport(record._id)));
    setCurrentStep(STEPS.DONE);
  }, [beforeReport, execute, dispatch, record._id]);

  const submitPlayedReport = useCallback(
    async (values) => {
      if (beforeReport) {
        await beforeReport();
      }
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'screenshot' && values[key]) {
          formData.append(key, values[key], values[key].name);
        } else {
          formData.append(key, values[key]);
        }
      });
      formData.append(
        'comment',
        `${activeAccount.groupId}, ${record.tournament || record.tournamentInfo?.name}, ${toAmount(
          record.buyin,
          activeAccount.currency,
        )}`,
      );
      await execute(() => dispatch(sendPlayedReport(record._id, formData)));
      setCurrentStep(STEPS.DONE);
    },
    [beforeReport, execute, dispatch, record, activeAccount],
  );

  const steps = useMemo(
    () => [
      {
        icon: <AimOutlined />,
        content: <HasPlayed record={record} />,
        footer: <HasPlayedFooter onYes={goNext} onNo={submitNotPlayedReport} loading={loading} />,
      },
      {
        icon: <BookOutlined />,
        content: (
          <ReportForm form={reportForm} request={record} onFinish={submitPlayedReport} activeAccount={activeAccount} />
        ),
        footer: <ReportFormFooter onBack={goBack} onNext={reportForm.submit} loading={loading} />,
      },
      {
        icon: <LikeOutlined />,
        content: <Done />,
        footer: <DoneFooter onClick={() => close(true)} />,
      },
    ],
    [record, reportForm, activeAccount, loading, close],
  );

  return (
    <Modal
      title={t('playingmyself.table.report')}
      open
      cancelText={t('cancel')}
      onCancel={() => close(false)}
      footer={steps[currentStep].footer}
    >
      <Steps current={currentStep} items={steps} style={{ marginBottom: 24 }} />
      {steps[currentStep].content}
    </Modal>
  );
};

export default CreateReportModal;
