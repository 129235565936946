import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruJSON from './locale/ru.json';

i18n.use(initReactI18next).init({
  resources: {
    ru: { ...ruJSON },
  }, // Where we're gonna put translations' files
  lng: 'ru', // Set the initial language of the App
});
