// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'gridGraphics',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    gridGraphicsRequestStarted: (gridGraphics) => {
      gridGraphics.loading = true;
    },
    gridGraphicsRequestFailed: (gridGraphics, action) => {
      gridGraphics.error = action.payload.data;
    },
    gridGraphicsRequestEnded: (gridGraphics) => {
      gridGraphics.loading = false;
    },
    gridGraphicsListReceived: (gridGraphics, action) => {
      gridGraphics.list = action.payload.data;
    },
  },
});

export const {
  gridGraphicsRequestStarted,
  gridGraphicsRequestFailed,
  gridGraphicsRequestEnded,
  gridGraphicsListReceived,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: `/grid-graphics`,
    params,
    onStart: gridGraphicsRequestStarted,
    onSuccess: gridGraphicsListReceived,
    onError: gridGraphicsRequestFailed,
    onEnd: gridGraphicsRequestEnded,
  });

export const getMy = () =>
  apiCallBegan({
    url: `/grid-graphics/my`,
    onStart: gridGraphicsRequestStarted,
    onSuccess: gridGraphicsListReceived,
    onError: gridGraphicsRequestFailed,
    onEnd: gridGraphicsRequestEnded,
  });

export const importGridGraphics = (data) =>
  apiCallBegan({
    url: `/grid-graphics`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: gridGraphicsRequestStarted,
    onEnd: gridGraphicsRequestEnded,
  });

//selector
export const selectList = createSelector(
  (state) => state.gridGraphics,
  (gridGraphics) => ({
    list: gridGraphics.list,
    loading: gridGraphics.loading,
  }),
);
