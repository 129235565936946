import { Spin, Table } from 'antd';
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const TotalABITable = ({ dataSource, loading }) => {
  const { t } = useTranslation();

  const chooseClass = useCallback(
    (record) => {
      if (record.degree === record.playerName) {
        return 'underscored';
      }

      const isWarning = dataSource
        .filter((item) => item.degree === item.playerName && item.abiPercent === record.abiPercent)
        .some((item) => item.ev > record.ev);

      return isWarning ? 'red' : 'green';
    },
    [dataSource],
  );

  const columns = [
    {
      title: t('totals.table.player'),
      dataIndex: 'playerName',
      key: 'playerName',
      align: 'center',
      render: (playerName) => (playerName ? playerName : '-'),
    },
    {
      title: t('totals.table.abi_percent'),
      dataIndex: 'abiPercent',
      key: 'abiPercent',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.abi'),
      dataIndex: 'abi',
      key: 'abi',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.min_buyin'),
      dataIndex: 'minBI',
      key: 'minBI',
      align: 'center',

      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.max_buyin'),
      dataIndex: 'maxBI',
      key: 'maxBI',
      align: 'center',

      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.hands'),
      dataIndex: 'numberOfHands',
      key: 'numberOfHands',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.ev'),
      dataIndex: 'ev',
      key: 'ev',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.net'),
      dataIndex: 'net',
      key: 'net',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.percent'),
      dataIndex: 'percent',
      key: 'percent',
      align: 'center',
      render: (value) => (value != null ? (value * 100).toFixed(2) : '-'),
    },
    {
      title: t('totals.table.roi'),
      dataIndex: 'roi',
      key: 'roi',
      align: 'center',
      render: (value) => (value ? (value * 100).toFixed(2) : '-'),
    },
    {
      title: t('totals.table.tourneys'),
      dataIndex: 'tourneys',
      key: 'tourneys',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      loading={{ indicator: <Spin />, spinning: loading }}
      rowClassName={chooseClass}
    />
  );
};
