import { Button, Image, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileImageOutlined } from '@ant-design/icons';

const ImageCell = ({ src, tooltipPlacement = 'top' }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <Tooltip title={t('viewScreenshot')} placement={tooltipPlacement}>
      <Button type="primary" icon={<FileImageOutlined />} disabled={!src} onClick={() => setPreviewVisible(true)} />
      <Image
        style={{ display: 'none' }}
        preview={{ visible: previewVisible, src, onVisibleChange: setPreviewVisible }}
      />
    </Tooltip>
  );
};

export default ImageCell;
