import { Button, Modal, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useMemo } from 'react';
import { BookOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { toAmount, toDate, toLocalTime, toOptional } from 'utils/format';
import { isReportTimeExpired, shouldBeReported } from 'utils/playing-myself';
import { useToggle } from 'hooks/useToggle';
import { revertReport } from 'store/playing-myself';
import { ID as CREATE_MODAL_ID } from 'components/PlayingMyself/CreateReportModal/config';
import { ModalContext } from 'components/Modal/ModalProvider';
import { ActionsTable } from './ActionsTable';

const ViewReportModal = ({ record, afterReport }) => {
  const { t } = useTranslation();
  const { active, activate, deactivate } = useToggle();
  const { activate: openModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const revertUserReport = useCallback(async () => {
    deactivate();
    openModal(CREATE_MODAL_ID, {
      record,
      beforeReport: () => dispatch(revertReport(record._id)),
      afterReport,
    });
  }, [dispatch, record, deactivate, afterReport]);

  const footer = useMemo(
    () =>
      shouldBeReported(record) && isReportTimeExpired(record) ? (
        [
          <Button key="revert" type="danger" onClick={revertUserReport}>
            {t('playingmyself.report.button.revertreport')}
          </Button>,
          <Button key="close" onClick={deactivate} type="primary">
            {t('OK')}
          </Button>,
        ]
      ) : (
        <Button key="ok" onClick={deactivate} type="primary">
          {t('OK')}
        </Button>
      ),
    [record, revertUserReport, deactivate, t],
  );

  return (
    <>
      <Button type="primary" onClick={activate} icon={<BookOutlined />} />
      <Modal title={t('playingmyself.table.report')} open={active} footer={footer} width={700} closable={false}>
        {record && active && (
          <>
            <Descriptions title="Tournament Details" bordered column={1} size="small" style={{ marginBottom: 24 }}>
              <Descriptions.Item label={t('playingmyself.table.tournament')}>
                {record.tournament || record.tournamentInfo?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('playingmyself.table.date')}>
                {toDate(record.tournamentDate, {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Descriptions.Item>
              <Descriptions.Item label={t('playingmyself.table.buyin')}>
                {toAmount(record.buyin, record.account[0].currency)}
              </Descriptions.Item>
              <Descriptions.Item label={t('playingmyself.table.entry')}>
                {toOptional(t(`playingmyself.create.selector.${record.entry}`))}
              </Descriptions.Item>
              <Descriptions.Item label={t('playingmyself.table.bbcount')}>
                {toOptional(record.bbCount)}
              </Descriptions.Item>
              <Descriptions.Item label={t('playingmyself.table.updateddate')}>
                {toLocalTime(record.updatedAt)}
              </Descriptions.Item>
              <DescriptionsItem label={t('playingmyself.table.requestcomment')}>
                {toOptional(record.comment)}
              </DescriptionsItem>
            </Descriptions>

            <ActionsTable record={record} />
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewReportModal;
