import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import { toDate } from 'utils/format';
import { positive } from 'utils/validators';
import { useToggle } from 'hooks/useToggle';
import useLoading from 'hooks/useLoading';
import { getActiveByUserId, updatePackage } from 'store/packages';
import InputNumber from 'components/Form/InputNumber';

const UpdatePackageModal = ({ id }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [currentPackage, setCurrentPackage] = useState(null);
  const [form] = Form.useForm();
  const { execute, loading } = useLoading();

  useEffect(() => {
    if (active) {
      // noinspection JSUnresolvedReference
      dispatch(getActiveByUserId(id)).then((res) => {
        setCurrentPackage(res.data);
        form.setFieldsValue({
          buyInPercent: res.data.buyInPercent,
          profitPercent: res.data.profitPercent,
        });
      });
    }
  }, [active, dispatch, id, form]);

  const handleSubmit = useCallback(
    async (values) => {
      await execute(() => dispatch(updatePackage(currentPackage._id, values)));
      deactivate();
    },
    [currentPackage, dispatch, deactivate, execute],
  );

  return (
    <>
      <Button type="primary" icon={<PercentageOutlined />} onClick={activate} />
      <Modal
        open={active}
        onCancel={deactivate}
        title={`Update (${toDate(currentPackage?.createdAt)})`}
        onOk={form.submit}
        confirmLoading={loading}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row gutter={10} style={{ marginBottom: -24 }}>
            <Col span={12}>
              <Form.Item name="buyInPercent" label="Buy-in percent" rules={[{ required: true }, positive]}>
                <InputNumber min={0} max={100} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="profitPercent" label="Profit percent" rules={[{ required: true }, positive]}>
                <InputNumber min={0} max={100} addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePackageModal;
