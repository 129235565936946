import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip } from 'antd';

export const POPOVER_EXCLUDED = ['IP', 'WPN', 'ПРОЧИЕ'];

export const LimitsTable = ({ list, loading }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('room'),
        dataIndex: 'room',
        key: 'room',
        align: 'right',
        render: (room) => {
          return POPOVER_EXCLUDED.includes(room.toUpperCase()) ? (
            room
          ) : (
            <Tooltip title="Турниры дороже прописаны в основном расписании">{room}</Tooltip>
          );
        },
      },
      {
        title: '1',
        dataIndex: 'one',
        key: 'one',
        align: 'center',
      },
      {
        title: '2 - 4',
        dataIndex: 'twoFour',
        key: 'twoFour',
        align: 'center',
      },
      {
        title: '5 - 9',
        dataIndex: 'fiveEight',
        key: 'fiveEight',
        align: 'center',
      },
    ],
    [t],
  );

  return <Table rowKey="_id" columns={columns} dataSource={list} pagination={false} loading={loading} />;
};
