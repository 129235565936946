// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'external-transactions',
  initialState: {
    list: { metadata: [{ total: 0, page: 1, hasNextPage: false }], docs: [] },
    loading: false,
    error: null,
  },
  reducers: {
    transactionsRequestStarted: (transactions) => {
      transactions.loading = true;
    },
    transactionsRequestEnded: (transactions) => {
      transactions.loading = false;
    },
    transactionsReceived: (transactions, action) => {
      transactions.list = action.payload.data;
    },
    transactionsAppend: (transactions, action) => {
      transactions.list = {
        ...action.payload.data,
        docs: [...transactions.list.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const { transactionsRequestStarted, transactionsRequestEnded, transactionsAppend, transactionsReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAllExternalTransactions = (params) =>
  apiCallBegan({
    url: `/external-transactions`,
    params,
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
    onSuccess: params?.pagination?.page > 1 ? transactionsAppend : transactionsReceived,
  });

export const createExternalTransaction = (data) =>
  apiCallBegan({
    url: `/external-transactions/`,
    method: 'post',
    data,
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
  });

export const selectExternalTransactions = createSelector(
  (state) => state.externalTransactions,
  (externalTransactions) => ({
    list: externalTransactions.list,
    loading: externalTransactions.loading,
    error: externalTransactions.error,
  }),
);
