import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Descriptions, Divider, Form, Modal, notification, Popconfirm, Select, Table } from "antd";
import debounce from 'lodash.debounce';
import { toDate } from 'utils/format';
import { useToggle } from 'hooks/useToggle';
import { PLAYING_MYSELF } from 'config/transactions';
import { getSum } from 'store/transactions';
import { get, getAll } from "store/user-stats";
import { getUserById } from 'store/users';
import {
  closePackageByAdmin,
  freezePackage,
  getActiveByUserId,
  getFrozenPackages,
  selectLoading,
} from 'store/packages';
import { COLUMNS } from './config';

const FreezeModal = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const [statsList, setStatsList] = useState([]);
  const [selectedStat, setSelectedStat] = useState(null);
  const [playingMyselfSum, setPlayingMyselfSum] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserPackage, setCurrentUserPackage] = useState(null);
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedStat && currentUser) {
      // noinspection JSUnresolvedReference
      dispatch(
        getSum({
          userId: currentUser._id,
          dateFrom: new Date(selectedStat.packageCreatedAt).getTime(),
          purpose: PLAYING_MYSELF,
        }),
      ).then(({ data }) => setPlayingMyselfSum(data?.[0]?.amountUSD));
    }
  }, [dispatch, selectedStat, currentUser]);

  const searchStat = useCallback(
    async (query) => {
      const { data } = await dispatch(getAll({ username: query, packageProfitTo: 0, onFreeze: 0 }));
      setStatsList(data || []);
    },
    [dispatch],
  );

  const selectStat = useCallback(
    async (id) => {
      setSelectedStat(statsList.find((stat) => stat.user === id));
      const { data: user } = await dispatch(getUserById(id));
      const { data: currentPackage } = await dispatch(getActiveByUserId(user._id));
      setCurrentUser(user);
      setCurrentUserPackage(currentPackage);
    },
    [statsList, dispatch],
  );

  const verifyWereNoChanges = useCallback(async () => {
    const { data } = await dispatch(get(selectedStat._id));
    return data?.packageProfit === selectedStat?.packageProfit
      && data?.playerProfit === selectedStat?.playerProfit
      && data?.projectProfit === selectedStat?.projectProfit;
  }, [selectedStat]);

  const onFinish = useCallback(async () => {
    const noChanges = await verifyWereNoChanges();

    if (noChanges) {
      await dispatch(closePackageByAdmin(currentUserPackage._id, currentUserPackage.user));
      await dispatch(freezePackage(currentUserPackage._id));
      await dispatch(getFrozenPackages());
      deactivate();
    } else {
      notification.error({ message: "Package has been changed. Please refresh the page and try again." });
    }

  }, [currentUserPackage, selectedStat, deactivate, dispatch]);

  return (
    <>
      <Button type="primary" onClick={activate}>
        Freeze
      </Button>
      <Modal
        title="Freeze package"
        open={active}
        confirmLoading={loading}
        okText={
          <Popconfirm title="Are you sure you want to freeze this package?" onConfirm={form.submit} okText="Confirm">
            Freeze
          </Popconfirm>
        }
        onCancel={deactivate}
      >
        <Form form={form} onFinish={debounce(onFinish, 250)} layout="vertical">
          <Form.Item label="User" name="user">
            <Select
              showSearch
              placeholder="Type login..."
              onSearch={debounce(searchStat, 500)}
              onSelect={selectStat}
              filterOption={false}
              allowClear
              virtual={false}
            >
              {statsList.map(({ user, username }) => (
                <Select.Option key={user}>{username}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        {currentUser && (
          <>
            <Divider />
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Package Created At">{toDate(selectedStat?.packageCreatedAt)}</Descriptions.Item>
              <Descriptions.Item label="Playing Myself Sum">
                {playingMyselfSum?.toFixed(2) || '0.00'}$
              </Descriptions.Item>
              <Descriptions.Item label="Package Profit">{selectedStat?.packageProfit.toFixed(2)}$</Descriptions.Item>
              <Descriptions.Item label="Player Profit">{selectedStat?.playerProfit.toFixed(2)}$</Descriptions.Item>
              <Descriptions.Item label="Project Profit / To Freeze">
                {selectedStat?.projectProfit.toFixed(2)}$
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Table
              dataSource={currentUser.accounts}
              rowClassName={(acc) => !acc.active && 'disabled-row'}
              columns={COLUMNS}
              pagination={false}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default FreezeModal;
