import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { getMyAccounts, selectList } from 'store/accounts';
import { getTournaments, selectTournaments } from 'store/tournaments';
import { getSerialTournaments, selectSerialTournaments } from 'store/serial-tournaments';
import { TournamentsTable } from 'components/Tournaments/TournamentsTable';
import { SerialTournamentsTable } from 'components/Tournaments/SerialTournamentsTable';
import { LimitsModal } from './fragments/LimitsModal';

export const Tournaments = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectTournaments);
  const { list: serialList, loading: serialLoading } = useSelector(selectSerialTournaments);
  const { list: accounts } = useSelector(selectList);
  const { params, addFilter, handleTableChange } = useTableParams({});
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    dispatch(getTournaments(params));
    dispatch(getSerialTournaments(params));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(getMyAccounts());
  }, [dispatch]);

  return (
    <>
      <LimitsModal />
      <Divider />
      <Row justify="space-between" gutter={10}>
        <Col span={13}>
          <TournamentsTable
            offset={311}
            loading={loading}
            dataSource={list}
            onFilter={addFilter}
            onChange={handleTableChange}
            myRooms={accounts.map((account) => account.type)}
          />
        </Col>
        <Col span={11}>
          <SerialTournamentsTable offset={234} loading={serialLoading} dataSource={serialList} />
        </Col>
      </Row>
    </>
  );
};
