export const accounts = [
  'Skrill',
  'Luxon',
  'Neteller',
  'PS',
  'PSes',
  'WMX',
  'ПокерOK',
  'GGPoker',
  'BetKings',
  'OlyBet',
  'Black Chip Poker',
  'Natural8',
  '888',
  '888.ES',
  'PartyPoker',
  'Optibet',
  'PokerKing',
  'ACR',
  'Ya Poker',
  'Redstar',
  'Titan',
  'Betsafe',
  'BetFair',
  'ChampionPoker',
  'TigerGaming',
  'BetOnline',
  'SportsBetting',
  'CoinPoker',
  '4Poker',
  'WPT Global',
  'OnTheWay',
];

export const accountRU = new Proxy(
  {
    OnTheWay: 'В пути',
  },
  {
    get: (target, type) => target[type] || type,
  },
);

export const currencies = ['USD', 'EUR'];

export const regions = ['RU', 'INT'];

export const getName = (account) =>
  `${accountRU[account.type]} - ${account.currency}${account.region ? ` - ${account.region}` : ''}`;

export const compare = (a, b) =>
  accounts.indexOf(a.type) - accounts.indexOf(b.type) ||
  currencies.indexOf(a.currency) - currencies.indexOf(b.currency) ||
  regions.indexOf(a.region) - currencies.indexOf(b.region);

export const sort = (list) => {
  if (!list) return [];

  return [...list].sort(compare);
};
