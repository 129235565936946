import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Input } from 'antd';
import debounce from 'lodash.debounce';
import { REQUEST_TYPE } from 'config/playing-myself';
import { getName } from 'utils/account';
import { toCurrency } from 'utils/format';
import { negative } from 'utils/validators';
import { is } from 'store/auth';
import InputNumber from 'components/Form/InputNumber';
import { ImageUpload } from 'components/Form/ImageUpload';

export const ReportForm = ({ activeAccount, request, form, onFinish }) => {
  const { t } = useTranslation();
  const userIs = useSelector(is);

  const amountProps = useMemo(() => {
    const props = {
      rules: [{ required: true }],
      max: undefined,
    };

    if (request.status === REQUEST_TYPE.FL_FREEROLL) {
      props.rules.push(negative);
      props.max = 0;
    }

    return props;
  }, [request]);

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row justify="space-between" gutter={10}>
        <Col span={12}>
          <Form.Item label={t('account')}>
            <Input value={getName(activeAccount)} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('amount')} name="amount" rules={amountProps.rules}>
            <InputNumber addonAfter={toCurrency(activeAccount.currency)} max={amountProps.max} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('screenshot')}
        name="screenshot"
        rules={[{ required: !userIs('admin') }]}
        style={{ marginBottom: 0 }}
      >
        <ImageUpload />
      </Form.Item>
    </Form>
  );
};

export const Footer = ({ onBack, onNext, loading }) => {
  const { t } = useTranslation();

  return [
    <Button key="back" onClick={onBack}>
      {t('playingmyself.report.button.back')}
    </Button>,
    <Button key="next" onClick={debounce(onNext, 300)} loading={loading}>
      {t('playingmyself.report.button.submit')}
    </Button>,
  ];
};
