// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'userStats',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    userStatsRequestStarted: (userStats) => {
      userStats.loading = true;
    },
    userStatsRequestFailed: (userStats, action) => {
      userStats.error = action.payload.data;
    },
    userStatsRequestEnded: (userStats) => {
      userStats.loading = false;
    },
    userStatsReceived: (userStats, action) => {
      userStats.list = action.payload.data;
    },
    userStatsNextReceived: (userStats, action) => {
      userStats.list.push(...action.payload.data);
    },
  },
});

export const { userStatsRequestStarted, userStatsRequestFailed, userStatsRequestEnded, userStatsReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params = {}) =>
  apiCallBegan({
    url: '/user-stats',
    params,
    onStart: userStatsRequestStarted,
    onSuccess: userStatsReceived,
    onError: userStatsRequestFailed,
    onEnd: userStatsRequestEnded,
  });

export const get = (id) =>
  apiCallBegan({
    url: `/user-stats/${id}`,
    onStart: userStatsRequestStarted,
    onError: userStatsRequestFailed,
    onEnd: userStatsRequestEnded,
  });

export const getLatest = () => apiCallBegan({ url: '/user-stats/latest' });

export const calculate = () =>
  apiCallBegan({
    url: '/calculate-stats',
    method: 'post',
    onStart: userStatsRequestStarted,
    onError: userStatsRequestFailed,
    onEnd: userStatsRequestEnded,
  });

//selector
export const selectUserStats = createSelector(
  (state) => state.userStats,
  (userStats) => ({
    list: userStats.list,
    loading: userStats.loading,
  }),
);
