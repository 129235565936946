import { Input, Table } from 'antd';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { selectPaidPackages } from 'store/packages';
import { InfiniteScroll } from 'components/InfiniteScroll';

export const ClosedTable = ({ onRowClick, onFilter, onPaginate }) => {
  const { paidPackages, loading } = useSelector(selectPaidPackages);

  return (
    <InfiniteScroll dataLength={paidPackages.docs.length} hasMore={paidPackages.hasNextPage} next={onPaginate}>
      <Table
        loading={loading}
        onRow={(record) => ({ onClick: () => onRowClick(record) })}
        dataSource={paidPackages.docs}
        pagination={false}
      >
        <Table.Column
          title={<Input placeholder="Player" onChange={debounce(onFilter('username'), 500)} />}
          dataIndex="user"
          key="user"
          align="center"
        />
        <Table.Column
          title="Buy-in Percentage"
          dataIndex="buyInPercent"
          key="buyInPercent"
          align="center"
          render={(buyInPercent) => `${buyInPercent} %`}
        />
        <Table.Column
          title="Profit Percentage"
          dataIndex="profitPercent"
          key="profitPercent"
          align="center"
          render={(profitPercent) => `${profitPercent} %`}
        />
        <Table.Column
          title="Open Date"
          dataIndex="createdAt"
          key="createdAt"
          align="center"
          render={(createdAt) => (createdAt ? new Date(createdAt).toLocaleString() : '-')}
        />
        <Table.Column
          title="Closed Date"
          dataIndex="closedAt"
          key="closedAt"
          align="center"
          render={(closedAt) => (closedAt ? new Date(closedAt).toLocaleString() : '-')}
        />
        <Table.Column
          title="Approve Date"
          dataIndex="paidAt"
          key="paidAt"
          align="center"
          render={(paidAt) => (paidAt ? new Date(paidAt).toLocaleString() : '-')}
        />
      </Table>
    </InfiniteScroll>
  );
};
