import { useEffect } from 'react';
import { Result, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMe, selectAuthenticated, is } from 'store/auth';
import Admin from 'layouts/Admin';
import Audit from 'layouts/Audit';
import Dashboard from 'layouts/Dashboard';

const Guard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authenticated = useSelector(selectAuthenticated);
  const hasRole = useSelector(is);

  useEffect(() => {
    if (!authenticated) {
      // noinspection JSUnresolvedReference
      dispatch(getMe()).catch(() => history.push('login'));
    }
  }, [dispatch, history, authenticated]);

  const getContent = () => {
    if (!authenticated) {
      return (
        <Row style={{ minHeight: '100vh' }} align="middle" justify="space-around">
          <Spin size="large" />
        </Row>
      );
    }

    switch (true) {
      case hasRole('user'):
        return <Dashboard />;
      case hasRole('admin'):
        return <Admin />;
      case hasRole('audit'):
        return <Audit />;
      default:
        return <Result status={404} title="Something went wrong" subTitle="Please, refresh the page" />;
    }
  };

  return getContent();
};

export default Guard;
