import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'actions',
  initialState: {
    list: [],
    lastAction: null,
    loading: false,
    error: null,
  },
  reducers: {
    actionsRequestStarted: (actions) => {
      actions.loading = true;
      actions.error = null;
    },
    actionsRequestFailed: (actions, action) => {
      actions.error = action.payload.data;
    },
    actionsRequestEnded: (actions) => {
      actions.loading = false;
    },
    actionsListReceived: (actions, action) => {
      actions.list = action.payload.data;
    },
    lastActionReceived: (actions, action) => {
      actions.lastAction = action.payload.data;
    },
    actionCreated: (actions, action) => {
      actions.list.push(action.payload.data);
    },
  },
});

export const {
  actionsRequestStarted,
  actionsRequestFailed,
  actionsRequestEnded,
  actionsListReceived,
  lastActionReceived,
  actionCreated,
} = slice.actions;

export default slice.reducer;

// API calls
export const fetchActions = (entityId, entityType) =>
  apiCallBegan({
    url: `/actions`,
    params: { entityType, entityId },
    onStart: actionsRequestStarted,
    onSuccess: actionsListReceived,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

export const fetchLastAction = (entityType, entityId) =>
  apiCallBegan({
    url: `/actions/last`,
    params: { entityType, entityId },
    onStart: actionsRequestStarted,
    onSuccess: lastActionReceived,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

export const createAction = (data) =>
  apiCallBegan({
    url: `/actions`,
    method: 'POST',
    data,
    onStart: actionsRequestStarted,
    onSuccess: actionCreated,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

// Selectors
export const selectActions = createSelector(
  (state) => state.actions,
  (actions) => ({
    list: actions.list,
    lastAction: actions.lastAction,
    loading: actions.loading,
    error: actions.error,
  }),
);
