import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'accountLogs',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    accountLogsRequestStarted: (accountLogs) => {
      accountLogs.loading = true;
    },
    accountLogsRequestFailed: (accountLogs, action) => {
      accountLogs.error = action.payload.data;
    },
    accountLogsRequestEnded: (accountLogs) => {
      accountLogs.loading = false;
    },
    accountLogsReceived: (accountLogs, action) => {
      accountLogs.list = action.payload.data;
    },
  },
});

export const { accountLogsRequestStarted, accountLogsRequestFailed, accountLogsRequestEnded, accountLogsReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAll = (username, range = null) =>
  apiCallBegan({
    url: `/account-stats/${username}`,
    params: { range },
    onStart: accountLogsRequestStarted,
    onSuccess: accountLogsReceived,
    onError: accountLogsRequestFailed,
    onEnd: accountLogsRequestEnded,
  });

//selector
export const selectAccountLogs = createSelector(
  (state) => state.accountLogs,
  (accountLogs) => ({
    list: accountLogs.list,
    loading: accountLogs.loading,
  }),
);
