import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/en_GB';
import { selectAnalytics } from 'store/analytics';
import { types } from '../config';

const SearchBar = ({ onSearch }) => {
  const [form] = Form.useForm();
  const [daysDisabled, setDaysDisabled] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const { analyticsLoading, tournamentsLoading } = useSelector(selectAnalytics);

  const onFinish = useCallback(
    (values) => {
      const { alias, type, days, startDate, endDate, lastTournamentsCount } = values;

      onSearch(
        {
          alias,
          type,
          days,
          startDate: startDate?.unix(),
          endDate: endDate?.unix(),
        },
        {
          alias,
          lastTournamentsCount,
        },
      );
    },
    [onSearch],
  );

  const handleDayChange = useCallback((value) => {
    setDateDisabled(value !== null && value >= 0);
  }, []);

  const handleDateChange = useCallback(() => {
    const { startDate, endDate } = form.getFieldsValue(['startDate', 'endDate']);
    setDaysDisabled(!!startDate || !!endDate);
  }, [form]);

  return (
    <Form onFinish={onFinish} form={form}>
      <Space>
        <Form.Item name="alias">
          <Input placeholder="Alias" required />
        </Form.Item>
        <Form.Item name="type">
          <Select allowClear placeholder="Type" style={{ minWidth: 250 }} virtual={false}>
            {types.map((type) => (
              <Select.Option value={type.value} key={type.label}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="days">
          <InputNumber
            min={0}
            style={{ minWidth: 100 }}
            onChange={handleDayChange}
            disabled={daysDisabled}
            placeholder="Last Days"
          />
        </Form.Item>
        <Form.Item name="lastTournamentsCount">
          <InputNumber min={100} max={500} style={{ minWidth: 160 }} placeholder="Last Tournaments" />
        </Form.Item>
        <Form.Item name="startDate">
          <DatePicker
            locale={locale}
            showTime
            format="DD.MM.YYYY HH:mm"
            placeholder="Start date"
            disabled={dateDisabled}
            onChange={handleDateChange}
          />
        </Form.Item>
        <Form.Item name="endDate">
          <DatePicker
            locale={locale}
            showTime
            format="DD.MM.YYYY HH:mm"
            placeholder="End date"
            disabled={dateDisabled}
            onChange={handleDateChange}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={'submit'} disabled={analyticsLoading || tournamentsLoading}>
            Calculate
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default SearchBar;
