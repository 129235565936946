import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { playerPersonalPurposes } from 'config/transactions';
import { getName } from 'utils/account';
import { withFormErrors } from 'utils/common';
import { amount } from 'utils/validators';
import { toCurrency } from 'utils/format';
import { useToggle } from 'hooks/useToggle';
import { getMyAccounts, selectList } from 'store/accounts';
import { createMyPersonalTransaction, selectLoading } from 'store/personal-transactions';
import { ImageUpload } from 'components/Form/ImageUpload';
import InputNumber from 'components/Form/InputNumber';

const CreateTransactionModal = ({ onCreate }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [currency, setCurrency] = useState('$');
  const accounts = useSelector(selectList);
  const loading = useSelector(selectLoading);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleAccountChange = useCallback(
    (id) => {
      const account = accounts.list.find((acc) => acc._id === id);
      setCurrency(toCurrency(account?.currency));
    },
    [accounts],
  );

  useEffect(() => {
    if (active) {
      dispatch(getMyAccounts({ inner: false }));
    }
  }, [active, dispatch]);

  const onClose = useCallback(() => {
    deactivate();
    form.resetFields();
  }, [deactivate, form]);

  const onFinish = useCallback(
    (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'screenshot') {
          formData.append(key, values[key], values[key].name);
        } else {
          formData.append(key, values[key]);
        }
      });

      withFormErrors(form, async () => {
        await dispatch(createMyPersonalTransaction(formData));
        await onCreate();
        onClose();
      });
    },
    [dispatch, onCreate, onClose, form],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        {t('create')}
      </Button>
      <Modal
        title={t('createTransaction')}
        open={active}
        onOk={form.submit}
        cancelText={t('cancel')}
        confirmLoading={loading}
        onCancel={onClose}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row justify="space-between" gutter={10}>
            <Col span={18}>
              <Form.Item label={t('account')} name="toAccount" rules={[{ required: true }]}>
                <Select virtual={false} loading={accounts.loading} onChange={handleAccountChange}>
                  {accounts.list.map((account) => (
                    <Select.Option key={account._id}>{getName(account)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t('amount')} name="amount" rules={[{ required: true }, amount]}>
                <InputNumber addonAfter={currency} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={t('purpose')} name="purpose" rules={[{ required: true }]}>
            <Select options={playerPersonalPurposes} virtual={false} />
          </Form.Item>
          <Form.Item label={t('comment')} name="comment">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label={t('screenshot')} name="screenshot" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
            <ImageUpload />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTransactionModal;
