export const amount = () => ({
  validator(_, value) {
    return value === 0 ? Promise.reject(new Error('Invalid amount')) : Promise.resolve();
  },
});

export const natural = () => ({
  validator(_, value) {
    return value <= 0 ? Promise.reject(new Error('Invalid amount')) : Promise.resolve();
  },
});

export const positive = () => ({
  validator(_, value) {
    return value < 0 ? Promise.reject(new Error('Invalid amount')) : Promise.resolve();
  },
});

export const negative = () => ({
  validator(_, value) {
    return value > 0 ? Promise.reject(new Error('Invalid amount')) : Promise.resolve();
  },
});
