import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, Modal, notification, Upload } from 'antd';
import { UploadOutlined, FileSyncOutlined } from '@ant-design/icons';
import { withFormErrors } from 'utils/common';
import { useToggle } from 'hooks/useToggle';
import { useFileUpload } from 'hooks/useFileUpload';
import { importTournaments, selectTournamentsLoading } from 'store/tournaments';

export const ImportModal = () => {
  const dispatch = useDispatch();
  const { fileList, handleChange, setStatus } = useFileUpload();
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();
  const loading = useSelector(selectTournamentsLoading);

  const onSubmit = useCallback(
    async (values) => {
      const formData = new FormData();
      formData.append('activationDate', values.activationDate.format());
      formData.append('csvFile', values.file.file, values.file.file.name);

      return withFormErrors(form, async () => {
        await dispatch(importTournaments(formData));
        deactivate();
        notification.open({
          message: 'File has been uploaded successfully',
          description: 'New schedule loading is in progress. This may take up to several minutes',
        });
        form.resetFields();
        setStatus('success');
      });
    },
    [dispatch, form, deactivate, setStatus],
  );

  return (
    <>
      <Button type="primary" onClick={activate} icon={<FileSyncOutlined />}>
        Import
      </Button>
      <Modal confirmLoading={loading} open={active} title="Import tournaments" onOk={form.submit} onCancel={deactivate}>
        <Form name="import-tournaments" form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="Activation date" name="activationDate" rules={[{ required: true }]}>
            <DatePicker showTime format="YYYY/MM/DD HH:mm" autoComplete="off" />
          </Form.Item>
          <Form.Item label="CSV file" name="file" rules={[{ required: true }]}>
            <Upload
              accept=".csv"
              maxCount={1}
              onChange={handleChange}
              fileList={fileList}
              beforeUpload={() => false}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
