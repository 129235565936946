import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider } from 'antd';
import { getPaidPackages, getSplitPackages } from 'store/packages';
import { useTableParams } from 'hooks/useTableParams';
import { SplitTable } from './fragments/SplitTable';
import { ClosedTable } from './fragments/ClosedTable';
import { SplitVerificationModal } from './fragments/SplitVerificationModal';

const PAGINATION = { page: 1, pageSize: 40 };

export const Split = () => {
  const dispatch = useDispatch();
  const [activePackage, setActivePackage] = useState(null);
  const { params, addFilter, nextPage } = useTableParams({ pagination: PAGINATION });

  const refresh = useCallback(
    (reFetch = true) => {
      setActivePackage(null);
      if (reFetch) {
        dispatch(getSplitPackages());
        dispatch(getPaidPackages(params));
      }
    },
    [dispatch, params],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      <SplitTable onRowClick={setActivePackage} />
      <Divider />
      <ClosedTable onRowClick={setActivePackage} onFilter={addFilter} onPaginate={nextPage} />
      <SplitVerificationModal activePackage={activePackage} onClose={refresh} />
    </>
  );
};
