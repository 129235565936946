import { Typography } from 'antd';
import { toCurrency } from 'utils/format';

const { Text } = Typography;

const Amount = ({ amount, currency, secondary }) => {
  let str = `${amount.toFixed(currency === 'BTC' ? 5 : 2)}${toCurrency(currency)}`;

  if (secondary?.amount) {
    return (
      <>
        {str} (
        <Text type="secondary">
          {secondary.addonBefore}
          {secondary.amount.toFixed(2)} $
        </Text>
        )
      </>
    );
  }

  return str;
};

export default Amount;
