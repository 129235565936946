import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row, Tabs } from 'antd';
import { getMy, selectList } from 'store/totals';
import { getMy as getMyAbi, selectList as selectAbiList } from 'store/totals-abi';
import { getMy as getMyGrid, selectList as selectGridList } from 'store/grid-graphics';
import { getMy as getMyRadar, selectList as selectRadarList } from 'store/radar-graphics';
import { CommonTotalTable } from 'components/Totals/CommonTotalTable';
import { TotalABITable } from 'components/Totals/TotalABITable';

export const Totals = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectList);
  const { list: listAbi, loading: loadingAbi } = useSelector(selectAbiList);
  const { list: listGridGraphics, loading: loadingGridGraphics } = useSelector(selectGridList);
  const { list: listRadarGraphics, loading: loadingRadarGraphics } = useSelector(selectRadarList);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMy());
    dispatch(getMyAbi());
    dispatch(getMyGrid());
    dispatch(getMyRadar());
  }, [dispatch]);

  return (
    <Tabs type="card" defaultActiveKey="totals">
      {list?.length > 0 && (
        <Tabs.TabPane tab={t('totals.tabs.totals')} key="totals">
          <Row justify="space-between" gutter={10}>
            <Col span={11}>
              <CommonTotalTable dataSource={list} loading={loading} />
            </Col>
            <Col span={13}>
              <TotalABITable dataSource={listAbi} loading={loadingAbi} />
            </Col>
          </Row>
        </Tabs.TabPane>
      )}
      {listGridGraphics?.length > 0 && (
        <Tabs.TabPane tab={t('totals.tabs.grid')} key="grid">
          <Image src={listGridGraphics[0].url} preview={false} loading={loadingGridGraphics} />
        </Tabs.TabPane>
      )}
      {listRadarGraphics?.length > 0 && (
        <Tabs.TabPane tab={t('totals.tabs.radar')} key="radar">
          <Image src={listRadarGraphics[0].url} preview={false} loading={loadingRadarGraphics} />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};
