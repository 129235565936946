import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popconfirm, Space } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { permissions } from 'config/permissions';
import { hasRole } from 'utils/users';
import { can } from 'store/auth';
import { blockUser, selectList, unblockUser } from 'store/users';
import UpdateModal from './UpdateModal/UpdateModal';
import UpdateAdminModal from './UpdateModal/UpdateAdminModal';
import UpdatePackageModal from './UpdatePackageModal';

const ActionsCell = ({ id, user, onChange }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectList);
  const hasPermission = useSelector(can);

  const toggleStatus = useCallback(async () => {
    const action = user.blockedAt ? unblockUser : blockUser;
    await dispatch(action(id));
    onChange();
  }, [id, dispatch, user.blockedAt]);

  const icon = user.blockedAt ? <UnlockOutlined /> : <LockOutlined />;

  return (
    <Space>
      {hasRole(user, 'admin') ? (
        <UpdateAdminModal id={id} onOk={onChange} disabled={!hasPermission(permissions.CanManageAdmins)} />
      ) : (
        <>
          <UpdateModal id={id} onOk={onChange} />
          {hasPermission(permissions.CanManagePackages) && <UpdatePackageModal id={id} />}
        </>
      )}
      <Popconfirm title="Are you sure?" onConfirm={toggleStatus}>
        <Button type="primary" disabled={loading} danger icon={icon} />
      </Popconfirm>
    </Space>
  );
};

export default ActionsCell;
