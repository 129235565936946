import { useMemo } from 'react';
import { Select } from 'antd';
import { toCurrency } from 'utils/format';

export const CurrencySelect = ({ currencies, ...props }) => {
  const options = useMemo(
    () =>
      currencies.map((currency) => ({
        value: currency,
        label: toCurrency(currency),
      })),
    [currencies],
  );

  return <Select options={options} {...props} />;
};
