// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'rates',
  initialState: {
    latest: null,
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    ratesRequestStarted: (rates) => {
      rates.loading = true;
    },
    ratesRequestFailed: (rates, action) => {
      rates.error = action.payload.data;
    },
    ratesRequestEnded: (rates) => {
      rates.loading = false;
    },
    ratesReceived: (rates, action) => {
      rates.list = action.payload.data;
    },
    latestReceived: (rates, action) => {
      rates.latest = action.payload.data;
    },
  },
});

export const { ratesRequestStarted, ratesRequestFailed, ratesRequestEnded, ratesReceived, latestReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAll = (date = null) =>
  apiCallBegan({
    url: '/rates',
    params: date ? { date } : null,
    onStart: ratesRequestStarted,
    onSuccess: ratesReceived,
    onError: ratesRequestFailed,
    onEnd: ratesRequestEnded,
  });

export const getLatest = () =>
  apiCallBegan({
    url: '/rates/latest',
    onStart: ratesRequestStarted,
    onSuccess: latestReceived,
    onError: ratesRequestFailed,
    onEnd: ratesRequestEnded,
  });

//selector
export const selectRates = createSelector(
  (state) => state.rates,
  (rates) => ({
    latest: rates.latest,
    list: rates.list,
    loading: rates.loading,
  }),
);
