import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'playingMyself',
  initialState: {
    active: {
      page: 1,
      limit: 10,
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    archive: {
      page: 1,
      limit: 10,
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    playingMyselfRequestStarted: (playingMyself) => {
      playingMyself.loading = true;
    },
    playingMyselfRequestFailed: (playingMyself, action) => {
      playingMyself.error = action.payload.data;
    },
    playingMyselfRequestEnded: (playingMyself) => {
      playingMyself.loading = false;
    },
    playingMyselfReceived: (playingMyself, action) => {
      playingMyself.active = action.payload.data;
    },
    archiveReceived: (playingMyself, action) => {
      playingMyself.archive = action.payload.data;
    },
  },
});

export const {
  playingMyselfRequestStarted,
  playingMyselfRequestFailed,
  playingMyselfRequestEnded,
  playingMyselfReceived,
  archiveReceived,
} = slice.actions;

export default slice.reducer;

//api calls
export const createRequest = (data) =>
  apiCallBegan({
    url: `/playing-myself`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: playingMyselfRequestStarted,
    onEnd: playingMyselfRequestEnded,
  });

export const updatePlayingMyselfRequest = (id, data) =>
  apiCallBegan({
    url: `/playing-myself/${id}`,
    method: 'PATCH',
    data,
    onStart: playingMyselfRequestStarted,
    onSuccess: playingMyselfRequestEnded,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const getAllActiveRequests = (params) =>
  apiCallBegan({
    url: `/playing-myself`,
    method: 'GET',
    params: params,
    onStart: playingMyselfRequestStarted,
    onSuccess: playingMyselfReceived,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const getAllActiveRequestsCount = () =>
  apiCallBegan({
    url: `/playing-myself/count`,
    method: 'GET',
  });

export const getAllArchivedRequests = (params) =>
  apiCallBegan({
    url: `/playing-myself/archive`,
    method: 'GET',
    params,
    onStart: playingMyselfRequestStarted,
    onSuccess: archiveReceived,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const downloadArchivedRequests = (filter) =>
  apiCallBegan({
    url: `/playing-myself/archive/export`,
    method: 'GET',
    params: filter,
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const markAsWeirdRequests = (id) =>
  apiCallBegan({
    url: `/playing-myself/${id}/weird`,
    method: 'PATCH',
    onStart: playingMyselfRequestStarted,
    onEnd: playingMyselfRequestEnded,
  });

export const getMyActiveRequests = (params) =>
  apiCallBegan({
    url: `/playing-myself/my`,
    method: 'GET',
    params,
    onStart: playingMyselfRequestStarted,
    onSuccess: playingMyselfReceived,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const getMyArchiveRequests = (params) =>
  apiCallBegan({
    url: `/playing-myself/my/archive`,
    method: 'GET',
    params,
    onStart: playingMyselfRequestStarted,
    onSuccess: archiveReceived,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const getMyWaitingForReportRequests = (params) =>
  apiCallBegan({
    url: `/playing-myself/my/unreported`,
    method: 'GET',
    params,
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const deleteActiveRequest = (id) =>
  apiCallBegan({
    url: `/playing-myself/${id}`,
    method: 'delete',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const cancelDecisionRequest = (id) =>
  apiCallBegan({
    url: `/playing-myself/${id}/cancel`,
    method: 'patch',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const freeRollDecisionRequest = (id) =>
  apiCallBegan({
    url: `/playing-myself/${id}/freeroll`,
    method: 'patch',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const packageDecisionRequest = (id) =>
  apiCallBegan({
    url: `/playing-myself/${id}/package`,
    method: 'patch',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const myselfDecisionRequest = (id) =>
  apiCallBegan({
    url: `/playing-myself/${id}/myself`,
    method: 'patch',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const sendNotPlayedReport = (requestId) =>
  apiCallBegan({
    url: `/playing-myself/${requestId}/report/not-played`,
    method: 'post',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const sendPlayedReport = (requestId, data) =>
  apiCallBegan({
    url: `/playing-myself/${requestId}/report/played`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'post',
    data,
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const revertReport = (requestId) =>
  apiCallBegan({
    url: `/playing-myself/${requestId}/report/revert`,
    method: 'POST',
    onStart: playingMyselfRequestStarted,
    onError: playingMyselfRequestFailed,
    onEnd: playingMyselfRequestEnded,
  });

export const selectActive = createSelector(
  (state) => state.playingMyself,
  (playingMyself) => playingMyself.active,
);

export const selectArchive = createSelector(
  (state) => state.playingMyself,
  (playingMyself) => playingMyself.archive,
);
