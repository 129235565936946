import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { getAll, selectList } from 'store/serial-evaluation';
import { useTableParams } from 'hooks/useTableParams';
import SmartTable from 'components/Table/SmartTable';
import { ImportArchiveModal } from './fragments/ImportArchiveModal';
import { SerialEvaluationBar } from './fragments/SerialEvaluationBar';
import { columns } from './config';
import 'index.css';

const PAGINATION = { page: 1, pageSize: 40 };

export const SerialEvaluation = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectList);
  const { params, addParams, nextPage } = useTableParams({ pagination: PAGINATION });

  useEffect(() => {
    dispatch(getAll(params));
  }, [dispatch, params]);

  const onSearch = useCallback((values) => addParams({ ...values, pagination: PAGINATION }), [addParams]);

  return (
    <>
      <ImportArchiveModal />
      <Divider />
      <SerialEvaluationBar onSearch={onSearch} loading={loading} />
      <SmartTable
        rowKey="_id"
        loading={loading}
        columns={columns}
        dataSource={list.docs}
        className="serial-evaluation-table"
        scroll={{ y: 'calc(100vh - 290px)' }}
        infinity
        onFetch={nextPage}
      />
    </>
  );
};
