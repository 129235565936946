import { Divider, Space, Tooltip, Typography } from 'antd';
import { BorderlessTableOutlined, CrownOutlined, GlobalOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { toCurrency } from 'utils/format';

export const COLUMNS = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
  },
  {
    title: 'Screen Name',
    dataIndex: 'screenName',
    key: 'screenName',
    align: 'center',
    render: (screenName, record) => (
      <>
        <span>{screenName || '-'}</span>
        {record.transferAddress && (
          <>
            <Divider style={{ margin: 0 }} />{' '}
            <Typography.Text type="secondary">({record.transferAddress})</Typography.Text>
          </>
        )}
      </>
    ),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    align: 'center',
    render: (balance, account) => `${balance.toFixed(2)} ${toCurrency(account.currency)}`,
  },
  {
    title: "Add's",
    dataIndex: 'vip',
    key: 'options',
    align: 'center',
    render: (vip, account) => (
      <Space>
        {vip && (
          <Tooltip title="VIP">
            <CrownOutlined />
          </Tooltip>
        )}
        {!!account.region && (
          <Tooltip title={account.region}>
            {account.region === 'RU' ? <BorderlessTableOutlined /> : <GlobalOutlined />}
          </Tooltip>
        )}
        {account.ableToTransfer && (
          <Tooltip title="Transfer available">
            <UserSwitchOutlined />
          </Tooltip>
        )}
      </Space>
    ),
  },
];
