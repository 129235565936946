import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Descriptions, Layout, Menu, Popover, Result } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, logout } from 'store/auth';
import { syncTimezone } from 'store/users';
import { RateModal } from 'components/Rates/RateModal';
import { UpdatePasswordModal } from 'components/UpdatePasswordModal';
import { ROUTES } from './config';
import { getSideMenu } from '../utils';

const { Header, Content, Sider } = Layout;

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(localStorage.getItem('menuCollapsed') === 'true');
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const chunks = location.pathname.split('/');

  useEffect(() => {
    dispatch(syncTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));
  }, [dispatch]);

  const signOut = useCallback(() => dispatch(logout()), [dispatch]);

  const sideMenu = useMemo(() => getSideMenu({ routes: ROUTES, renderLabel: t }), [t]);

  const setSideMenuCollapsed = useCallback((value) => {
    setCollapsed(value);
    localStorage.setItem('menuCollapsed', value);
  }, []);

  const topMenu = useMemo(
    () => [
      {
        key: 'rates',
        label: <RateModal />,
      },
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        onClick: signOut,
        label: t('logout'),
      },
    ],
    [signOut, t],
  );

  return (
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={setSideMenuCollapsed}>
        {user && (
          <div style={{ padding: 24, textAlign: 'center', color: '#fff' }}>
            <Popover
              content={
                <Descriptions title={user.username} column={1} bordered style={{ display: 'inline-block' }}>
                  <Descriptions.Item style={{ padding: 7 }} label={t('course')}>
                    {user.course}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ padding: 7 }} label={t('country')}>
                    {user.country}
                  </Descriptions.Item>
                </Descriptions>
              }
            >
              <Avatar src={user.avatar} style={{ marginBottom: 20 }} />
            </Popover>
          </div>
        )}
        <Menu theme="dark" defaultSelectedKeys={[`/${chunks[1]}`]} mode="inline" items={Object.values(sideMenu)} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-header" style={{ padding: 0 }}>
          <Menu
            triggerSubMenuAction="click"
            mode="horizontal"
            theme="light"
            style={{ justifyContent: 'end' }}
            selectedKeys={['rates']}
            items={topMenu}
          />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-content">
            <Switch>
              {ROUTES.map(({ path, Component }) => (
                <Route key={path} exact path={path}>
                  <Component />
                </Route>
              ))}
              <Route>
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={
                    <Link to="/">
                      <Button type="primary">Back Home</Button>
                    </Link>
                  }
                />
              </Route>
            </Switch>
          </div>
        </Content>
        <UpdatePasswordModal />
      </Layout>
    </Layout>
  );
};

export default Dashboard;
