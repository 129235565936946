import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Select } from 'antd';
import { positive } from 'utils/validators';
import { getMyAccounts, selectList } from 'store/accounts';
import InputNumber from 'components/Form/InputNumber';
import DatePicker from 'components/Form/DatePicker';

const rules = {
  required: [{ required: true, message: 'required' }],
  positive: [{ required: true, message: 'required' }, positive],
};

const BasicDetails = ({ form }) => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectList);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMyAccounts());
  }, [dispatch]);

  const rooms = useMemo(
    () => list?.filter((acc) => acc.groupId && acc.active).map((acc) => ({ value: acc._id, label: acc.type })) || [],
    [list],
  );

  return (
    <Form form={form} layout="vertical">
      <Row gutter={10} style={{ marginBottom: -24 }}>
        <Col span={10}>
          <Form.Item label={t('playingmyself.create.label.room')} name="accountId" rules={rules.required}>
            <Select virtual={false} placeholder={t('placeholder.select_room')} loading={loading} options={rooms} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label={t('playingmyself.create.label.date')} name="time" rules={rules.required}>
            <DatePicker showTime format="YYYY/MM/DD HH:mm" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label={t('playingmyself.create.label.buyin')} name="buyin" rules={rules.positive}>
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDetails;
