import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Modal, notification } from 'antd';
import { defaultSettings, PasswordInput } from 'antd-password-input-strength';
import { useToggle } from '../hooks/useToggle';
import { selectUser } from 'store/auth';
import { passwordUpdateUser } from 'store/auth';

export const UpdatePasswordModal = () => {
  const dispatch = useDispatch();

  const { active, deactivate, setActive } = useToggle();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();

  useEffect(() => {
    setActive(user.isPasswordChangeRequired);
  }, [user, setActive]);

  const onFinish = useCallback(
    async (values) => {
      try {
        await dispatch(passwordUpdateUser(user._id, values));
        deactivate();
        notification.success({
          message: 'Password updated successfully',
        });
      } catch (e) {
        notification.error({
          message: 'Old password is not correct',
        });
      }
    },
    [dispatch, deactivate, user],
  );

  return (
    <Modal
      open={active}
      title="Update your password"
      footer={[
        <Button key="cancel" type="danger" onClick={deactivate}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={form.submit}>
          Update
        </Button>,
      ]}
      closable={false}
    >
      You need update your password to continue.
      <br />
      <br />
      <Form name="update-password" form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Old password"
          name="oldPassword"
          rules={[{ required: true, message: 'Enter previous password' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New password"
          name="newPassword"
          rules={[
            { required: true, message: 'Enter new password' },
            { min: 8, message: 'Password should have at least 8 characters.' },
          ]}
        >
          <PasswordInput
            settings={{
              ...defaultSettings,
              alwaysVisible: true,
              height: 5,
            }}
          />
        </Form.Item>
        <Form.Item
          label="Repeat password"
          name="repeatPassword"
          style={{ marginBottom: 0 }}
          rules={[
            { required: true, message: 'Enter new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
