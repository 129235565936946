import { useCallback } from 'react';
import { Button, Input, notification } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';

const Success = ({ form, onCopy }) => {
  const copyToClipboard = useCallback(() => {
    notification.success({
      message: 'Credentials copied to clipboard',
    });
    onCopy();
  }, [onCopy]);

  const { username, password } = form.getFieldsValue(true);

  return (
    <>
      <p style={{ marginBottom: 24 }}>
        The user <b>{username}</b> was created.
      </p>
      <Input.Group compact>
        <Input style={{ width: 200 }} value={password} disabled />
        <CopyToClipboard text={`Login: ${username} \nPassword: ${password}`} onCopy={copyToClipboard}>
          <Button type="primary">Copy to clipboard</Button>
        </CopyToClipboard>
      </Input.Group>
    </>
  );
};

export default Success;
