import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'serialEvaluation',
  initialState: {
    list: {
      page: 1,
      limit: 40,
      totalDocs: 0,
      hasNextPage: false,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    serialEvaluationRequestStarted: (serialEvaluation) => {
      serialEvaluation.loading = true;
    },
    serialEvaluationRequestFailed: (serialEvaluation, action) => {
      serialEvaluation.error = action.payload.data;
    },
    serialEvaluationRequestEnded: (serialEvaluation) => {
      serialEvaluation.loading = false;
    },
    serialEvaluationListReceived: (serialEvaluation, action) => {
      serialEvaluation.list = action.payload.data;
    },
    serialEvaluationLoaded: (serialEvaluation, action) => {
      serialEvaluation.list = {
        ...action.payload.data,
        docs: [...serialEvaluation.list.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const {
  serialEvaluationRequestStarted,
  serialEvaluationRequestFailed,
  serialEvaluationRequestEnded,
  serialEvaluationListReceived,
  serialEvaluationLoaded,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params = {}) =>
  apiCallBegan({
    url: `/serial-evaluation`,
    params,
    onStart: serialEvaluationRequestStarted,
    onSuccess: params.pagination?.page > 1 ? serialEvaluationLoaded : serialEvaluationListReceived,
    onError: serialEvaluationRequestFailed,
    onEnd: serialEvaluationRequestEnded,
  });

export const importSerialEvaluation = (data) =>
  apiCallBegan({
    url: `/serial-evaluation`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: serialEvaluationRequestStarted,
    onEnd: serialEvaluationRequestEnded,
  });

export const getFieldValues = (params) =>
  apiCallBegan({
    url: `/serial-evaluation/fields`,
    params,
  });

//selector
export const selectList = createSelector(
  (state) => state.serialEvaluation,
  (serialEvaluation) => ({
    list: serialEvaluation.list,
    loading: serialEvaluation.loading,
  }),
);
