import { Divider } from 'antd';
import { AllActiveRequestTable } from './fragments/AllActiveRequestTable';
import { AllArchivedRequestTable } from './fragments/AllArchivedRequestTable/AllArchivedRequestTable';
import DownloadTransactionsModal from './fragments/DownloadArchiveModal';

export const PlayingMyself = () => (
  <>
    <DownloadTransactionsModal />
    <Divider />
    <AllActiveRequestTable />
    <Divider />
    <AllArchivedRequestTable />
  </>
);
