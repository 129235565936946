import React from 'react';
import InfiniteScrollComponent from 'react-infinite-scroll-component';
import { Spin } from 'antd';

/** DOCS { https://github.com/ankeetmaini/react-infinite-scroll-component#readme } */

export const InfiniteScroll = ({ dataLength, hasMore, next, children }) => (
  <InfiniteScrollComponent
    dataLength={dataLength} //This is important field to render the next data
    next={next}
    hasMore={hasMore}
    style={{ overflow: 'visible' }}
    loader={<Spin spinning style={{ margin: '0 auto' }} />}
  >
    {children} {/** !!! DO NOT TOUCH !!! => It wraps the table */}
  </InfiniteScrollComponent>
);
