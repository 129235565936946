import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'accountStats',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    accountStatsRequestStarted: (accountStats) => {
      accountStats.loading = true;
    },
    accountStatsRequestFailed: (accountStats, action) => {
      accountStats.error = action.payload.data;
    },
    accountStatsRequestEnded: (accountStats) => {
      accountStats.loading = false;
    },
    accountStatsReceived: (accountStats, action) => {
      accountStats.list = action.payload.data;
    },
    accountStatsNextReceived: (accountStats, action) => {
      accountStats.list.push(...action.payload.data);
    },
  },
});

export const { accountStatsRequestStarted, accountStatsRequestFailed, accountStatsRequestEnded, accountStatsReceived } =
  slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params = {}) =>
  apiCallBegan({
    url: '/account-stats',
    params,
    onStart: accountStatsRequestStarted,
    onSuccess: accountStatsReceived,
    onError: accountStatsRequestFailed,
    onEnd: accountStatsRequestEnded,
  });

//selector
export const selectAccountStats = createSelector(
  (state) => state.accountStats,
  (accountStats) => ({
    list: accountStats.list,
    loading: accountStats.loading,
  }),
);
