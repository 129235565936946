import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, notification, Steps } from 'antd';
import { InfoCircleOutlined, TrophyOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import useLoading from 'hooks/useLoading';
import { createRequest, getMyActiveRequests } from 'store/playing-myself';
import BasicDetails from './BasicDetails';
import TournamentInfo from './TournamentInfo';

export const CreateRequestModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [currentStep, setCurrentStep] = useState(0);
  const { execute, loading } = useLoading();
  const [basicDetails, setBasicDetails] = useState({});
  const [basicDetailsForm] = Form.useForm();
  const [tournamentForm] = Form.useForm();
  const { t } = useTranslation();

  const steps = [
    {
      title: t('playingmyself.create.steps.general'),
      icon: <InfoCircleOutlined />,
      content: <BasicDetails form={basicDetailsForm} />,
    },
    {
      title: t('playingmyself.create.steps.tournament'),
      icon: <TrophyOutlined />,
      content: <TournamentInfo form={tournamentForm} filter={basicDetails} />,
    },
  ];

  const lastStep = currentStep === steps.length - 1;

  const closeModal = useCallback(() => {
    deactivate();
    basicDetailsForm.resetFields();
    tournamentForm.resetFields();
    setCurrentStep(0);
  }, [deactivate, basicDetailsForm, tournamentForm]);

  const goNext = useCallback(async () => {
    if (!currentStep) {
      try {
        const basicDetailsFormValues = await basicDetailsForm.validateFields();
        setBasicDetails({
          ...basicDetailsFormValues,
          time: basicDetailsFormValues.time.unix() * 1000,
        });
        setCurrentStep(currentStep + 1);
      } catch (e) {}
    } else {
      const tournamentDetails = await tournamentForm.validateFields();
      const requestData = {
        ...basicDetails,
        ...tournamentDetails,
      };

      const formData = new FormData();
      Object.keys(requestData).forEach((key) => {
        if (!requestData[key]) {
          return;
        }

        if (key === 'screenshot') {
          formData.append(key, requestData[key], requestData[key].name);
        } else {
          formData.append(key, requestData[key]);
        }
      });

      await execute(() => dispatch(createRequest(formData)));
      notification.success({ message: t('playingmyself.create.notification.success') });
      dispatch(getMyActiveRequests());

      closeModal();
    }
  }, [currentStep, basicDetailsForm, basicDetails, execute, dispatch, tournamentForm, closeModal]);

  const goBack = useCallback(() => {
    setCurrentStep((current) => (current ? current - 1 : current));
    tournamentForm.resetFields();
  }, [tournamentForm]);

  const footer = [
    <Button key="back" onClick={goBack} disabled={!currentStep}>
      {t('playingmyself.create.steps.buttons.back')}
    </Button>,
    <Button key="next" onClick={goNext} loading={loading}>
      {lastStep ? t('playingmyself.create.steps.buttons.finish') : t('playingmyself.create.steps.buttons.next')}
    </Button>,
  ];

  return (
    <>
      <Button type="primary" onClick={activate}>
        {t('playingmyself.create.button')}
      </Button>
      <Modal
        style={{ top: 20 }}
        open={active}
        onCancel={closeModal}
        title={t('playingmyself.create.title')}
        footer={footer}
      >
        <Steps current={currentStep} items={steps} style={{ marginBottom: 24 }} />
        {steps[currentStep].content}
      </Modal>
    </>
  );
};
