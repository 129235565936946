import { Divider } from 'antd';
import { CreateRequestModal } from './fragments/CreateRequestModal/CreateRequestModal';
import { ArchivedRequestsTable } from './fragments/ArchivedRequestsTable/ArchivedRequestsTable';
import { ActiveRequestsTable } from './fragments/ActiveRequestsTable';

export const PlayingMyself = () => (
  <>
    <CreateRequestModal />
    <Divider />
    <ActiveRequestsTable />
    <Divider />
    <ArchivedRequestsTable />
  </>
);
