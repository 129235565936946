import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { findAllMy, getMySummary, selectFlCraft } from 'store/fl-craft';
import FlCraftSummary from 'components/FLCraft/FlCraftSummary';
import FlCraftSearch from 'components/FLCraft/FlCraftSearch';
import FlCraftTable from 'components/FLCraft/FlCraftTable';
import UploadArchiveModal from './fragments/UploadArchiveModal';

export const FLCraft = () => {
  const dispatch = useDispatch();
  const { list, summary, loading } = useSelector(selectFlCraft);
  const { params, handleTableChange, addFilter } = useTableParams();

  useEffect(() => {
    dispatch(findAllMy(params));
    dispatch(getMySummary(params));
  }, [dispatch, params]);

  const isSummaryEmpty = useMemo(() => Object.keys(summary).length === 0 && summary.constructor === Object, [summary]);

  return (
    <>
      <UploadArchiveModal />
      <Divider />
      <FlCraftSearch onChange={addFilter} />
      <Divider />
      {!isSummaryEmpty && (
        <>
          <FlCraftSummary summary={summary} />
          <Divider />
        </>
      )}
      <FlCraftTable list={list} loading={loading} onChange={handleTableChange} />
    </>
  );
};
