import { useCallback, useState } from 'react';

export const useToggle = () => {
  const [active, setActive] = useState(false);

  const activate = useCallback(() => setActive(true), []);

  const deactivate = useCallback(() => setActive(false), []);

  return {
    active,
    setActive,
    activate,
    deactivate,
  };
};
