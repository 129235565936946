import { useTranslation } from 'react-i18next';
import { Button, Descriptions } from 'antd';
import { toAmount, toLocalTime, toOptional } from 'utils/format';

export const HasPlayed = ({ record }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h2>{t('playingmyself.report.hasplayed')}</h2>
      </div>
      <Descriptions bordered column={1} size="small">
        <Descriptions.Item label={t('playingmyself.table.tournament')}>{record.tournament}</Descriptions.Item>
        <Descriptions.Item label={t('playingmyself.table.date')}>
          {toLocalTime(record.tournamentDate)}
        </Descriptions.Item>
        <Descriptions.Item label={t('playingmyself.table.buyin')}>
          {toAmount(record.buyin, record.account[0].currency)}
        </Descriptions.Item>
        <Descriptions.Item label={t('playingmyself.table.entry')}>
          {toOptional(t(`playingmyself.create.selector.${record.entry}`))}
        </Descriptions.Item>
        <Descriptions.Item label={t('playingmyself.table.bbcount')}>{toOptional(record.bbCount)}</Descriptions.Item>

        <Descriptions.Item label={t('playingmyself.table.requestcomment')}>
          {toOptional(record.comment)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export const Footer = ({ onYes, onNo, loading }) => {
  const { t } = useTranslation();

  return [
    <Button key="not-played" type="danger" onClick={onNo} loading={loading}>
      {t('playingmyself.report.button.hasnotplayed')}
    </Button>,
    <Button key="played" type="primary" onClick={onYes}>
      {t('playingmyself.report.button.hasplayed')}
    </Button>,
  ];
};
