import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Divider, Result, Space, Table } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { getAll, selectAccountLogs } from 'store/account-logs';
import { UserSelect } from 'components/Form/UserSelect';
import { getColumns } from './utils';

export const AccountLogs = () => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(selectAccountLogs);
  const [username, setUsername] = useState('');
  const [range, setRange] = useState([]);

  useEffect(() => {
    if (username) {
      dispatch(getAll(username, range.length ? range : null));
    }
  }, [dispatch, username, range]);

  const changeRange = useCallback((current) => {
    setRange(
      current?.map((moment, idx) => {
        const date = idx === 0 ? moment.startOf('day') : moment.endOf('day');
        return date.unix() * 1000;
      }) || [],
    );
  }, []);

  const columns = useMemo(() => getColumns(list), [list]);

  return (
    <div style={{ position: 'relative', minHeight: 'calc(100vh - 64px)' }}>
      <Space>
        <UserSelect onSelect={setUsername} allowClear />
        <DatePicker.RangePicker onChange={changeRange} />
      </Space>
      <Divider />
      {username ? (
        <Table
          loading={loading}
          rowKey="_id"
          dataSource={list}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content', y: 'calc(100vh - 248px)' }}
        />
      ) : (
        <Result
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          icon={<FileSearchOutlined />}
          title="Search user logs"
        />
      )}
    </div>
  );
};
