import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'personal-transactions',
  initialState: {
    list: {
      page: 1,
      limit: 40,
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    transactionsRequestStarted: (transactions) => {
      transactions.loading = true;
    },
    transactionsRequestFailed: (transactions, action) => {
      transactions.error = action.payload.data;
    },
    transactionsRequestEnded: (transactions) => {
      transactions.loading = false;
    },
    transactionsReceived: (transactions, action) => {
      transactions.list = action.payload.data;
    },
    transactionsLoaded: (transactions, action) => {
      transactions.list = {
        ...action.payload.data,
        docs: [...transactions.list.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const {
  transactionsRequestStarted,
  transactionsRequestFailed,
  transactionsRequestEnded,
  transactionsReceived,
  transactionsLoaded,
} = slice.actions;
export default slice.reducer;

//api calls
export const getPersonal = (params = {}) =>
  apiCallBegan({
    url: '/transactions/personal',
    params,
    onStart: transactionsRequestStarted,
    onSuccess: params?.pagination?.page > 1 ? transactionsLoaded : transactionsReceived,
    onError: transactionsRequestFailed,
    onEnd: transactionsRequestEnded,
  });

export const getMyPersonal = (params = {}) =>
  apiCallBegan({
    url: '/transactions/personal/my',
    params,
    onStart: transactionsRequestStarted,
    onSuccess: params?.pagination?.page > 1 ? transactionsLoaded : transactionsReceived,
    onError: transactionsRequestFailed,
    onEnd: transactionsRequestEnded,
  });

export const createFakePersonalTransaction = (data) =>
  apiCallBegan({
    url: '/transactions/personal/fake',
    method: 'POST',
    data,
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
  });

export const createMyPersonalTransaction = (data) =>
  apiCallBegan({
    url: '/transactions/personal/my',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
  });

export const createRevertPersonalTransaction = (id) =>
  apiCallBegan({
    url: `/transactions/personal/${id}/revert`,
    method: 'POST',
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
  });

export const getPersonalTransaction = (id) =>
  apiCallBegan({
    url: `/transactions/personal/${id}`,
    method: 'GET',
    onStart: transactionsRequestStarted,
    onEnd: transactionsRequestEnded,
  });

export const getMin = (type) => apiCallBegan({ url: `/transactions/${type}/min` });

export const getMax = (type) => apiCallBegan({ url: `/transactions/${type}/max` });

//selector
export const selectPersonalTransactions = createSelector(
  (state) => state.personalTransactions,
  (personalTransactions) => ({
    list: personalTransactions.list,
    loading: personalTransactions.loading,
  }),
);

export const selectLoading = createSelector(
  (state) => state.personalTransactions,
  (personalTransactions) => personalTransactions.loading,
);
