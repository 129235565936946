import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Checkbox, Form, Input, Row } from 'antd';
import { selectAuth, login } from 'store/auth';
import './index.css';

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectAuth);

  const onFinish = useCallback((values) => {
    dispatch(
      login({
        ...values,
        username: values.username.trim(),
      }),
    );
  }, []);

  return (
    <Row style={{ height: '100vh' }} justify="space-around" align="middle">
      <Card title="Log in">
        <Form
          name="auth"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Login"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your login!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item
            className="login-submit-button"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
  );
};

export default Login;
