import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import 'moment/locale/en-gb';
import debounce from 'lodash.debounce';
import InputNumber from 'components/Form/InputNumber';
import DatePicker from 'components/Form/DatePicker';

const FlCraftSearch = ({ onChange, addonBefore, addonAfter }) => {
  const { t } = useTranslation();

  return (
    <Space>
      {addonBefore}
      <DatePicker
        format="DD.MM.YYYY"
        placeholder={t('flcraft.search.start_date')}
        onChange={onChange('startDateFrom')}
      />
      <DatePicker format="DD.MM.YYYY" placeholder={t('flcraft.search.end_date')} onChange={onChange('startDateTo')} />
      <InputNumber
        min={0}
        placeholder={t('flcraft.search.buy_in_from')}
        onChange={debounce(onChange('buyInFrom'), 300)}
      />
      <InputNumber min={0} placeholder={t('flcraft.search.buy_in_to')} onChange={debounce(onChange('buyInTo'), 300)} />
      {addonAfter}
    </Space>
  );
};

export default FlCraftSearch;
