import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'tournaments',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    tournamentsRequestStarted: (tournaments) => {
      tournaments.loading = true;
    },
    tournamentsRequestFailed: (tournaments, action) => {
      tournaments.error = action.payload.data;
    },
    tournamentsRequestEnded: (tournaments) => {
      tournaments.loading = false;
    },
    tournamentsReceived: (tournaments, action) => {
      tournaments.list = action.payload.data;
    },
  },
});

export const { tournamentsRequestStarted, tournamentsRequestFailed, tournamentsRequestEnded, tournamentsReceived } =
  slice.actions;
export default slice.reducer;

export const getTournaments = (params = {}) =>
  apiCallBegan({
    url: '/tournaments',
    params,
    onStart: tournamentsRequestStarted,
    onSuccess: tournamentsReceived,
    onError: tournamentsRequestFailed,
    onEnd: tournamentsRequestEnded,
  });

export const importTournaments = (data) =>
  apiCallBegan({
    url: '/tournaments',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: tournamentsRequestStarted,
    onSuccess: () => getTournaments(),
    onEnd: tournamentsRequestEnded,
  });

export const selectTournamentsLoading = createSelector(
  (state) => state.tournaments,
  (tournaments) => tournaments.loading,
);

export const selectTournaments = createSelector(
  (state) => state.tournaments,
  (tournaments) => ({
    list: tournaments.list,
    loading: tournaments.loading,
  }),
);
