import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Popover, Select, Space, Table } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { stopPropagation } from 'utils/common';
import { useTableParams } from 'hooks/useTableParams';
import { useArchiveColumns } from 'hooks/playing-myself/useArchiveColumns';
import useLoading from 'hooks/useLoading';
import { rowClassName } from 'utils/playing-myself';
import { getAllArchivedRequests, markAsWeirdRequests, selectArchive } from 'store/playing-myself';
import ModalController from 'components/Modal/ModalController';
import { ID as CREATE_REPORT_MODAL_ID } from 'components/PlayingMyself/CreateReportModal/config';
import CreateReportModal from 'components/PlayingMyself/CreateReportModal/CreateReportModal';
import { ReportCell } from './ReportCell';

const reportsFilter = [
  { value: null, label: 'All' },
  { value: 'expired', label: 'Expired' },
  { value: 'waiting', label: 'Waiting' },
];

export const AllArchivedRequestTable = () => {
  const dispatch = useDispatch();
  const archive = useSelector(selectArchive);
  const { params, addFilter, handleTableChange } = useTableParams();
  const { t } = useTranslation();
  const { execute, loading } = useLoading();

  const fetchRequests = useCallback(
    () => execute(() => dispatch(getAllArchivedRequests(params))),
    [execute, dispatch, params],
  );

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const columns = useArchiveColumns([
    {
      title: (
        <Input
          placeholder={t('playingmyself.table.user')}
          onClick={stopPropagation}
          onChange={debounce(addFilter('username'), 300)}
        />
      ),
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user, record) => {
        return (
          <Space>
            {user?.at(0)?.username || '-'}
            {record.isWeird && (
              <Popover content="Weird">
                <WarningOutlined label="Weird" style={{ color: 'red' }} />
              </Popover>
            )}
          </Space>
        );
      },
    },
    {
      title: (
        <Select placeholder={t('playingmyself.table.report')} options={reportsFilter} onChange={addFilter('expired')} />
      ),
      dataIndex: 'reported',
      key: 'reported',
      align: 'center',
      render: (_, record) => <ReportCell record={record} afterReport={fetchRequests} />,
    },
  ]);

  const handleRow = useCallback(
    (record) => ({
      onContextMenu: async (event) => {
        event.preventDefault();
        await dispatch(markAsWeirdRequests(record._id));
        fetchRequests();
      },
    }),
    [dispatch, fetchRequests],
  );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        rowKey="_id"
        dataSource={archive.docs}
        onChange={handleTableChange}
        pagination={{ total: archive.totalDocs, current: archive.page, pageSize: archive.limit }}
        scroll={{ x: 'auto' }}
        rowClassName={rowClassName}
        onRow={handleRow}
      />
      <ModalController id={CREATE_REPORT_MODAL_ID}>
        <CreateReportModal />
      </ModalController>
    </>
  );
};
