import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Descriptions, Form, Modal, Row, Select, Space, Upload } from 'antd';
import { UploadOutlined, FileSyncOutlined } from '@ant-design/icons';
import { rangeWithStep } from 'utils/generators';
import { useToggle } from 'hooks/useToggle';
import { importLimits, getLimits, selectLimits } from 'store/limits';
import { LimitsTable } from 'components/Tournaments/LimitsTable';

const courseOptions = rangeWithStep(1, 15, 1).map((value) => ({ value, label: value }));

export const LimitsModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { loading, list } = useSelector(selectLimits);

  useEffect(() => {
    dispatch(getLimits());
  }, [dispatch]);

  const onUpload = useCallback(async () => {
    const formData = new FormData();
    formData.append('csvFile', fileList[0], fileList[0].name);
    setUploading(true);

    try {
      await dispatch(importLimits(formData));
      setFileList([]);
      dispatch(getLimits());
    } finally {
      setUploading(false);
    }
  }, [fileList, dispatch]);

  const beForeUpload = useCallback((file) => {
    setFileList([file]);
    return false;
  }, []);

  const onRemove = useCallback(() => {
    setFileList([]);
  }, []);

  const onCourseSelect = useCallback(
    (course) => {
      dispatch(getLimits({ course }));
    },
    [dispatch],
  );

  return (
    <>
      <Button type="primary" onClick={activate} icon={<FileSyncOutlined />}>
        Limits
      </Button>
      <Modal
        width={1000}
        open={active}
        title="Можно играть все турниры дешевле указанных лимитов:"
        footer={
          <Space>
            <Upload accept=".csv" maxCount={1} beforeUpload={beForeUpload} onRemove={onRemove} fileList={fileList}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <Button type="primary" disabled={fileList.length === 0} loading={uploading} onClick={onUpload}>
              Upload
            </Button>
          </Space>
        }
        onCancel={deactivate}
      >
        <Form.Item label="Course">
          <Select virtual={false} defaultValue={1} onChange={onCourseSelect} options={courseOptions} />
        </Form.Item>
        <Row gutter={20}>
          <Col span={16}>
            <LimitsTable loading={loading} list={list} />
          </Col>
          <Col span={8}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="1">pko regspeed</Descriptions.Item>
              <Descriptions.Item label="2">8-9 max non-pko regspeed</Descriptions.Item>
              <Descriptions.Item label="3">5-7 max non-pko regspeed</Descriptions.Item>
              <Descriptions.Item label="4">5-7 max pko turbo</Descriptions.Item>
              <Descriptions.Item label="5">5-7 max pko hyper</Descriptions.Item>
              <Descriptions.Item label="6">8-9 max pko turbo</Descriptions.Item>
              <Descriptions.Item label="7">5-7 max non-pko turbo/hyper</Descriptions.Item>
              <Descriptions.Item label="8">8-9 max non-pko turbo/hyper</Descriptions.Item>
              <Descriptions.Item label="9">2-4 max all type</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
