// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'totals',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    totalsRequestStarted: (totals) => {
      totals.loading = true;
    },
    totalsRequestFailed: (totals, action) => {
      totals.error = action.payload.data;
    },
    totalsRequestEnded: (totals) => {
      totals.loading = false;
    },
    totalsListReceived: (totals, action) => {
      totals.list = action.payload.data;
    },
  },
});

export const { totalsRequestStarted, totalsRequestFailed, totalsRequestEnded, totalsListReceived } = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: `/totals`,
    params,
    onStart: totalsRequestStarted,
    onSuccess: totalsListReceived,
    onError: totalsRequestFailed,
    onEnd: totalsRequestEnded,
  });

export const getMy = () =>
  apiCallBegan({
    url: `/totals/my`,
    onStart: totalsRequestStarted,
    onSuccess: totalsListReceived,
    onError: totalsRequestFailed,
    onEnd: totalsRequestEnded,
  });

export const importTotals = (data) =>
  apiCallBegan({
    url: `/totals`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: totalsRequestStarted,
    onEnd: totalsRequestEnded,
  });

//selector
export const selectList = createSelector(
  (state) => state.totals,
  (totals) => ({
    list: totals.list,
    loading: totals.loading,
  }),
);
