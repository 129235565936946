import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'accounts',
  initialState: {
    list: [],
    current: null,
    loading: false,
    error: null,
  },
  reducers: {
    accountsRequestStarted: (accounts) => {
      accounts.loading = true;
    },
    accountsRequestFailed: (accounts, action) => {
      accounts.error = action.payload.data;
    },
    accountsRequestEnded: (accounts) => {
      accounts.loading = false;
    },
    accountsListReceived: (accounts, action) => {
      accounts.list = action.payload.data;
    },
    accountReceived: (accounts, action) => {
      accounts.current = action.payload.data;
    },
    accountsDeleted: (accounts, action) => {
      accounts.list = accounts.list.filter((account) => !action.payload.data.includes(account._id));
    },
    accountCreated: (accounts, action) => {
      accounts.list.push(action.payload.data);
    },
  },
});

export const {
  accountsRequestStarted,
  accountsRequestFailed,
  accountsRequestEnded,
  accountsListReceived,
  accountReceived,
  accountCreated,
  accountsDeleted,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = () =>
  apiCallBegan({
    url: '/accounts',
    onStart: accountsRequestStarted,
    onSuccess: accountsListReceived,
    onError: accountsRequestFailed,
    onEnd: accountsRequestEnded,
  });

export const getSameAccounts = (fromUser, toUser) =>
  apiCallBegan({
    url: '/accounts/same',
    params: { fromUser, toUser },
    onStart: accountsRequestStarted,
    onEnd: accountsRequestEnded,
  });

export const getById = (id) =>
  apiCallBegan({
    url: `/accounts/${id}`,
    onStart: accountsRequestStarted,
    onSuccess: accountReceived,
    onError: accountsRequestFailed,
    onEnd: accountsRequestEnded,
  });

export const getMyAccounts = (params = {}) =>
  apiCallBegan({
    url: '/accounts/my',
    params,
    onStart: accountsRequestStarted,
    onSuccess: accountsListReceived,
    onError: accountsRequestFailed,
    onEnd: accountsRequestEnded,
  });

export const updateBalance = (id, balance) =>
  apiCallBegan({
    url: `/accounts/${id}/balance`,
    method: 'patch',
    data: { balance },
    onStart: accountsRequestStarted,
    onSuccess: () => getMyAccounts(),
    onEnd: accountsRequestEnded,
  });

export const createAccount = (data) =>
  apiCallBegan({
    url: '/accounts',
    method: 'post',
    data,
    onStart: accountsRequestStarted,
    onSuccess: accountCreated,
    onEnd: accountsRequestEnded,
  });

export const updateAccount = (id, data) =>
  apiCallBegan({
    url: `/accounts/${id}`,
    method: 'put',
    data,
    onStart: accountsRequestStarted,
    onEnd: accountsRequestEnded,
  });

export const deleteAccount = (id) =>
  apiCallBegan({
    url: `/accounts/${id}`,
    method: 'delete',
    onStart: accountsRequestStarted,
    onSuccess: accountsDeleted,
    onError: accountsRequestFailed,
    onEnd: accountsRequestEnded,
  });

export const deleteAccounts = (ids) =>
  apiCallBegan({
    url: `/accounts/delete-many`,
    method: 'post',
    data: { ids },
    onStart: accountsRequestStarted,
    onError: accountsRequestFailed,
    onEnd: accountsRequestEnded,
  });

//selector
export const selectList = createSelector(
  (state) => state.accounts,
  (accounts) => ({
    list: accounts.list,
    loading: accounts.loading,
  }),
);

export const selectLoading = createSelector(
  (state) => state.accounts,
  (accounts) => accounts.loading,
);
