import { Spin } from 'antd';

const LoadingState = ({ loading, children }) => {
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return children;
};

export default LoadingState;
