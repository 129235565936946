import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Modal, Space, Popconfirm, notification } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { generatePassword } from 'utils/users';
import { useToggle } from 'hooks/useToggle';
import { passwordResetUser } from 'store/users';

export const ResetPasswordModal = ({ user }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [closeable, setCloseable] = useState(false);
  const [updatedVisible, setUpdatedVisible] = useState(false);

  const [form] = Form.useForm();

  const resetPassword = useCallback(
    (values) => {
      dispatch(passwordResetUser(user._id, values));
      deactivate();
      setUpdatedVisible(true);
      setCloseable(false);
    },
    [user, deactivate, dispatch],
  );

  const copyToClipboard = useCallback(() => {
    notification.success({
      message: 'Credentials copied to clipboard',
    });
    setCloseable(true);
  }, []);

  const handleUpdatedClose = useCallback(() => {
    if (closeable) {
      setUpdatedVisible(false);
    } else {
      notification.warn({
        message: 'Please copy credentials to clipboard before closing',
      });
    }
  }, [closeable]);

  return (
    <>
      <Popconfirm
        placement="bottom"
        title="Are you sure you want to reset the password?"
        onConfirm={activate}
        okText="Yes"
        cancelText="No"
      >
        <Button danger>Reset password</Button>
      </Popconfirm>
      <Modal
        open={active}
        title="Reset Password"
        okText="Reset"
        okButtonProps={{ danger: true }}
        onOk={form.submit}
        onCancel={deactivate}
      >
        The password will be reset for the player: <b>{user?.username}</b>
        <Form
          name="reset-password"
          form={form}
          layout="vertical"
          onFinish={resetPassword}
          initialValues={{ password: generatePassword() }}
        >
          <Form.Item hidden name="password" rules={[{ required: true }]}>
            <Input.Password readOnly={true} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={updatedVisible}
        title="Password is reset"
        okText="Ok"
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleUpdatedClose}
        onOk={handleUpdatedClose}
      >
        <p style={{ marginBottom: 24 }}>
          The password was reset for the player: <b>{user?.username}</b>
        </p>
        <Form name="reset-password" form={form} layout="horizontal" onFinish={resetPassword}>
          <Space>
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>{' '}
          </Space>
          <CopyToClipboard
            text={`Login: ${user?.username} \nPassword: ${form.getFieldValue('password')}`}
            onCopy={copyToClipboard}
          >
            <Button type="primary">Copy to clipboard</Button>
          </CopyToClipboard>
        </Form>
      </Modal>
    </>
  );
};
