export const toCurrency = (currency) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'BTC':
      return '₿';
    case 'USD':
    default:
      return '$';
  }
};

export const toLocalTime = (
  date,
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  locales = 'ru-RU',
) => {
  if (!date) {
    return '-';
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return new Date(date).toLocaleString(locales, { timeZone, ...options });
};

export const toDate = (date, options = {}, locales = 'ru-RU') => {
  if (!date) {
    return '-';
  }

  return new Date(date).toLocaleString(locales, options);
};

export const toUpperCase = (value) => value.toUpperCase();

export const toAmount = (value, currency = 'USD') => `${(value || 0).toFixed(2)} ${toCurrency(currency)}`;

export const toAmountWithSign = (value, currency) => `${(value || 0).toFixed(2)}${currency}`;

export const mapFormErrors = (e) => {
  const { errors } = e.response.data;

  if (!errors) {
    return [];
  }

  return Object.keys(errors).map((key) => ({
    name: key,
    errors: [errors[key]],
  }));
};

export const toOptional = (value) => value || '-';

export const toBuyInPercent = (value, onFreeze) => {
  switch (value) {
    case 0:
      return onFreeze ? '0 (25/25/50)' : '0 (50/50)';
    case 5:
      return '5 (52.5/47.5)';
    case 10:
      return '10 (55/45)';
    case 15:
      return '15 (57.5/42.5)';
    case 20:
      return '20 (60/40)';
    case 25:
      return '25 (62.5/37.5)';
    case 30:
      return '30 (65/35)';
    case 35:
      return '35 (67.5/32.5)';
    case 40:
      return '40 (70/30)';
    case 45:
      return '45 (72.5/27.5)';
    case 50:
      return '50 (75/25)';
    default:
      return '';
  }
};
