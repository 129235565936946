import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Input, Space, Table, Typography } from 'antd';
import debounce from 'lodash.debounce';
import { getFrozenPackages, selectFrozenPackages } from 'store/packages';
import { useTableParams } from 'hooks/useTableParams';
import { InfiniteScroll } from 'components/InfiniteScroll';
import FreezeModal from './fragments/FreezeModal/FreezeModal';
import { FreezeInfo } from './fragments/FreezeInfo';
import { permissions } from "../../../config/permissions";
import UpdatePackageModal from "../Users/fragments/UpdatePackageModal";
import { can } from "../../../store/auth";

const PAGINATION = { page: 1, pageSize: 40 };

export const Freeze = () => {
  const dispatch = useDispatch();
  const { params, addFilter, nextPage, handleTableChange } = useTableParams({ pagination: PAGINATION });
  const { loading, list } = useSelector(selectFrozenPackages);
  const hasPermission = useSelector(can);

  useEffect(() => {
    dispatch(getFrozenPackages(params));
  }, [dispatch, params]);

  const columns = [
    {
      title: <Input placeholder="Player" onChange={debounce(addFilter('username'), 500)} />,
      key: 'username',
      dataIndex: 'username',
      align: 'right',
    },
    {
      title: 'Status',
      key: 'closedAt',
      dataIndex: 'closedAt',
      align: 'center',
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Closed',
          value: false,
        },
      ],
      render: (val) =>
        val ? (
          <Typography.Text type="danger">Closed</Typography.Text>
        ) : (
          <Typography.Text type="success">Active</Typography.Text>
        ),
    },
    {
      title: 'Created at',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      render: (val) => new Date(val).toLocaleDateString('ru-RU'),
    },
    {
      title: 'On freeze',
      key: 'onFreeze',
      dataIndex: 'onFreeze',
      align: 'center',
      sorter: true,
      render: (val, frozenPackage) => (
        <Space>
          {val.toFixed(2)}
          {!frozenPackage.closedAt && <FreezeInfo frozenPackage={frozenPackage} />}
        </Space>
      ),
    },
  ];

  return (
    <>
      {hasPermission(permissions.CanFreeze) && <FreezeModal />}
      <Divider />
      <InfiniteScroll dataLength={list.docs.length} hasMore={list.hasNextPage} next={nextPage}>
        <Table
          onChange={handleTableChange}
          columns={columns}
          loading={loading}
          rowKey="_id"
          pagination={false}
          dataSource={list.docs}
        />
      </InfiniteScroll>
    </>
  );
};
