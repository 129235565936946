import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'packages',
  initialState: {
    split: [],
    frozen: {
      hasNextPage: true,
      docs: [],
    },
    paid: {
      hasNextPage: true,
      docs: [],
    },
    myActive: null,
    loading: false,
    error: null,
  },
  reducers: {
    packagesRequestStarted: (packages) => {
      packages.loading = true;
    },
    packagesRequestFailed: (packages, action) => {
      packages.error = action.payload.data;
    },
    packagesRequestEnded: (packages) => {
      packages.loading = false;
    },
    frozenReceived: (packages, action) => {
      packages.frozen = action.payload.data;
    },
    frozenLoaded: (packages, action) => {
      packages.frozen = {
        ...action.payload.data,
        docs: [...packages.frozen.docs, ...action.payload.data.docs],
      };
    },
    activeReceived: (packages, action) => {
      packages.myActive = action.payload.data;
    },
    splitReceived: (packages, action) => {
      packages.split = action.payload.data;
    },
    paidReceived: (packages, action) => {
      packages.paid = action.payload.data;
    },
    paidLoaded: (packages, action) => {
      packages.paid = {
        ...action.payload.data,
        docs: [...packages.paid.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const {
  packagesRequestStarted,
  packagesRequestFailed,
  packagesRequestEnded,
  frozenReceived,
  frozenLoaded,
  activeReceived,
  splitReceived,
  paidReceived,
  paidLoaded,
} = slice.actions;
export default slice.reducer;

//api calls
export const getActiveByUserId = (userId) =>
  apiCallBegan({
    url: `/users/${userId}/packages/active`,
    onStart: packagesRequestStarted,
    onError: packagesRequestFailed,
    onEnd: packagesRequestEnded,
  });

export const getById = (packageId) => apiCallBegan({ url: `/packages/${packageId}` });

export const approveById = (packageId) =>
  apiCallBegan({
    url: `/packages/${packageId}/approve`,
    method: 'patch',
  });

export const rejectById = (packageId) =>
  apiCallBegan({
    url: `/packages/${packageId}/reject`,
    method: 'patch',
  });

export const getMyActivePackage = () =>
  apiCallBegan({
    url: '/packages/my',
    onStart: packagesRequestStarted,
    onSuccess: activeReceived,
    onError: packagesRequestFailed,
    onEnd: packagesRequestEnded,
  });

export const getMyUnapprovedPackage = () =>
  apiCallBegan({
    url: `/packages/my-unapproved`,
    onStart: packagesRequestStarted,
    onEnd: packagesRequestEnded,
  });

export const finishPackage = (id) =>
  apiCallBegan({
    url: `/packages/${id}/close`,
    method: 'patch',
    onStart: packagesRequestStarted,
    onEnd: packagesRequestEnded,
  });

export const closePackageByAdmin = (id, userId) =>
  apiCallBegan({
    url: `/packages/${id}/admin-close`,
    method: 'patch',
    data: { userId },
    onStart: packagesRequestStarted,
    onEnd: packagesRequestEnded,
  });

export const freezePackage = (id) =>
  apiCallBegan({
    url: `/packages/${id}/freeze`,
    method: 'patch',
    onStart: packagesRequestStarted,
    onEnd: packagesRequestEnded,
  });

export const createPackage = (userId, data) =>
  apiCallBegan({
    url: `/users/${userId}/packages`,
    method: 'post',
    data,
    onStart: packagesRequestStarted,
    onEnd: packagesRequestEnded,
  });

export const getSplitPackages = () =>
  apiCallBegan({
    url: `/packages/split`,
    onSuccess: splitReceived,
  });

export const getFrozenPackages = (params = {}) =>
  apiCallBegan({
    url: `/packages/frozen`,
    params,
    onStart: packagesRequestStarted,
    onSuccess: params.pagination?.page > 1 ? frozenLoaded : frozenReceived,
    onEnd: packagesRequestEnded,
  });

export const getPaidPackages = (params = {}) =>
  apiCallBegan({
    url: `/packages/paid-split`,
    params,
    onStart: packagesRequestStarted,
    onSuccess: params.pagination?.page > 1 ? paidLoaded : paidReceived,
    onEnd: packagesRequestEnded,
  });

export const updatePackage = (id, data) =>
  apiCallBegan({
    url: `packages/${id}`,
    method: 'put',
    data,
  });

export const getSplitPackagesCount = () => apiCallBegan({ url: `/packages/split/count` });

//selector
export const selectLoading = createSelector(
  (state) => state.packages,
  (packages) => packages.loading,
);

export const selectFrozenPackages = createSelector(
  (state) => state.packages,
  (packages) => ({
    list: packages.frozen,
    loading: packages.loading,
  }),
);

export const selectMyActivePackage = createSelector(
  (state) => state.packages,
  (packages) => ({
    myActive: packages.myActive,
    loading: packages.loading,
  }),
);

export const selectSplitPackages = createSelector(
  (state) => state.packages,
  (packages) => ({
    splitPackages: packages.split,
  }),
);

export const selectPaidPackages = createSelector(
  (state) => state.packages,
  (packages) => ({
    paidPackages: packages.paid,
    loading: packages.loading,
  }),
);
