import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Input, Table } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { toBuyInPercent } from 'utils/format';
import { getAll, selectUserStats } from 'store/user-stats';
import ColumnTotal from 'components/Table/ColumnTotal';

const renderNumber = (val) => val?.toFixed(2) || 0;

const sorter = (field) => (a, b) => a[field] - b[field];

const courses = Array.from({ length: 15 }).map((_, i) => ({
  text: i + 1,
  value: i + 1,
}));

const Title = ({ children }) => (
  <span style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>{children}</span>
);

export const UserStatistics = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [expanded, setExpanded] = useState(false);
  const { loading, list } = useSelector(selectUserStats);

  useEffect(() => {
    dispatch(getAll(filters));
  }, [dispatch, filters]);

  const onTableChange = useCallback(
    (_p, filter, sorter) => {
      setFilters((prevState) => ({
        ...prevState,
        ...filter,
        sort: sorter.order ? { [sorter.field]: `${sorter.order}ing` } : undefined,
      }));
    },
    [setFilters],
  );

  const onInputChange = useCallback(
    (e) => {
      setFilters((prevState) => ({
        ...prevState,
        username: e.target.value,
      }));
    },
    [setFilters],
  );

  const onToggleBlocked = useCallback(() => {
    setFilters((current) => ({ ...current, blocked: !current.blocked }));
  }, []);

  const expand = useCallback((e) => {
    e.stopPropagation();
    setExpanded((exp) => !exp);
  }, []);

  return (
    <>
      <Button onClick={onToggleBlocked}>{filters.blocked ? 'Hide' : 'Show'} blocked users</Button>
      <Divider />
      <Table
        loading={loading}
        rowKey="_id"
        dataSource={list}
        pagination={false}
        scroll={{ x: 'max-content', y: 'calc(100vh - 264px)' }}
        onChange={onTableChange}
        bordered
      >
        <Table.Column
          title={<Input placeholder="Player" onChange={debounce(onInputChange, 500)} />}
          dataIndex="username"
          key="username"
          align="right"
          fixed
        />
        <Table.Column
          title={<Title>Course</Title>}
          dataIndex="course"
          key="course"
          sorter={sorter('course')}
          align="center"
          filters={courses}
          width={122}
        />
        <Table.Column
          title={
            <div>
              Total{' '}
              <Button
                shape="circle"
                icon={expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                type="ghost"
                onClick={expand}
                size="small"
              />
            </div>
          }
          dataIndex="accountsTotal"
          key="accountsTotal"
          sorter={sorter('accountsTotal')}
          render={renderNumber}
          align="center"
        />
        {expanded && (
          <>
            <Table.Column
              title="Total $"
              dataIndex="accountsUSDTotal"
              key="accountsUSDTotal"
              sorter={sorter('accountsUSDTotal')}
              render={renderNumber}
              align="center"
              width={100}
            />
            <Table.Column
              title="Total €"
              dataIndex="accountsEURTotal"
              key="accountsEURTotal"
              sorter={sorter('accountsEURTotal')}
              render={renderNumber}
              align="center"
              width={100}
            />
          </>
        )}
        <Table.ColumnGroup title="Project">
          <Table.Column
            title={<ColumnTotal title="Required" field="requiredForGameFromProject" list={list} />}
            dataIndex="requiredForGameFromProject"
            key="requiredForGameFromProject"
            sorter={sorter('requiredForGameFromProject')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title={<ColumnTotal title="Money" field="projectMoney" list={list} />}
            dataIndex="projectMoney"
            key="projectMoney"
            sorter={sorter('projectMoney')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title={<ColumnTotal title="Over" field="over" list={list} />}
            dataIndex="over"
            key="over"
            sorter={sorter('over')}
            render={renderNumber}
            align="center"
          />
        </Table.ColumnGroup>
        <Table.ColumnGroup title="Player">
          <Table.Column
            title="Required"
            dataIndex="requiredForGameFromPlayer"
            key="requiredForGameFromPlayer"
            sorter={sorter('requiredForGameFromPlayer')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title="Personal"
            dataIndex="personalMoney"
            key="personalMoney"
            sorter={sorter('personalMoney')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title="Hold"
            dataIndex="hold"
            key="hold"
            sorter={sorter('hold')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title="Available"
            dataIndex="availableForOutput"
            key="availableForOutput"
            sorter={sorter('availableForOutput')}
            render={renderNumber}
            align="center"
          />
        </Table.ColumnGroup>
        <Table.ColumnGroup title="Package">
          <Table.Column
            title="Created at"
            dataIndex="packageCreatedAt"
            key="packageCreatedAt"
            render={(val) => (val ? new Date(val).toLocaleDateString('ru-RU') : '-')}
            sorter={sorter('packageCreatedAt')}
            align="center"
          />
          <Table.Column
            title="Condition"
            dataIndex="packageCondition"
            key="packageCondition"
            render={(packageCondition, stat) => toBuyInPercent(packageCondition, stat.onFreeze)}
            sorter={sorter('packageCondition')}
            align="center"
          />
          <Table.Column
            title={<ColumnTotal title="Profit" field="packageProfit" list={list} />}
            dataIndex="packageProfit"
            key="packageProfit"
            sorter={sorter('packageProfit')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title="In ABI"
            dataIndex="inABI"
            key="inABI"
            sorter={sorter('inABI')}
            render={Math.round}
            align="center"
          />
          <Table.Column
            title="Player"
            dataIndex="playerProfit"
            key="playerProfit"
            sorter={sorter('playerProfit')}
            render={renderNumber}
            align="center"
          />
          <Table.Column
            title={<ColumnTotal title="Project" field="projectProfit" list={list} />}
            dataIndex="projectProfit"
            key="projectProfit"
            sorter={sorter('projectProfit')}
            render={renderNumber}
            align="center"
          />
        </Table.ColumnGroup>
      </Table>
    </>
  );
};
