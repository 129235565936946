import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Table } from 'antd';
import debounce from 'lodash.debounce';
import { toAmount, toLocalTime } from 'utils/format';
import { useTableParams } from 'hooks/useTableParams';
import { getAllExternalTransactions, selectExternalTransactions } from 'store/external-transactions';
import { InfiniteScroll } from 'components/InfiniteScroll';

const TransactionsTable = () => {
  const dispatch = useDispatch();
  const { params, addFilter, nextPage } = useTableParams({ pagination: { page: 1, pageSize: 30 }});
  const { list, loading } = useSelector(selectExternalTransactions);

  useEffect(() => {
    dispatch(getAllExternalTransactions(params));
  }, [dispatch, params]);

  return (
    <InfiniteScroll dataLength={list.docs.length} hasMore={list.metadata[0]?.hasNextPage} next={nextPage}>
      <Table dataSource={list.docs} loading={loading} rowKey="_id" pagination={false}>
        <Table.Column
          title="Date"
          dataIndex="createdAt"
          key="createdAt"
          width={109}
          align="center"
          render={(createdAt) => toLocalTime(createdAt, { dateStyle: 'short' })}
        />
        <Table.Column
          title="Time"
          dataIndex="createdAt"
          key="createdAt"
          width={70}
          align="center"
          render={(createdAt) => toLocalTime(createdAt, { hour: '2-digit', minute: '2-digit' })}
        />
        <Table.Column
          title={<Input placeholder="Name" onChange={debounce(addFilter('name'), 500)} />}
          dataIndex="name"
          key="name"
          width="18%"
          align="center"
        />
        <Table.Column
          title="Amount"
          dataIndex="amount"
          key="amount"
          width="10%"
          align="center"
          render={(amount, record) => toAmount(amount, record.currency)}
        />
        <Table.Column title="Comment" dataIndex="comment" key="comment" render={(comment) => comment || '-'} />
      </Table>
    </InfiniteScroll>
  );
};

export default TransactionsTable;
