import { Divider } from 'antd';
import { useMemo } from 'react';

const ColumnTotal = ({ title, list, field, addonAfter, align = 'center' }) => {
  const total = useMemo(
    () =>
      list.reduce((acc, stat) => {
        acc += stat[field];

        return acc;
      }, 0),
    [list, field],
  );

  const totalBlock = (
    <p style={{ textAlign: align, marginBottom: 0 }}>
      {total.toFixed(2)}
      {addonAfter}
    </p>
  );

  return !title ? (
    totalBlock
  ) : (
    <>
      {title}
      <Divider style={{ margin: '10px 0' }} />
      {totalBlock}
    </>
  );
};

export default ColumnTotal;
