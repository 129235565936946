import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Table } from 'antd';
import { getAnalytics, getTournamentsAnalytics, selectAnalytics } from 'store/analytics';
import SearchBar from './fragments/SearchBar';
import { columns, tournamentsColumns } from './config';

export const Analytics = () => {
  const { list, analyticsLoading, tournamentsList, tournamentsLoading } = useSelector(selectAnalytics);
  const dispatch = useDispatch();

  const onSearch = useCallback(
    (analyticsParams, tournamentParams) => {
      dispatch(getAnalytics(analyticsParams));
      dispatch(getTournamentsAnalytics(tournamentParams));
    },
    [dispatch],
  );

  return (
    <>
      <SearchBar onSearch={onSearch} />
      <Table rowKey="type" dataSource={list} columns={columns} pagination={false} loading={analyticsLoading} />
      <Divider />
      <Table
        rowKey="_id"
        dataSource={tournamentsList}
        columns={tournamentsColumns}
        pagination={false}
        loading={tournamentsLoading}
      />
    </>
  );
};
