import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Popconfirm, Row, Select, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { rangeWithStep } from 'utils/generators';
import { useTableParams } from 'hooks/useTableParams';
import { permissions } from 'config/permissions';
import { can } from 'store/auth';
import { getTournaments, selectTournaments } from 'store/tournaments';
import { deleteSerialTournament, getSerialTournaments, selectSerialTournaments } from 'store/serial-tournaments';
import { TournamentsTable } from 'components/Tournaments/TournamentsTable';
import { SerialTournamentsTable } from 'components/Tournaments/SerialTournamentsTable';
import { LimitsModal } from './fragments/LimitsModal';
import { ImportModal } from './fragments/ImportModal';
import { ImportSerialModal } from './fragments/ImportSerialModal';
import { UpdateSerialModal } from './fragments/UpdateSerialModal';

const dateFrom = new Date().getTime();
const defaultParams = {
  dateFrom,
  dateTo: dateFrom + 12 * 3600 * 1000,
};

const courseOptions = rangeWithStep(1, 15, 1).map((value) => ({ value, label: value }));
const MAX_COURSE_VALUE = 15;

export const Tournaments = () => {
  const dispatch = useDispatch();
  const hasPermission = useSelector(can);
  const { list, loading } = useSelector(selectTournaments);
  const { list: serialList, loading: serialLoading } = useSelector(selectSerialTournaments);
  const { params, addFilter, handleTableChange } = useTableParams(defaultParams);
  const [filterCourse, setFilterCourse] = useState(MAX_COURSE_VALUE);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTournaments(params));
    dispatch(getSerialTournaments(params));
  }, [dispatch, params]);

  const addCourseFilter = useCallback(
    (course) => {
      if (!course) {
        course = MAX_COURSE_VALUE;
      }
      setFilterCourse(course);
      addFilter('course')(course);
    },
    [addFilter],
  );

  const additionalColumns = useMemo(() => {
    const columns = [
      {
        title: t('tournaments.course'),
        dataIndex: 'course',
        key: 'course',
        width: 65,
      },
    ];

    if (hasPermission(permissions.CanEditSerialTournaments) || hasPermission(permissions.CanDeleteSerialTournaments)) {
      columns.push({
        title: '',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        width: 106,
        render: (id, model) => (
          <Space>
            {hasPermission(permissions.CanEditSerialTournaments) && <UpdateSerialModal tournament={model} />}
            {hasPermission(permissions.CanDeleteSerialTournaments) && (
              <Popconfirm title="Are you sure?" onConfirm={() => dispatch(deleteSerialTournament(id))}>
                <Button type="primary" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      });
    }

    return columns;
  }, [dispatch, t, hasPermission]);

  return (
    <>
      <Space>
        {hasPermission(permissions.CanUploadTournaments) && (
          <>
            <ImportModal />
            <ImportSerialModal />
          </>
        )}
        <LimitsModal />
      </Space>
      <Divider />
      <Row justify="space-between" gutter={10}>
        <Col span={13}>
          <TournamentsTable
            offset={314}
            loading={loading}
            additionalColumns={[additionalColumns[0]]}
            hiddenColumns={['edge']}
            searchExtra={
              <Select
                virtual={false}
                allowClear
                options={courseOptions}
                placeholder={t('placeholder.course')}
                onChange={addCourseFilter}
                style={{ minWidth: 100 }}
              />
            }
            dataSource={list}
            onFilter={addFilter}
            onChange={handleTableChange}
            adminCourse={filterCourse}
          />
        </Col>
        <Col span={11}>
          <SerialTournamentsTable
            offset={314}
            loading={serialLoading}
            additionalColumns={additionalColumns}
            dataSource={serialList}
          />
        </Col>
      </Row>
    </>
  );
};
