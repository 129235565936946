import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export const Done = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <h3>{t('playingmyself.report.message.success')}</h3>
    </div>
  );
};

export const Footer = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button key="next" onClick={onClick}>
      {t('playingmyself.report.button.done')}
    </Button>
  );
};
