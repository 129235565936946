import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Badge } from 'antd';

const NotificationsCount = ({ request, children }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  useEffect(() => {
    dispatch(request()).then((res) => setCount(res.data));
  }, [dispatch, request]);

  return (
    <Badge
      count={count}
      color="blue"
      size="small"
      offset={[-1, 0]}
      style={{ marginLeft: 7, position: 'relative', bottom: 2 }}
    >
      {children}
    </Badge>
  );
};

export default NotificationsCount;
