import { Tag } from 'antd';

const StatusCell = ({ blockedAt }) => {
  let color;
  let label;
  if (blockedAt) {
    color = 'red';
    label = 'Blocked';
  } else {
    color = 'green';
    label = 'Active';
  }

  return <Tag color={color}>{label}</Tag>;
};

export default StatusCell;
