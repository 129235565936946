import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toDate, toLocalTime, toOptional } from 'utils/format';
import ImageCell from 'components/Table/ImageCell';

export function useColumns(extra = [], hidden = []) {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns = [
      {
        title: t('playingmyself.table.date'),
        dataIndex: 'tournamentDate',
        key: 'tournamentDate',
        render: (tournamentDate) => toDate(tournamentDate, { year: 'numeric', month: 'numeric', day: 'numeric' }),
      },
      {
        title: t('playingmyself.table.day'),
        dataIndex: 'tournamentDate',
        key: 'weekDay',
        width: 62,
        render: (tournamentDate) => toLocalTime(tournamentDate, { weekday: 'short' }, 'en-EN'),
      },
      {
        title: t('playingmyself.table.time'),
        dataIndex: 'tournamentDate',
        key: 'tournamentTime',
        render: (tournamentDate) => toLocalTime(tournamentDate, { hour: '2-digit', minute: '2-digit' }),
      },
      {
        title: t('playingmyself.table.room'),
        dataIndex: 'account',
        key: 'account',
        render: (account) => account?.at(0)?.groupId,
      },
      {
        title: t('playingmyself.table.tournament'),
        dataIndex: 'tournament',
        key: 'tournament',
        render: (tournament, request) => tournament || request.tournamentInfo?.name || '-',
      },
      {
        title: t('playingmyself.table.buyin'),
        dataIndex: 'buyin',
        key: 'buyin',
        render: (buyIn) => (buyIn ? Math.round(buyIn * 100) / 100 : '-'),
      },
      {
        title: t('playingmyself.table.tournament_id'),
        dataIndex: 'tournamentExternalId',
        key: 'tournamentExternalId',
        render: toOptional,
      },
      {
        title: t('playingmyself.table.bbcount'),
        dataIndex: 'bbCount',
        key: 'bbCount',
        render: toOptional,
      },
      {
        title: t('playingmyself.table.entry'),
        dataIndex: 'entry',
        key: 'entry',
        render: (entry) => (entry ? t(`playingmyself.create.selector.${entry}`) : '-'),
      },
      {
        title: t('playingmyself.table.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: t('playingmyself.table.type'),
        dataIndex: '_id',
        key: 'type',
        align: 'center',
        render: (_id, { tournamentInfo, tournamentEntity, serialTournamentEntity }) => {
          if (tournamentInfo?.type) {
            return tournamentInfo.type;
          } else if (tournamentEntity?.at(0)?.type) {
            return tournamentEntity.at(0).type;
          } else if (serialTournamentEntity?.at(0)?.type) {
            return serialTournamentEntity.at(0).type;
          } else {
            return '-';
          }
        },
      },
      {
        title: t('playingmyself.table.tournament_course'),
        dataIndex: '_id',
        key: 'tournamentCourse',
        align: 'center',
        width: 10,
        render: (_id, { tournamentInfo, tournamentEntity, serialTournamentEntity }) => {
          if (tournamentInfo?.course) {
            return tournamentInfo.course;
          } else if (tournamentEntity?.at(0)?.course) {
            return tournamentEntity.at(0).course;
          } else if (serialTournamentEntity?.at(0)?.course) {
            return serialTournamentEntity.at(0).course;
          } else {
            return '-';
          }
        },
      },
      {
        title: t('playingmyself.table.course'),
        dataIndex: 'user',
        key: 'course',
        align: 'center',
        render: (user, record) => record.userInfo?.course || user?.at(0)?.course,
      },
      {
        title: t('playingmyself.table.screenshot'),
        dataIndex: 'screenshotUrl',
        key: 'screenshotUrl',
        align: 'center',
        render: (screenshotUrl) => <ImageCell src={screenshotUrl} />,
      },
      ...extra,
      {
        title: t('playingmyself.table.createdat'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (val) =>
          new Date(val).toLocaleString('ru-RU', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }),
      },
      {
        title: t('playingmyself.table.closedat'),
        dataIndex: 'closedAt',
        key: 'closedAt',
        render: (val) =>
          new Date(val).toLocaleString('ru-RU', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }),
      },
    ];

    return hidden.length ? columns.filter((column) => !hidden.includes(column.key)) : columns;
  }, [t, extra, hidden]);
}
