import { apiCallBegan } from './api';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'flCraft',
  initialState: {
    summary: {},
    list: {
      docs: []
    },
    info: {},
    loading: false,
    error: null,
  },
  reducers: {
    flCraftRequestStarted: (flCraft) => {
      flCraft.loading = true;
    },
    flCraftRequestFailed: (flCraft, action) => {
      flCraft.error = action.payload.data;
    },
    flCraftRequestEnded: (flCraft) => {
      flCraft.loading = false;
    },
    flCraftReceived: (flCraft, action) => {
      flCraft.list = action.payload.data;
    },

    flCraftInfoReceived: (flCraft, action) => {
      flCraft.info = action.payload.data;
    },
    flCraftSummaryReceived: (flCraft, action) => {
      flCraft.summary = action.payload.data.length > 0 ? action.payload.data[0] : {};
    }
  },
});

export const {
  flCraftRequestStarted,
  flCraftRequestFailed,
  flCraftRequestEnded,
  flCraftReceived,
  flCraftSummaryReceived,
  flCraftInfoReceived,
} = slice.actions;

export default slice.reducer;

//api calls
export const importArchive = (data) =>
  apiCallBegan({
    url: `/fl-craft/my/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: flCraftRequestStarted,
    onEnd: flCraftRequestEnded,
  });

export const findAll = (params) =>
  apiCallBegan({
    url: `/fl-craft`,
    params,
    onStart: flCraftRequestStarted,
    onSuccess: flCraftReceived,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const findAllMy = (params) =>
  apiCallBegan({
    url: `/fl-craft/my`,
    params,
    onStart: flCraftRequestStarted,
    onSuccess: flCraftReceived,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const getMySummary = (params) =>
  apiCallBegan({
    url: `/fl-craft/my/summary`,
    params,
    onStart: flCraftRequestStarted,
    onSuccess: flCraftSummaryReceived,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const getSummary = (params) =>
  apiCallBegan({
    url: `/fl-craft/summary`,
    params,
    onStart: flCraftRequestStarted,
    onSuccess: flCraftSummaryReceived,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const getInfo = (params) =>
  apiCallBegan({
    url: `/fl-craft/info`,
    params,
    onStart: flCraftRequestStarted,
    onSuccess: flCraftInfoReceived,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const exportCsv = (params) =>
  apiCallBegan({
    url: `/fl-craft/export`,
    params,
    onStart: flCraftRequestStarted,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const getArchiveUrl = () =>
  apiCallBegan({
    url: `/fl-craft/archive`,
    onStart: flCraftRequestStarted,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const refreshArchive = () =>
  apiCallBegan({
    url: `/fl-craft/archive/refresh`,
    onStart: flCraftRequestStarted,
    onError: flCraftRequestFailed,
    onEnd: flCraftRequestEnded,
  });

export const selectFlCraft = createSelector(
  (state) => state.flCraft,
  (flCraft) => ({
    list: flCraft.list,
    info: flCraft.info,
    summary: flCraft.summary,
    loading: flCraft.loading,
  }),
);
