import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'analytics',
  initialState: {
    list: [],
    analyticsLoading: false,
    tournamentsList: [],
    tournamentsLoading: false,
    error: null,
  },
  reducers: {
    analyticsRequestStarted: (analytics) => {
      analytics.analyticsLoading = true;
    },
    analyticsRequestFailed: (analytics, action) => {
      analytics.error = action.payload.data;
    },
    analyticsRequestEnded: (analytics) => {
      analytics.analyticsLoading = false;
    },
    analyticsReceived: (analytics, action) => {
      analytics.list = action.payload.data;
    },
    tournamentsRequestStarted: (analytics) => {
      analytics.tournamentsLoading = true;
    },
    tournamentsRequestFailed: (analytics, action) => {
      analytics.error = action.payload.data;
    },
    tournamentsRequestEnded: (analytics) => {
      analytics.tournamentsLoading = false;
    },
    tournamentsReceived: (analytics, action) => {
      analytics.tournamentsList = action.payload.data;
    },
  },
});

export const {
  analyticsRequestStarted,
  analyticsRequestFailed,
  analyticsRequestEnded,
  analyticsReceived,
  tournamentsRequestStarted,
  tournamentsRequestEnded,
  tournamentsRequestFailed,
  tournamentsReceived,
} = slice.actions;
export default slice.reducer;

export const getAnalytics = (params) =>
  apiCallBegan({
    url: `/player-analytics/${encodeURIComponent(params.alias.toLowerCase())}`,
    params,
    onStart: analyticsRequestStarted,
    onSuccess: analyticsReceived,
    onError: analyticsRequestFailed,
    onEnd: analyticsRequestEnded,
  });

export const getTournamentsAnalytics = (params) =>
  apiCallBegan({
    url: `/player-analytics/${encodeURIComponent(params.alias.toLowerCase())}/tournaments`,
    params,
    onStart: tournamentsRequestStarted,
    onSuccess: tournamentsReceived,
    onError: tournamentsRequestFailed,
    onEnd: tournamentsRequestEnded,
  });

export const selectAnalytics = createSelector(
  (state) => state.analytics,
  (analytics) => ({
    list: analytics.list,
    analyticsLoading: analytics.analyticsLoading,
    tournamentsList: analytics.tournamentsList,
    tournamentsLoading: analytics.tournamentsLoading,
  }),
);
