import { Link } from 'react-router-dom';
import NotificationsCount from './Admin/fragments/NotificationsCount';

export const getSideMenu = ({ routes, renderLabel = undefined }) => {
  return routes.reduce((acc, route) => {
    if (!route.menu) {
      return acc;
    }

    const item = {
      key: route.path,
      label: (
        <Link to={route.path}>
          {renderLabel ? renderLabel(route.menu.label) : route.menu.label}
          {route.menu.countRequest && <NotificationsCount request={route.menu.countRequest} />}
        </Link>
      ),
      icon: route.menu.icon,
    };

    if (route.menu.group) {
      if (acc[route.menu.group.key]) {
        acc[route.menu.group.key].children.push(item);
      } else {
        acc[route.menu.group.key] = {
          ...route.menu.group,
          label: renderLabel ? renderLabel(route.menu.group.label) : route.menu.group.label,
          type: 'submenu',
          children: [item],
        };
      }
    } else {
      acc[route.path] = item;
    }

    return acc;
  }, {});
};
