import { Button, Row } from 'antd';
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons';

const Footer = ({ editMode, onToggleEditMode, showHidden, onToggleShowHidden }) => (
  <Row justify="end" align="center">
    <Button.Group>
      {editMode && (
        <Button icon={showHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={onToggleShowHidden}>
          Show {showHidden ? 'active' : 'hidden'}
        </Button>
      )}
      <Button icon={editMode ? <SaveOutlined /> : <EditOutlined />} onClick={onToggleEditMode}>
        {editMode ? 'Save' : 'Edit mode'}
      </Button>
    </Button.Group>
  </Row>
);

export default Footer;
