import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { selectLoading } from 'store/accounts';
import { INITIAL_STATE } from './config';

export const useAccountModal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [currentType, setCurrentType] = useState(INITIAL_STATE);
  const [ableToTransfer, setIsAbleToTransfer] = useState(false);
  const [transferAddressVisible, setTransferAddressVisible] = useState(true);
  const loading = useSelector(selectLoading);
  const [form] = Form.useForm();

  useEffect(() => {
    setTransferAddressVisible(!currentType.transfer.equalToScreenName);
  }, [currentType]);

  const changeVisible = useCallback((flag) => () => setVisible(flag));

  return {
    form,
    dispatch,
    visible,
    setVisible,
    currentType,
    setCurrentType,
    ableToTransfer,
    transferAddressVisible,
    setIsAbleToTransfer,
    changeVisible,
    loading,
  };
};
