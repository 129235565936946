import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Descriptions, Modal, Row } from 'antd';
import { FileSyncOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { getLimits, selectLimits } from 'store/limits';
import { LimitsTable } from 'components/Tournaments/LimitsTable';

export const LimitsModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const { loading, list } = useSelector(selectLimits);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getLimits());
  }, [dispatch]);

  return (
    <>
      <Button type="primary" onClick={activate} icon={<FileSyncOutlined />}>
        {t('tournaments.limits')}
      </Button>
      <Modal width={1000} open={active} title={t('tournaments.limits')} footer={false} onCancel={deactivate}>
        <Row gutter={20}>
          <Col span={16}>
            <LimitsTable loading={loading} list={list} />
          </Col>
          <Col span={8}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="1">pko regspeed</Descriptions.Item>
              <Descriptions.Item label="2">8-9 max non-pko regspeed</Descriptions.Item>
              <Descriptions.Item label="3">5-7 max non-pko regspeed</Descriptions.Item>
              <Descriptions.Item label="4">5-7 max pko turbo</Descriptions.Item>
              <Descriptions.Item label="5">5-7 max pko hyper</Descriptions.Item>
              <Descriptions.Item label="6">8-9 max pko turbo</Descriptions.Item>
              <Descriptions.Item label="7">5-7 max non-pko turbo/hyper</Descriptions.Item>
              <Descriptions.Item label="8">8-9 max non-pko turbo/hyper</Descriptions.Item>
              <Descriptions.Item label="9">2-4 max all type</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
