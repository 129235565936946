import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'limits',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    limitsRequestStarted: (limits) => {
      limits.loading = true;
    },
    limitsRequestFailed: (limits, action) => {
      limits.error = action.payload.data;
    },
    limitsRequestEnded: (limits) => {
      limits.loading = false;
    },
    limitsReceived: (limits, action) => {
      limits.list = action.payload.data;
    },
  },
});

export const { limitsRequestStarted, limitsRequestFailed, limitsRequestEnded, limitsReceived } = slice.actions;
export default slice.reducer;

const BASE_URL = '/tournaments/limits';

export const getLimits = (params = {}) =>
  apiCallBegan({
    url: BASE_URL,
    params,
    onStart: limitsRequestStarted,
    onSuccess: limitsReceived,
    onError: limitsRequestFailed,
    onEnd: limitsRequestEnded,
  });

export const resetLimits = (data) =>
  apiCallBegan({
    url: BASE_URL,
    method: 'delete',
    data,
    onStart: limitsRequestStarted,
    onSuccess: () => getLimits(),
    onError: limitsRequestFailed,
    onEnd: limitsRequestEnded,
  });

export const importLimits = (data) =>
  apiCallBegan({
    url: BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: limitsRequestStarted,
    onSuccess: () => getLimits(),
    onEnd: limitsRequestEnded,
  });

export const selectLimits = createSelector(
  (state) => state.limits,
  (limits) => ({
    list: limits.list,
    loading: limits.loading,
  }),
);
