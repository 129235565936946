import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CountryRegionData } from 'react-country-region-selector';
import { Button, Form, Input, Modal, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { useToggle } from 'hooks/useToggle';
import { getUserById, selectUser, updateAdmin, validateUnique } from 'store/users';
import { mapFormErrors, toUpperCase } from 'utils/format';
import { mapInitialValues } from '../../utils';
import { ResetPasswordModal } from './ResetPasswordModal';

const UpdateAdminModal = ({ id, onOk, disabled }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const { user, loading } = useSelector(selectUser);
  const [form] = Form.useForm();

  useEffect(() => {
    if (active) {
      dispatch(getUserById(id));
    }
  }, [dispatch, id, active]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(mapInitialValues(user));
    }
  }, [user, form]);

  const validateEmail = useCallback(
    async (e) => {
      try {
        await dispatch(validateUnique({ email: e.target.value, userId: id }));
      } catch (e) {
        const code = e.response?.status;
        if (code === 409) {
          form.setFields([
            {
              name: 'email',
              errors: ['Email is already taken'],
            },
          ]);
        }
      }
    },
    [id, dispatch, form],
  );

  const update = useCallback(
    async (data) => {
      try {
        await dispatch(updateAdmin(id, data));
        deactivate();
        form.resetFields();
        onOk();
      } catch (e) {
        form.setFields(mapFormErrors(e));
      }
    },
    [form, deactivate, dispatch, id, onOk],
  );

  const footer = useMemo(() => {
    const buttons = [
      <Button key="cancel" onClick={deactivate}>
        Cancel
      </Button>,
    ];

    if (user) {
      buttons.push(<ResetPasswordModal key="reset-password" user={user} />);
    }

    buttons.push(
      <Button type="primary" key="update" onClick={form.submit} loading={loading}>
        Update
      </Button>,
    );

    return buttons;
  }, [user, form.submit, loading, deactivate]);

  return (
    <>
      <Button type="primary" icon={<EditOutlined />} onClick={activate} disabled={disabled} />
      <Modal open={active} title={`Update ${user?.username}`} onCancel={deactivate} footer={footer}>
        <Form form={form} layout="vertical" onFinish={update}>
          <Form.Item label="Login" name="username" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please, use the valid email' }]}
          >
            <Input onChange={debounce(validateEmail, 500)} />
          </Form.Item>
          <Form.Item
            label="Full Name (Latin)"
            name="name"
            rules={[
              { required: true, message: 'Required' },
              { pattern: new RegExp('^[a-zA-Z\\s\\-]*$'), message: 'Use latin letters only' },
            ]}
            normalize={toUpperCase}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Full Name (Cyrillic)"
            name="nameRus"
            rules={[
              {
                pattern: new RegExp('^[А-яЁёўЎЇїІіЄєҐґ\\s\\-]*$'),
                message: 'Use cyrillic letters only',
                required: false,
              },
            ]}
            normalize={toUpperCase}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: 'Required' }]}
            style={{ marginBottom: 0 }}
          >
            <Select
              name="country"
              showSearch
              virtual={false}
              options={CountryRegionData.map((country) => ({ value: country[0], label: country[0] }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateAdminModal;
