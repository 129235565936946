import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { suffixOf } from 'utils/fl-craft';
import { toAmountWithSign } from "utils/format";

const FlCraftTable = ({ list, loading, onChange, columnsBefore = [], columnsAfter = [] }) => {
  const { t } = useTranslation();

  const columns = [
    ...columnsBefore,
    {
      title: t('flcraft.table.start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (val) =>
        new Date(val).toLocaleString('ru-RU', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }),
    },
    {
      title: t('flcraft.table.title'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: true,
    },
    {
      title: t('flcraft.table.re_entries'),
      dataIndex: 'reEntries',
      key: 'reEntries',
      align: 'center',
      sorter: true,
    },
    {
      title: t('flcraft.table.buy_in'),
      dataIndex: 'buyIn',
      key: 'buyIn',
      align: 'center',
      sorter: true,
      render: (buyIn, record) => `${buyIn}${record.currency}`,
    },
    {
      title: t('flcraft.table.players'),
      dataIndex: 'players',
      key: 'players',
      align: 'center',
      sorter: true,
    },
    {
      title: t('flcraft.table.prize_pool'),
      dataIndex: 'prizePool',
      key: 'prizePool',
      align: 'center',
      sorter: true,
      render: (prizePool, record) => toAmountWithSign(prizePool, record.currency),
    },
    {
      title: t('flcraft.table.result_rank'),
      dataIndex: 'resultRank',
      key: 'resultRank',
      align: 'center',
      sorter: true,
      render: (resultRank) => `${resultRank}${suffixOf(resultRank)}`,
    },
    {
      title: t('flcraft.table.result_prize'),
      dataIndex: 'resultPrize',
      key: 'resultPrize',
      align: 'center',
      sorter: true,
      render: (resultPrize, record) =>
        `${toAmountWithSign(resultPrize, record.currency)}${record.resultTicketWon ? ' (Entry)' : ''}`,
    },
    {
      title: t('flcraft.table.result'),
      dataIndex: 'resultPrize',
      key: 'result',
      align: 'center',
      render: (resultPrize, record) =>
        `${toAmountWithSign(resultPrize - record.buyIn * (1 + record.reEntries), record.currency)}`,
    },
    ...columnsAfter,
  ];

  return (
    <Table
      loading={loading}
      rowKey="_id"
      pagination={{ total: list.totalDocs, pageSize: list.limit, current: list.page }}
      dataSource={list.docs}
      columns={columns}
      onChange={onChange}
    />
  );
};

export default FlCraftTable;
